import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { navigate } from 'redux-saga-first-router';

import Layout from '../Layout';

const LoginSuccess = withTranslation()(({ email, navigateLogin, t }) => {
  if (!email || window.THEME !== 'enscape') {
    navigateLogin();
    return null;
  }

  return (
    <div className="wrapper login-success">
      <Layout type="login-success" cover="">
        <img className="success" src="/images/enscape-activation-success.svg" alt="success" />
        <div className="title">{t('Authentication complete')}</div>
        <div className="signed-in-as">
          {t('You are now signed in as')} <strong>{email}</strong>
        </div>
        <div className="info">
          {t('You may now close this window')} <br /> {t('and proceed to the Enscape Application')}
        </div>
      </Layout>
    </div>
  );
});

export default connect(
  (state) => ({ email: state.app.email }),
  (dispatch) => ({
    navigateLogin: () => dispatch(navigate('LOGIN')),
  }),
)(LoginSuccess);
