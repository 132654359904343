/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate } from 'redux-saga-first-router';
import { routesMap } from '../../routes';
import { getQueryParamByName, setQueryParam } from '../Utils/helpers';

function RegisterLink({ children, navigateToLink, ...props }) {
  const routeID = 'REGISTER';
  const navigateParams = { query: {} };
  props.href = routesMap.get(routeID).path;

  const returnTo = getQueryParamByName('return_to');
  if (returnTo) {
    props.href = setQueryParam(props.href, 'return_to', returnTo);
    navigateParams.query.return_to = returnTo;
  }
  const promptQuery = getQueryParamByName('prompt');
  if (promptQuery) {
    props.href = setQueryParam(props.href, 'prompt', promptQuery);
    navigateParams.query.prompt = promptQuery;
  }

  props.onClick = (e) => e.preventDefault() || navigateToLink(routeID, navigateParams);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <a {...props}>{children}</a>;
}

RegisterLink.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.string,
};

export default connect(null, (dispatch) => ({
  navigateToLink: (routeID, navigateParams) => dispatch(navigate(routeID, null, navigateParams)),
}))(RegisterLink);
