import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ChaosCloud from '../Layout/Covers/ChaosCloud';
import LoginLink from '../Helper/LoginLink';

import Layout from '../Layout';

const Error = withTranslation()(({ routing, t }) => {
  const { error_description } = routing.query || {};

  return (
    <div className="wrapper ula err">
      <Layout cover={<ChaosCloud />}>
        <img src="/images/ULA/Error.svg" alt="error" />
        <div className="title">
          <strong>{t('Something went wrong.')}</strong>
        </div>
        <div className="description">
          {t(error_description || 'An error has occurred during authentication. Try to sign in again.')}
        </div>
        <div className="login-button-wrapper">
          <LoginLink id="btn-login" className="button btn-active">
            {t('Sign in')}
          </LoginLink>
        </div>
        <div className="info">
          <div>{t('Still having problems signing in?')}</div>
          <a className="link" href={window.HELP_CENTER}>
            {t('Visit our Help Center.')}
          </a>
        </div>
      </Layout>
    </div>
  );
});

export default connect((state) => ({
  routing: state.routing,
}))(Error);
