import { reducer as routerReducer } from 'redux-saga-first-router';
import { combineReducers } from 'redux';

import appReducer from './app/App/appReducer';
import loginReducer from './app/Login/loginReducer';
import corporateLoginReducer from './app/CorporateLogin/corporateReducers';
import registerReducer from './app/Register/registerReducer';
import discourseReducer from './app/Discourse/discourseReducer';

import {
  linkExistingReducer,
  socialLoginReducer,
  socialRegisterReducer,
} from './app/SocialAccounts/socialAccountsReducers';

import {
  activateReducer,
  deleteReducer,
  editProfileReducer,
  changePasswordReducer,
  recoverPasswordReducer,
  resetPasswordReducer,
  activationResendReducer,
  setupPasswordReducer,
} from './app/Profile/profileReducers';
import trialReducer from './app/Trial/trialReducer';

export default combineReducers({
  app: appReducer,
  login: loginReducer,
  register: registerReducer,
  socialLogin: socialLoginReducer,
  socialRegister: socialRegisterReducer,
  linkExistingAccount: linkExistingReducer,
  activationResend: activationResendReducer,
  routing: routerReducer,
  profileActivate: activateReducer,
  profileDelete: deleteReducer,
  profileEdit: editProfileReducer,
  profileChangePassword: changePasswordReducer,
  profileRecoverPassword: recoverPasswordReducer,
  profileResetPassword: resetPasswordReducer,
  profileSetupPassword: setupPasswordReducer,
  discourseLogin: discourseReducer,
  corporateLogin: corporateLoginReducer,
  trial: trialReducer,
});
