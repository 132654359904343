import * as Sentry from '@sentry/react';

const reportError = (errorCategory, errorMessage, errorSource) => {
  Sentry.addBreadcrumb({
    category: errorCategory,
    message: errorMessage,
    data: { errorSource },
    level: Sentry.Severity.Error,
  });

  Sentry.captureException(new Error(errorMessage), {
    tags: {
      category: errorCategory,
    },
  });
};

export default reportError;
