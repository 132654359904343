import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { navigate } from 'redux-saga-first-router';

import Layout from '../Layout';
import { Cover } from '../Helper/Cover';

const ExpiredLink = withTranslation()(({ navigateEmailCheck, t }) => {
  return (
    <Layout
      className="trial error"
      cover={<Cover backgroundImage='url("/images/trial-banner.png")' copyright="© Chaos" />}
    >
      <div className="wrapper-shrink">
        <h1 className="title">{t('Oops! Your activation link has expired.')}</h1>
        <br />
        <br />
        <br />
        <img src="/images/ULA/Error.svg" alt="error" />
        <br />
        <br />
        <div className="error-content">
          {t(
            "No problem! Simply restart the process, and we'll make sure to send you a new link. Remember, it's only valid for 24 hours.",
          )}
        </div>
        <br />
        <div className="login-button-wrapper">
          <button type="submit" className="button btn-active" onClick={navigateEmailCheck}>
            {t('Restart process')}
          </button>
        </div>
      </div>
    </Layout>
  );
});

export default connect(null, (dispatch) => ({
  navigateEmailCheck: () => dispatch(navigate('TRIAL_EMAIL_CHECK')),
}))(withTranslation()(ExpiredLink));
