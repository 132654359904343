import { useEffect } from 'react';

const Close = () => {
  useEffect(() => {
    window.opener.postMessage('closeSignInPopup', '*');
    window.close();
  }, []);

  return null;
};

export default Close;
