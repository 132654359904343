/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Layout from '../Layout';
import TextField from '../Form/TextField';
import PasswordField from '../Form/PasswordField';
import Select from '../Form/Select';
import { InputError, FormError } from '../Form/errors';
import LoginLink from '../Helper/LoginLink';
import Checkbox from '../Form/Checkbox';
import CgCaptcha from '../Recaptcha/CgCaptcha';
import { SocialLoginButtons } from '../Login/Login';
import CorporateLoginButton from '../CorporateLogin/CorporateLoginButton';
import { TRIMBLE_STYLE } from '../Helper/constants';
import PrivacyText from '../Helper/PrivacyText';
import { getAlternateLoginLabels } from '../Utils/helpers';

const IMAGES = {
  1: { src: '/images/sign-up.jpg', author: 'Double Aye' },
  2: { src: '/images/sign-up2.jpg', author: 'Nmachine' },
};

const FormButton = withTranslation()(
  ({ data: { loading, registrationStep, fieldsValidating }, disabled, app, goToStep, onSubmit, children, t }) => {
    const anyFieldValidating = Object.values(fieldsValidating).some((v) => v);
    return (
      <div className="buttons-wrapper">
        <div className="form-field-wrapper button-wrapper">
          <button
            type="button"
            id="btn-register"
            className="button btn-active"
            disabled={loading || app.redirecting || anyFieldValidating || disabled}
            onMouseDown={(e) => {
              e.preventDefault();
              if (e.button === 0) {
                onSubmit();
              }
            }}
          >
            {children}
          </button>
        </div>

        {registrationStep === 2 && (
          <p className="back">
            <span
              className="link link-nav-back"
              onMouseDown={(e) => {
                e.preventDefault();
                if (e.button === 0) {
                  goToStep(1);
                }
              }}
            >
              <span className="icon-arrow-left" />
              <span className="text">{t('Back')}</span>
            </span>
          </p>
        )}
      </div>
    );
  },
);

const getCustomPageText = (returnTo) => {
  if (returnTo.includes('try')) {
    return {
      pageTitle: 'Create account to get your trial',
      emailFieldLabel: 'Work email',
    };
  }

  if (returnTo.includes(window.STORE_ADDR) || returnTo.includes('doCheckout')) {
    return {
      pageTitle: 'Create account to complete your purchase',
      emailFieldLabel: 'Email',
    };
  }

  return {
    pageTitle: 'Create account',
    emailFieldLabel: 'Email',
  };
};

const Step1 = withTranslation()(
  ({ data, socialLogin, validateInput, onInputChange, app, routing, goToStep, onSubmit, children, t }) => {
    const { error, email, isEmailLocked, newPassword, fieldErrors, fieldWarnings, fieldsValidating, fieldsValidated } =
      data;

    // Show custom page title for users that are redirected from the website's trial page
    const { return_to = '' } = routing.query || {};
    const { pageTitle, emailFieldLabel } = getCustomPageText(return_to);
    const formError = error || socialLogin.error;
    const alternateLoginLabels = getAlternateLoginLabels(window.THEME);

    return (
      <>
        <h1 className="title">{t(pageTitle)}</h1>
        <p className="button-post-text">
          {t('Already have an account?')} <LoginLink className="is-regular">{t('Sign in')}</LoginLink>
        </p>

        {formError && <FormError error={formError} />}

        <TextField
          type="email"
          inputName="email"
          icon={isEmailLocked ? { src: '../images/padlock.svg', className: 'custom-icon' } : null}
          disabled={isEmailLocked}
          value={email}
          label={t(emailFieldLabel)}
          autoFocus
          error={fieldErrors.email}
          validating={fieldsValidating.email}
          valid={fieldsValidated.email}
          onChange={(e) => e.preventDefault() || onInputChange(e.target.id, e.target.value)}
          onBlur={(e) => validateInput(e.target.id, e.target.value)}
        />
        <PasswordField
          inputName="newPassword"
          value={newPassword}
          showValidationRules
          showHideButton
          autoComplete="new-password"
          label={<span>{t('Password')}</span>}
          warning={fieldWarnings.newPassword}
          error={fieldErrors.newPassword}
          valid={fieldsValidated.newPassword}
          onChange={(e) => {
            onInputChange(e.target.id, e.target.value);
            validateInput(e.target.id, e.target.value);
          }}
        />

        <PrivacyText className="button-pre-text" i18nKey={`privacy-text-${window.THEME.toLowerCase()}`} />

        <FormButton data={data} app={app} goToStep={goToStep} children={children} onSubmit={onSubmit}>
          {t('Continue')}
        </FormButton>
        {window.SOCIAL_REGISTER && (
          <SocialLoginButtons
            labels={alternateLoginLabels}
            showTrimble={window.TRIMBLE_REGISTER}
            trimbleStyle={TRIMBLE_STYLE.LIGHT}
          />
        )}
        {window.CORPORATE_REGISTER && <CorporateLoginButton label={alternateLoginLabels.corporate} />}
      </>
    );
  },
);

const Step2 = withTranslation()(
  ({
    data,
    validateInput,
    cgCaptchaDomain,
    onInputChange,
    generateCgCaptcha,
    app,
    goToStep,
    onSubmit,
    children,
    t,
  }) => {
    const {
      error,
      firstName,
      lastName,
      countryCode,
      countries,
      fieldErrors,
      fieldsValidated,
      newsletterSubscription,
      captchaValue,
      captchaImageUrl,
      captchaLoading,
    } = data;

    return (
      <>
        <h1 className="title">{t('Almost there')}</h1>
        <h5 className="subtitle">{t('Complete your profile')}</h5>

        {error && <FormError error={error} />}

        <TextField
          inputName="firstName"
          value={firstName}
          label={t('First name')}
          autoFocus
          error={fieldErrors.firstName}
          valid={fieldsValidated.firstName}
          onChange={(e) => e.preventDefault() || onInputChange(e.target.id, e.target.value)}
          onBlur={(e) => validateInput(e.target.id, e.target.value)}
        />
        <TextField
          inputName="lastName"
          value={lastName}
          label={t('Last name')}
          error={fieldErrors.lastName}
          valid={fieldsValidated.lastName}
          onChange={(e) => e.preventDefault() || onInputChange(e.target.id, e.target.value)}
          onBlur={(e) => validateInput(e.target.id, e.target.value)}
        />
        <Select
          selectName="countryCode"
          label={t('Country or Region')}
          value={countryCode}
          error={fieldErrors.countryCode}
          valid={fieldsValidated.countryCode}
          options={countries.sort((a, b) => a.value.localeCompare(b.value))}
          onChange={(e) => {
            e.preventDefault();
            onInputChange(e.target.id, e.target.value);
            validateInput(e.target.id, e.target.value);
          }}
          onInputChange={onInputChange}
          validateInput={validateInput}
        />
        <Checkbox
          inputName="newsletterSubscription"
          value={newsletterSubscription}
          label={t('newsletterSubscription')}
          onInputChange={onInputChange}
          onChange={(e) => {
            e.preventDefault();
            onInputChange('newsletterSubscription', !newsletterSubscription);
          }}
        />

        {app.location === 'CN' && (
          <CgCaptcha
            value={captchaValue}
            imageUrl={captchaImageUrl}
            domain={cgCaptchaDomain}
            loading={captchaLoading}
            onInputChange={(e) => {
              e.preventDefault();
              onInputChange('captchaValue', e.target.value);
            }}
            onCaptchaRefresh={(e) => {
              e.preventDefault();
              generateCgCaptcha();
            }}
          />
        )}

        {fieldErrors.captcha && <InputError err={fieldErrors.captcha} />}

        <FormButton data={data} app={app} goToStep={goToStep} children={children} onSubmit={onSubmit}>
          {t('Create account')}
        </FormButton>
      </>
    );
  },
);

const Form = ({ data, id, className, reCaptcha, onSubmitRegister, ...otherProps }) => {
  const onSubmit = () => {
    if (!data.loading) {
      onSubmitRegister(reCaptcha);
    }
  };

  return (
    <div id={id} className={['wrapper-shrink', className].join(' ')}>
      {data.registrationStep === 1 && <Step1 data={data} onSubmit={onSubmit} {...otherProps} />}
      {data.registrationStep === 2 && <Step2 data={data} onSubmit={onSubmit} {...otherProps} />}
    </div>
  );
};

const Register = ({ data, app, onReCaptchaValidate, ...otherProps }) => {
  return (
    <Layout
      className="signup"
      cover={IMAGES[data.registrationStep]}
      reCaptchaValidateHandler={app.location !== 'CN' ? onReCaptchaValidate : null}
      type="register"
    >
      <Form id="form-register" data={data} app={app} {...otherProps} />
    </Layout>
  );
};

Register.defaultProps = {
  cgCaptchaDomain: window.CG_CAPTCHA_DOMAIN,
};

Register.propTypes = {
  onSubmitRegister: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  cgCaptchaDomain: PropTypes.string,
};

export default connect(
  (state) => ({
    data: state.register,
    socialLogin: state.socialLogin,
    resend: state.activationResend,
    app: state.app,
    routing: state.routing,
  }),
  (dispatch) => ({
    onInputChange: (id, value) => dispatch({ type: 'REGISTER_INPUT_CHANGE', data: { id, value } }),
    onReCaptchaValidate: (value) => dispatch({ type: 'SUBMIT_REGISTER#VALIDATE_CAPTCHA', data: { value } }),
    generateCgCaptcha: () => dispatch({ type: 'GENERATE_CG_CAPTCHA' }),
    onSubmitRegister: (captcha) => dispatch({ type: 'SUBMIT_REGISTER', captcha }),
    goToStep: (registrationStep) =>
      dispatch({
        type: 'SUBMIT_REGISTER#SET_STEP',
        data: { registrationStep },
      }),
    validateInput: (id, value) => dispatch({ type: 'VALIDATE_INPUT', data: { id, value } }),
  }),
)(Register);
