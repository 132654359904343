import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FacebookButton from '../Helper/FacebookButton';

function FacebookLoginButton({ id, label, onLogin, children }) {
  return (
    <span
      onMouseDown={(e) => {
        e.preventDefault();
        if (e.button === 0) {
          onLogin();
        }
      }}
      style={{ display: 'inline-block' }}
      id={id}
      className="facebook-login-button"
    >
      {children || <FacebookButton type="light" label={label} />}
    </span>
  );
}

FacebookLoginButton.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default connect(null, (dispatch) => ({
  onLogin: () => {
    return dispatch({
      type: 'SUBMIT_SOCIAL_LOGIN',
      platform: 'facebook',
    });
  },
}))(FacebookLoginButton);
