import React from 'react';
import { withTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';

export default withTranslation()(({ active, t }) => (
  <LoadingOverlay
    active={active}
    spinner
    text={t('Please wait...')}
    styles={{
      wrapper: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'fixed',
        zIndex: -1,
      },
      overlay: (base) => ({
        ...base,
        background: 'rgba(20, 20, 20, 0.5)',
      }),
    }}
  />
));
