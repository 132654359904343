import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Layout from '../Layout';
import { Cover } from '../Helper/Cover';
import PrivacyText from '../Helper/PrivacyText';
import ProductsStrip from './ProductsStrip';

const EmailConfirm = withTranslation()(({ routing, auth, t }) => {
  const { firstName, lastName, email } = auth;
  return (
    <Layout
      className="trial confirm"
      cover={<Cover backgroundImage='url("/images/trial-banner.png")' copyright="© Chaos" />}
    >
      <ProductsStrip />
      <div className="wrapper-shrink">
        <h1 className="title">{t('Confirm your email.')}</h1>
        <p className="subtitle">{t('You will use it to access your trial.')}</p>
        <div className="confirm-content">
          <div className="user-data">
            <p>
              {firstName} {lastName}
            </p>
            <p>
              <strong>{email}</strong>
            </p>
          </div>

          <PrivacyText className="privacy-text" i18nKey="privacy-text-trial" />
        </div>
        <div className="login-button-wrapper">
          <button
            type="submit"
            className="button btn-active"
            onClick={() => {
              const returnTo = routing.query?.return_to;
              const redirectAddress = new URL(returnTo || window.TRIAL_ADDR);
              redirectAddress.searchParams.append('isNewAccount', false);
              window.location = redirectAddress;
            }}
          >
            {t('Start your trial')}
          </button>
        </div>
      </div>
    </Layout>
  );
});

export default connect((state) => ({
  routing: state.routing,
  auth: state.app,
}))(withTranslation()(EmailConfirm));
