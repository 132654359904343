import React from 'react';
import { withTranslation } from 'react-i18next';

const NotFound = withTranslation()(({ t }) => (
  <div className="page-404">
    <h1>{t('404 – PAGE NOT FOUND')}</h1>
  </div>
));

export default NotFound;
