import React from 'react';
import { withTranslation } from 'react-i18next';

import ContentHeader from '../Layout/ContentHeader';
import { Cover } from '../Helper/Cover';
import LoginLink from '../Helper/LoginLink';

const ActivateSuccessEnscape = withTranslation()(({ requestStatus, error, t }) => {
  const capitalizedThemeName = `${window.THEME.charAt(0).toUpperCase()}${window.THEME.slice(1)}`;

  return (
    <div className="wrapper profile activate">
      <Cover className="column image-cover" type="welcome" />
      <div className="column content">
        <ContentHeader />
        <div className="wrapper-shrink">
          {requestStatus === 'OK' && (
            <>
              <img alt="success" src="/images/enscape-activation-success.svg" />
              <h1 className="title">{t(`Welcome to ${capitalizedThemeName}!`)}</h1>
              <h5 className="subtitle">{t('Your account is now complete.')}</h5>

              <span className="divider" />
              <p>
                {t(
                  'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.',
                )}
              </p>

              <div className="button-wrapper">
                <LoginLink id="btn-login" className="button btn-active">
                  {t('Sign in')}
                </LoginLink>
              </div>
            </>
          )}
          {error && (
            <h5 id="msg-error">
              <span className="icon-minus" />
              {t(error.message)} Ref. {error.guid}
            </h5>
          )}
        </div>
      </div>
    </div>
  );
});

export default ActivateSuccessEnscape;
