import React from 'react';
import { withTranslation } from 'react-i18next';

import TextField from './TextField';
import { InputError, PasswordValidation } from './errors';

const PasswordField = ({
  children,
  id,
  inputName,
  label,
  value,
  disabled,
  tabIndex,
  showHideButton,
  autoComplete,
  valid,
  warning,
  error,
  showValidationRules,
  onChange,
  onBlur,
  t,
}) => {
  const [type, setType] = React.useState('password');
  const inputID = id || inputName;

  const showPasswordToggle = () => {
    if (!showHideButton) {
      return null;
    }

    return (
      <span className="password-show-hide-button">
        <span
          className={`password-visibility-icon ${type === 'password' ? 'visible' : 'off'}`}
          data-balloon={`${type === 'password' ? t('Show password') : t('Hide password')}`}
          onClick={() => setType(type === 'password' ? 'text' : 'password')}
        />
      </span>
    );
  };

  const handleChange = (e) => {
    e.preventDefault();

    onChange(e);
  };

  const showPasswordValidationRules = () => {
    if (showValidationRules) {
      return <PasswordValidation id={`${inputID}-error`} err={error} inputValue={value} showValidationRules />;
    }

    if (!error) {
      return false;
    }

    return <InputError id={`${inputID}-error`} err={error} />;
  };

  return (
    <div className={`form-field-wrapper ${error ? 'has-error' : ''}`}>
      {label && <label htmlFor={inputID}>{label}</label>}
      <input
        id={inputID}
        name={inputName}
        value={value}
        disabled={disabled}
        tabIndex={tabIndex}
        autoComplete={autoComplete}
        onChange={handleChange}
        onBlur={onBlur}
        type={type}
      />
      {valid && <span className="field-icon valid-icon" />}
      {warning && <div className="password-info-svg">{warning}</div>}
      {showPasswordToggle()}
      {showPasswordValidationRules()}
      {children}
    </div>
  );
};

PasswordField.defaultProps = {
  showHideButton: true,
  valid: false,
  tabIndex: null,
  autoComplete: null,
};

PasswordField.propTypes = { ...TextField.propTypes };

export default withTranslation()(PasswordField);
