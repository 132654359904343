/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Layout from '../../Layout';
import TextField from '../../Form/TextField';
import PasswordField from '../../Form/PasswordField';
import Select from '../../Form/Select';
import { FormError } from '../../Form/errors';
import Checkbox from '../../Form/Checkbox';
import Link from '../../Helper/Link';
import LoadingOverlay from '../../Helper/LoadingOverlay';
import { Cover } from '../../Helper/Cover';
import Captcha from './Captcha';
import ProductsStrip from '../ProductsStrip';

const FormButton = withTranslation()(({ onSubmit, disabled, children }) => {
  return (
    <div className="buttons-wrapper">
      <div className="form-field-wrapper button-wrapper">
        <button
          type="button"
          id="btn-register"
          className="button btn-active"
          disabled={disabled}
          onMouseDown={(e) => {
            e.preventDefault();
            if (e.button === 0) {
              onSubmit();
            }
          }}
        >
          {children}
        </button>
      </div>
    </div>
  );
});

const Register = ({ app, onReCaptchaValidate }) => {
  return (
    <Layout
      className="trial signup"
      cover={<Cover backgroundImage='url("/images/trial-banner.png")' copyright="© Chaos" />}
      reCaptchaValidateHandler={app.location !== 'CN' ? onReCaptchaValidate : null}
      type="register"
    >
      <ProductsStrip />
      <Form />
    </Layout>
  );
};

const FormComponent = ({
  data,
  loading,
  reCaptcha,
  fieldErrors,
  validateInput,
  onInputChange,
  app,
  error,
  countries,
  onSubmitRegister,
  t,
}) => {
  const { email, newPassword, firstName, lastName, countryCode, newsletterSubscription } = data;

  const requiredFields = ['firstName', 'lastName', 'newPassword'];
  const { captcha, ...fieldErrorsNoCaptcha } = fieldErrors;
  const canSubmit =
    Object.values(fieldErrorsNoCaptcha).every((field) => !field) && requiredFields.every((field) => data[field]);

  const onSubmit = () => {
    if (!loading) {
      onSubmitRegister(reCaptcha);
    }
  };

  return (
    <div className="wrapper-shrink">
      <h1 className="title">{t('Create a Chaos account to start your free trial.')}</h1>

      {error && <FormError error={error} />}
      <LoadingOverlay active={loading || app.redirecting} />

      <TextField
        disabled
        inputName="email"
        value={email}
        icon={{ src: '../images/padlock.svg', className: 'custom-icon' }}
        label={t('Work or educational email')}
      >
        <Link id="change-email" action="TRIAL_EMAIL_CHECK">
          {t('Change email')}
        </Link>
      </TextField>

      <TextField
        inputName="firstName"
        value={firstName || ''}
        label={t('First name')}
        autoFocus
        error={fieldErrors.firstName}
        onChange={(e) => {
          e.preventDefault();
          onInputChange({ firstName: e.target.value });
          validateInput('firstName', e.target.value);
        }}
      />
      <TextField
        inputName="lastName"
        value={lastName || ''}
        label={t('Last name')}
        error={fieldErrors.lastName}
        onChange={(e) => {
          e.preventDefault();
          onInputChange({ lastName: e.target.value });
          validateInput('lastName', e.target.value);
        }}
      />

      <PasswordField
        inputName="newPassword"
        value={newPassword || ''}
        showValidationRules
        showHideButton
        autoComplete="new-password"
        label={<span>{t('Password')}</span>}
        error={fieldErrors.newPassword}
        onChange={(e) => {
          onInputChange({ newPassword: e.target.value });
          validateInput('newPassword', e.target.value);
        }}
      />

      {countries && (
        <Select
          selectName="countryCode"
          label={t('Country or Region')}
          value={countryCode || ''}
          error={fieldErrors.countryCode}
          options={countries.sort((a, b) => a.value.localeCompare(b.value))}
          onChange={(e) => {
            e.preventDefault();
            onInputChange({ countryCode: e.target.value });
            validateInput('countryCode', e.target.value);
          }}
          validateInput={validateInput}
        />
      )}

      <Checkbox
        inputName="newsletterSubscription"
        value={newsletterSubscription || false}
        label={t('newsletterSubscription')}
        onChange={(e) => {
          e.preventDefault();
          onInputChange({ newsletterSubscription: !newsletterSubscription });
        }}
      />

      <Captcha />

      <FormButton data={data} app={app} disabled={loading || app.redirecting || !canSubmit} onSubmit={onSubmit}>
        {t('Create account')}
      </FormButton>
    </div>
  );
};

const Form = connect(
  (state) => ({
    data: state.trial.fields,
    fieldErrors: state.trial.fieldErrors,
    error: state.trial.error,
    countries: state.trial.countries,
    loading: state.trial.loading,
    app: state.app,
    routing: state.routing,
  }),
  (dispatch) => ({
    onInputChange: (data) => dispatch({ type: 'TRIAL_INPUT_CHANGE', data }),
    validateInput: (id, value) => dispatch({ type: 'TRIAL_VALIDATE_INPUT', data: { id, value } }),
    onSubmitRegister: (captcha) => dispatch({ type: 'SUBMIT_REGISTER', captcha, isTrial: true }),
  }),
)(withTranslation()(FormComponent));

export default connect(
  (state) => ({
    app: state.app,
  }),
  (dispatch) => ({
    onReCaptchaValidate: (value) =>
      dispatch({ type: 'SUBMIT_REGISTER#VALIDATE_CAPTCHA', data: { value, isTrial: true } }),
  }),
)(Register);
