import React from 'react';
import { connect } from 'react-redux';

import Login from '../Login/Login';
import LoginSuccess from '../Login/LoginSuccess';
import Register from '../Register/Register';
import Profile from '../Profile/Profile';
import Activate from '../Profile/Activate';
import DeleteAccount from '../Profile/DeleteAccount';
import ErrorBoundary from '../ErrorBoundary';
import NotFound from '../NotFound';
import PasswordChange from '../Profile/PasswordChange';
import PasswordRecover from '../Profile/PasswordRecover';
import PasswordReset from '../Profile/PasswordReset';
import PasswordSetup from '../Profile/PasswordSetup';
import DiscourseLogin from '../Discourse/DiscourseLogin';
import LoadingScreen from '../Loading';
import RegisterComplete from '../Register/RegisterComplete';
import TrimbleLogin from '../Trimble/Login';
import CorporateLogin from '../CorporateLogin/CorporateLogin';

import ULASuccess from '../ULA/Success';
import ULAError from '../ULA/Error';
import ULALogout from '../ULA/Logout';

import RegisterSocial from '../SocialAccounts/Register';
import RegisterSocialComplete from '../SocialAccounts/RegisterComplete';
import SketchupLoginComplete from '../SocialAccounts/SketchupLoginComplete';
import LinkExistingAccount from '../SocialAccounts/LinkExistingAccount';

import TrialEmailCheck from '../Trial/EmailCheck';
import TrialEmailConfirm from '../Trial/EmailConfirm';
import TrialLogin from '../Trial/Login';
import TrialRegister from '../Trial/Register/index';
import TrialSuccess from '../Trial/Success';
import TrialExpiredLink from '../Trial/ExpiredLink';
import TrialActivate from '../Trial/Activate';
import TrialRecover from '../Trial/Recover';

import ErrorPage from '../Error';
import Close from '../Close/Index';

const routesComponentsMap = {
  LOGIN: () => <Login />,
  LOGIN_SUCCESS: () => <LoginSuccess />,
  SERVICE_LOGIN: () => <Login />,
  CORPORATE_LOGIN: () => <CorporateLogin />,
  CORPORATE_LOGIN_ERROR: () => <ErrorPage />,
  ULA_LOGIN_SUCCESS: () => <ULASuccess />,
  ULA_LOGIN_ERROR: () => <ULAError />,
  ULA_LOGOUT_SUCCESS: () => <ULALogout />,
  ERROR: () => <ErrorPage />,
  SKETCHUP_LOGIN: () => <TrimbleLogin />,
  LOGOUT: () => <LoadingScreen />,
  REGISTER: () => <Register />,

  REGISTER_SOCIAL: () => <RegisterSocial />,
  REGISTER_SOCIAL_COMPLETE: () => <RegisterSocialComplete />,
  SKETCHUP_LOGIN_COMPLETE: () => <SketchupLoginComplete />,
  LINK_EXISTING_SOCIAL: () => <LinkExistingAccount />,

  REGISTER_COMPLETE: () => <RegisterComplete />,

  ACCOUNT_EDIT: () => <Profile />,
  ACCOUNT_ACTIVATE: () => <Activate />,
  ACCOUNT_DELETE: () => <DeleteAccount />,
  PASSWORD_CHANGE: () => <PasswordChange />,
  PASSWORD_RECOVER: () => <PasswordRecover />,
  PASSWORD_RESET: () => <PasswordReset />,
  PASSWORD_SETUP: () => <PasswordSetup />,
  DISCOURSE_LOGIN: () => <DiscourseLogin />,

  TRIAL_EMAIL_CHECK: () => <TrialEmailCheck />,
  TRIAL_EMAIL_CONFIRM: () => <TrialEmailConfirm />,
  TRIAL_LOGIN: () => <TrialLogin />,
  TRIAL_REGISTER: () => <TrialRegister />,
  TRIAL_REGISTER_SUCCESS: () => <TrialSuccess />,
  TRIAL_EXPIRED_LINK: () => <TrialExpiredLink />,
  TRIAL_ACTIVATE_LOGIN: () => <TrialActivate />,
  TRIAL_PASSWORD_RECOVER: () => <TrialRecover />,

  CLOSE: () => <Close />,
};

function App({ routeID }) {
  if (!routeID) {
    return null;
  }

  if (!routesComponentsMap[routeID]) {
    return <NotFound />;
  }

  return (
    <ErrorBoundary>
      <div id={window.THEME}>{routesComponentsMap[routeID]()}</div>
    </ErrorBoundary>
  );
}

export default connect((state) => ({ routeID: state.routing.id, uid: state.app.uid }))(App);
