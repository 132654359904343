/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Layout from '../Layout';
import LoadingOverlay from '../Helper/LoadingOverlay';
import TextField from '../Form/TextField';
import PrivacyText from '../Helper/PrivacyText';
import PasswordField from '../Form/PasswordField';
import Link from '../Helper/Link';
import { Cover } from '../Helper/Cover';
import ProductsStrip from './ProductsStrip';

const Login = withTranslation()(({ onContinue, onPasswordChange, socialLogin, data, fieldErrors, loading, app, t }) => {
  const buttonsDisabled = app.loading || app.redirecting;

  return (
    <Layout
      className="trial login"
      cover={<Cover backgroundImage='url("/images/trial-banner.png")' copyright="© Chaos" />}
    >
      <ProductsStrip />
      <div className="wrapper-shrink">
        <h1 className="title">{t('Enter your email.')}</h1>
        <p className="subtitle">{t('You will use it to access your trial.')}</p>
        <LoadingOverlay active={loading || socialLogin.loading || app.redirecting} />
        <form
          id="form-login"
          className="form-vertical-space"
          method="post"
          onSubmit={(e) => {
            e.preventDefault();
            onContinue();
          }}
        >
          <TextField
            id="email"
            inputName="email"
            value={data.email}
            label={t('Work or educational email')}
            icon={{ src: '../images/padlock.svg', className: 'custom-icon' }}
            disabled
            error={fieldErrors.email}
          >
            <Link id="change-email" tabIndex="4" action="TRIAL_EMAIL_CHECK">
              {t('Change email')}
            </Link>
          </TextField>
          <PasswordField
            inputName="password"
            value={data.password || ''}
            label={t('Password')}
            error={fieldErrors.password}
            showHideButton
            tabIndex="2"
            disabled={buttonsDisabled}
            onChange={(e) => onPasswordChange(e.target.value)}
          >
            <Link id="link-recover-account" tabIndex="4" action="TRIAL_PASSWORD_RECOVER">
              {t('Forgot your password?')}
            </Link>
          </PasswordField>

          <PrivacyText className="privacy-text" i18nKey="privacy-text-trial" />
          <div className="login-button-wrapper">
            <button
              id="btn-login"
              form="form-login"
              type="submit"
              name="commit"
              value={t('Continue')}
              className="button btn-active"
              disabled={buttonsDisabled}
              tabIndex="3"
            >
              {t('Continue')}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
});

export default connect(
  (state) => ({
    data: state.trial.fields,
    fieldErrors: state.trial.fieldErrors,
    loading: state.trial.loading,
    socialLogin: state.socialLogin,
    app: state.app,
    routing: state.routing,
  }),
  (dispatch) => ({
    onPasswordChange: (password) => dispatch({ type: 'TRIAL_INPUT_CHANGE', data: { password } }),
    onContinue: () => dispatch({ type: 'SUBMIT_LOGIN', isTrial: true }),
    resendActivationCode: (email) => dispatch({ type: 'RESEND_ACTIVATION_CODE', data: { email } }),
  }),
)(Login);
