import { call, all } from 'redux-saga/effects';

export function* validateData(data, rules = {}) {
  let errors = {};

  const fieldErrors = yield all(
    Object.keys(rules).map((field) => call(getFieldErrors, field, data[field], rules[field])),
  );

  fieldErrors.forEach((err) => {
    errors = err ? { ...errors, ...err } : errors;
  });

  return Object.keys(errors).length > 0 ? errors : null;
}

function* getFieldErrors(field, value, rules) {
  const errors = [];

  for (let i = 0; i < rules.length; i += 1) {
    const { f, message, last = true } = rules[i];
    const result = yield call(f, value);

    if (!result) {
      errors.push(message);

      if (last) {
        break;
      }
    }
  }

  return errors.length > 0 ? { [field]: errors } : null;
}
