import { getQueryParamByName } from '../Utils/helpers';

export const getCorporateLoginURL = () => {
  const URI = '/corporate_login';
  const returnToQuery = getQueryParamByName('return_to');
  const promptQuery = getQueryParamByName('prompt');

  return returnToQuery
    ? `${URI}?return_to=${encodeURIComponent(returnToQuery)}&prompt=${encodeURIComponent(promptQuery)}`
    : URI;
};
