import React from 'react';

const ProductsStrip = () => {
  return (
    <div className="trial-products-strip">
      <img src="/images/products-strip/corona.svg" alt="corona" />
      <img src="/images/products-strip/enscape.svg" alt="enscape" />
      <img src="/images/products-strip/phoenix.svg" alt="phoenix" />
      <img src="/images/products-strip/vantage.svg" alt="vantage" />
      <img src="/images/products-strip/player.svg" alt="player" />
      <img src="/images/products-strip/cylindo.svg" alt="cylindo" />
      <img src="/images/products-strip/cloud.svg" alt="cloud" />
      <img src="/images/products-strip/vray.svg" alt="vray" />
      <img src="/images/products-strip/cosmos.svg" alt="cosmos" />
      <img src="/images/products-strip/scans.svg" alt="scans" />
    </div>
  );
};

export default ProductsStrip;
