export function isEmpty(v = '') {
  return String(v).length === 0;
}

export function isEmail(v) {
  // eslint-disable-next-line max-len
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(v).toLowerCase(),
  );
}

export function isLength(v, { min = 1, max = Infinity } = {}) {
  const len = v.trim().replace(/\s+/g, ' ').length;
  return len >= min && len <= max;
}

export function containsDigits(v) {
  return /^(?=.*\d).*$/.test(String(v));
}

export function containsSpecialSymbols(v) {
  return /^(?=.*[^A-Za-z\d]).*$/.test(String(v));
}

export function containsLowerAndUpperCaseLetters(v) {
  return /^(?=.*[A-Z])(?=.*[a-z]).*$/.test(String(v));
}

/* eslint-disable no-control-regex */
export function containsNonLatinLetters(v) {
  return /[^\u0000-\u007f]/.test(String(v));
}
