import { takeLatest, call, all, cancel } from 'redux-saga/effects';
import { buildRoutesMap, route } from 'redux-saga-first-router';
import { tracePageview } from './app/Utils/Analytics/ga';
import { setPageTitleForRoute } from './app/Utils/helpers';
import {
  navigateActivateProfile,
  navigateDeleteAccount,
  navigateProfile,
  navigateResetPassword,
  navigateRecoverPassword,
  navigateChangePassword,
  navigateSetupPassword,
} from './app/Profile/profileSagas';
import { navigateRegister, navigateRegisterComplete } from './app/Register/registerSagas';

import {
  navigateLinkExistingAccount,
  navigateSocialRegister,
  navigateSocialRegisterComplete,
} from './app/SocialAccounts/socialAccountsSagas';

import { navigateTrimbleLogin, navigateSketchupLoginComplete } from './app/Trimble/trimbleSagas';

import { navigateLogin, navigateLogout } from './app/Login/loginSagas';
import { navigateDiscourseLogin } from './app/Discourse/discourseSagas';
import { navigateCorporateLogin } from './app/CorporateLogin/corporateSagas';
import { auth } from './app/App/appSagas';
import {
  navigateTrialActivate,
  navigateTrialEmailCheck,
  navigateTrialEmailConfirm,
  navigateTrialLogin,
  navigateTrialPasswordRecover,
  navigateTrialRegister,
  navigateTrialRegisterSuccess,
} from './app/Trial/trialSagas';

export const excludeInitRoutes = ['/error', '/register/trimble/complete', '/register/complete'];

export const routesMap = buildRoutesMap(
  route('LOGIN', '/', () => auth(navigateLogin)),
  route('LOGIN_SUCCESS', '/login/success', () => auth()),
  route('SERVICE_LOGIN', '/service_login', () => auth(navigateLogin)),
  route('CORPORATE_LOGIN', '/corporate_login', () => auth(navigateCorporateLogin)),
  route('CORPORATE_LOGIN_ERROR', '/corporate_login/error', () => auth()),
  route('ULA_LOGIN_SUCCESS', '/ula-login-success', () => auth()),
  route('ULA_LOGIN_ERROR', '/ula-login-error', () => auth()),
  route('ULA_LOGOUT_SUCCESS', '/ula-logout-success', () => auth()),
  route('ERROR', '/error', () => auth()),
  route('DISCOURSE_LOGIN', '/discourse/login', () => auth(navigateDiscourseLogin)),
  route('SKETCHUP_LOGIN', '/trimble/login', () => auth(navigateTrimbleLogin)),
  route('LOGOUT', '/logout', () => auth(navigateLogout)),
  route('REGISTER', '/register', () => auth(navigateRegister)),
  route('REGISTER_COMPLETE', '/register/complete', navigateRegisterComplete),
  route('SKETCHUP_LOGIN_COMPLETE', '/register/trimble/complete', navigateSketchupLoginComplete),
  route('REGISTER_SOCIAL', '/register/social', () => auth(navigateSocialRegister, { public: true })),
  route('LINK_EXISTING_SOCIAL', '/link/existing/social', (params) =>
    auth(navigateLinkExistingAccount, { public: true, callbackParams: params }),
  ),
  route('REGISTER_SOCIAL_COMPLETE', '/register/social/complete', navigateSocialRegisterComplete),

  route('ACCOUNT_EDIT', '/profile/edit', () => auth(navigateProfile, { public: false })),
  route('ACCOUNT_ACTIVATE', '/account/activate', () => auth(navigateActivateProfile)),
  route('ACCOUNT_DELETE', '/account/delete', () => auth(navigateDeleteAccount)),
  route('PASSWORD_RECOVER', '/account/recover', () => auth(navigateRecoverPassword)),
  route('PASSWORD_RESET', '/account/password-reset', () => auth(navigateResetPassword)),
  route('PASSWORD_SETUP', '/account/password-setup', () => auth(navigateSetupPassword)),
  route('PASSWORD_CHANGE', '/account/password-change', () => auth(navigateChangePassword, { public: false })),

  route('TRIAL_EMAIL_CHECK', '/trial/email-check', () => auth(navigateTrialEmailCheck)),
  route('TRIAL_EMAIL_CONFIRM', '/trial/email-confirm', () => auth(navigateTrialEmailConfirm)),
  route('TRIAL_EXPIRED_LINK', '/trial/link-expired'),
  route('TRIAL_LOGIN', '/trial/login', () => auth(navigateTrialLogin)),
  route('TRIAL_PASSWORD_RECOVER', '/trial/recover', () => auth(navigateTrialPasswordRecover)),
  route('TRIAL_REGISTER', '/trial/register', () => auth(navigateTrialRegister)),
  route('TRIAL_REGISTER_SUCCESS', '/trial/register/success', () => auth(navigateTrialRegisterSuccess)),
  route('TRIAL_ACTIVATE_LOGIN', '/account/activate/login', () => auth(navigateTrialActivate)),

  route('CLOSE', '/close', () => auth()),
);

export function* watchRouterNavigate() {
  yield takeLatest('router/NAVIGATE', handleRouterNavigate);
}

function* handleRouterNavigate(params) {
  const currentRoute = routesMap.get(params.id);
  if (!currentRoute) {
    yield cancel();
  }

  yield all([call(() => tracePageview(currentRoute)), call(() => setPageTitleForRoute(currentRoute))]);
}
