export const LANGUAGE_COOKIE = '_cgloc';

export const getCookie = (cName) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cName}=`);

  return parts.length === 2 ? parts.pop().split(';').shift() : '';
};

export const setCookie = (cName, value, sameSite) => {
  document.cookie = `${cName}=${value};path=/;secure;${sameSite ? `samesite=${sameSite};` : ''}`;
};

export const deleteCookie = (cName) => {
  document.cookie = `${cName}=;path=/;secure;Max-Age=0;`;
};
