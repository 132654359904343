import React from 'react';
import { withTranslation } from 'react-i18next';
import { Cover } from '../../Helper/Cover';

const ChaosCloud = withTranslation()(({ className, i18n, t }) => (
  <Cover
    id="chaos-cloud-cover"
    className={className}
    backgroundImage='url("/images/chaos-cloud/background.jpg")'
    copyright="&copy; Chaos"
  >
    <div className="content flex-grid">
      <div className="cover-logo flex-grid">
        <div className="col">
          <img src="/images/chaos-cloud/logo.svg" alt="Chaos Cloud" />
        </div>
      </div>
      <div className="buttons flex-grid">
        <div className="col header">
          <h5>{t('Simple, fast, smart cloud rendering')}</h5>
        </div>
        <div className="col button-wrapper">
          <a
            id="gtm-login-explore-cloud"
            href={`https://www.chaos.com/${i18n.language}/cloud-rendering?utm_campaign=Chaos-Cloud&utm_medium=website&utm_source=login`}
            className="btn-passive-light button"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Start with 20 free credits')}
          </a>
        </div>
        <div className="col" />
      </div>
    </div>
  </Cover>
));

export default ChaosCloud;
