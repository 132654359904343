import reportError from './reportError';

const ERRORS = Object.freeze({
  REACT: 'REACT_ERROR',
  SAGA: 'SAGA_ERROR',
  JS: 'JS_EXCEPTION',
  API: 'API_ERROR',
});

const IGNORE_STATUS_CODES = [401, 403, 404, 405];

export const reportReactError = (error) => {
  reportError(ERRORS.REACT, error, error.stack.split('\n')[1].trim());
};

export const reportSagaError = (error) => {
  reportError(ERRORS.SAGA, error.toString(), error.stack);
};

export const reportJsError = (message, source, lineno, colno) => {
  reportError(ERRORS.JS, message, `SOURCE: [${source}] / POSITION: [${lineno}:${colno}]`);
};

// eslint-disable-next-line no-shadow
export const reportApiError = ({ url, status, statusText }) => {
  if (IGNORE_STATUS_CODES.includes(status)) {
    return;
  }
  reportError(ERRORS.API, statusText, `URL: [${url}] / STATUS: [${status}]`);
};
