/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { navigate } from 'redux-saga-first-router';

import Layout from '../Layout';
import LoadingOverlay from '../Helper/LoadingOverlay';
import TextField from '../Form/TextField';
import { Cover } from '../Helper/Cover';
import ProductsStrip from './ProductsStrip';

const RecoverView = ({ email, passwordRecoverSuccess }) => {
  return (
    <Layout
      className="trial login recover"
      cover={<Cover backgroundImage='url("/images/trial-banner.png")' copyright="© Chaos" />}
    >
      <ProductsStrip />
      <div className="wrapper-shrink">
        {passwordRecoverSuccess ? <Success email={email} /> : <Form email={email} />}
      </div>
    </Layout>
  );
};

const FormView = withTranslation()(({ onRecover, onEmailChange, email, fieldErrors, loading, app, t }) => {
  const buttonsDisabled = app.loading || app.redirecting || !email;

  return (
    <>
      <h1 className="title">{t('Forgot your password?')}</h1>
      <p className="subtitle">{t("Enter your email and we'll send you instructions to reset your password")}</p>
      <LoadingOverlay active={loading || app.redirecting} />
      <form
        id="form-login"
        className="form-vertical-space"
        method="post"
        onSubmit={(e) => {
          e.preventDefault();
          onRecover(email);
        }}
      >
        <TextField
          inputName="email"
          type="email"
          value={email || ''}
          label={t('Email')}
          error={fieldErrors.email}
          tabIndex="1"
          autoFocus
          onChange={(e) => {
            e.preventDefault();
            onEmailChange(e.target.value);
          }}
        />

        <div className="login-button-wrapper">
          <button
            id="btn-login"
            form="form-login"
            type="submit"
            name="commit"
            value={t('Recover')}
            className="button btn-active"
            disabled={buttonsDisabled}
            tabIndex="3"
          >
            {t('Recover')}
          </button>
        </div>
      </form>
    </>
  );
});

const SuccessView = withTranslation()(({ email, routing, navigateLogin, passwordRecoverReset, t }) => {
  return (
    <>
      <h1 className="title">{t('Thank you!')}</h1>
      <div className="signup-confirmation">
        <p id="msg-reset-password">
          {t("We've sent an email to")} <strong>{email}</strong>&nbsp;
          {t("with a link to reset your password. If you don't see the email, please check your spam folder.")}
        </p>
        <button
          id="btn-login"
          form="form-login"
          type="submit"
          name="commit"
          value={t('Back to login')}
          className="button btn-active"
          tabIndex="3"
          onClick={() => {
            navigateLogin(routing.query);
            passwordRecoverReset();
          }}
        >
          {t('Back to login')}
        </button>
      </div>
    </>
  );
});

const Form = connect(
  (state) => ({
    fieldErrors: state.trial.fieldErrors,
    loading: state.trial.loading,
    passwordRecoverSuccess: state.trial.passwordRecoverSuccess,
    app: state.app,
  }),
  (dispatch) => ({
    onEmailChange: (email) => dispatch({ type: 'TRIAL_INPUT_CHANGE', data: { email } }),
    onRecover: (email) => dispatch({ type: 'TRIAL_PASSWORD_RECOVER', email }),
  }),
)(FormView);

const Success = connect(
  (state) => ({
    routing: state.routing,
  }),
  (dispatch) => ({
    navigateLogin: (query) => dispatch(navigate('TRIAL_LOGIN', null, { query })),
    passwordRecoverReset: () => dispatch({ type: 'TRIAL_PASSWORD_RECOVER#RESET' }),
  }),
)(SuccessView);

export default connect((state) => ({
  email: state.trial.fields.email,
  passwordRecoverSuccess: state.trial.passwordRecoverSuccess,
}))(RecoverView);
