// eslint-disable-next-line
export const Countries = {
  AR: 'es',
  BO: 'es',
  CL: 'es',
  CO: 'es',
  CR: 'es',
  CU: 'es',
  DO: 'es',
  EC: 'es',
  SV: 'es',
  GQ: 'es',
  GT: 'es',
  HN: 'es',
  MX: 'es',
  NI: 'es',
  PA: 'es',
  PY: 'es',
  PE: 'es',
  ES: 'es',
  UY: 'es',
  VE: 'es',

  BR: 'pt',
  AO: 'pt',
  MZ: 'pt',
  PT: 'pt',
  GW: 'pt',
  CV: 'pt',
  ST: 'pt',
  MO: 'pt',

  CN: 'cn',

  KR: 'kr',
  KP: 'kr',

  JP: 'jp',
};

export const Languages = [
  { short: 'en', long: 'English' },
  { short: 'es', long: 'Español' },
  { short: 'pt', long: 'Português (Brasil)' },
  { short: 'de', long: 'German' },
  { short: 'it', long: 'Italian' },
  { short: 'fr', long: 'French' },
  { short: 'cn', long: '简体中文' },
  { short: 'kr', long: '한국어' },
  { short: 'jp', long: '日本語' },
];

export const LanguageCodes = Languages.map((lang) => lang.short);
