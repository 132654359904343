// Analytics.ga provides support for Google Analytics integration.
// The functionality can be easily extended in the Analytics namespace
// with a `customAnalytics` module for example.
import ReactGA from 'react-ga';

if (window.GA_TRACKING_ID) {
  ReactGA.initialize(window.GA_TRACKING_ID, {
    gaOptions: {
      allowLinker: true,
    },
  });

  ReactGA.ga('require', 'linker');
  ReactGA.ga('linker:autoLink', ['chaos.com']);
}

export function tracePageview(currentRoute) {
  if (!window.GA_TRACKING_ID || !currentRoute) {
    return;
  }

  ReactGA.pageview(currentRoute.path);
}
