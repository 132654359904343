/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { InputError } from './errors';

export default function TextField({
  id,
  type,
  inputName,
  value,
  label,
  disabled,
  autoFocus,
  valid,
  icon,
  error,
  tabIndex,
  onChange,
  onBlur,
  onKeyPress,
  children,
}) {
  const fieldId = id || inputName;

  return (
    <div className={`form-field-wrapper ${error ? 'has-error' : ''}`}>
      {label && <label htmlFor={id || inputName}>{label}</label>}
      <input
        id={fieldId}
        name={inputName}
        {...{ type, value, disabled, autoFocus, tabIndex, onChange, onBlur, onKeyPress }}
      />
      {error && <InputError id={`${fieldId}-error`} err={error} />}
      {valid && <span className="field-icon valid-icon" />}
      {icon && <img src={icon.src} alt="icon" className={`field-icon ${icon.className}`} />}
      {children}
    </div>
  );
}

TextField.propTypes = {
  value: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  autoComplete: PropTypes.oneOf(['on', 'off', 'new-password']),
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
  validating: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
