import { put, takeLatest, call, select, fork, cancel } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';
import i18n from 'i18next';

import { Api } from '../Utils/api';
import {
  UNKNOWN_COUNTRY_CODE,
  validationRules,
  generateCgCaptcha,
  handleSubmitRegister,
  handleValidateCaptcha,
} from '../Register/registerSagas';
import { validateData } from '../Utils/Validation';
import { watchSubmitLogin } from '../Login/loginSagas';
import { watchSubmitSocialLogin } from '../SocialAccounts/socialAccountsSagas';
import { getQueryParamByName } from '../Utils/helpers';
import { recoverValidations } from '../Profile/profileSagas';
import { LanguageCodes } from '../../constants';

export function* navigateTrialEmailCheck() {
  const query = yield select((state) => state.routing.query);

  // This code should live until Enscape website is merged with Chaos
  // return_to is checked if contains a language segment and if yes -
  // the language is set as cookie here
  if (query.return_to) {
    const regex = new RegExp(`/(${LanguageCodes.join('|')})/`);
    const match = decodeURIComponent(query.return_to).match(regex);
    if (match) {
      i18n.changeLanguage(match[1]);
    }
  }

  const { email } = yield select((state) => state.app);
  if (email) {
    yield put(
      navigate('TRIAL_EMAIL_CONFIRM', null, {
        query,
      }),
    );
    return;
  }
  yield fork(watchValidateEmail);
  yield fork(watchSubmitSocialLogin);
}

export function* navigateTrialEmailConfirm() {
  const query = yield select((state) => state.routing.query);
  const { email } = yield select((state) => state.app);
  if (!email) {
    yield put(
      navigate('TRIAL_EMAIL_CHECK', null, {
        query,
      }),
    );
  }
}

export function* navigateTrialLogin() {
  const query = yield select((state) => state.routing.query);
  const email = yield select((state) => state.trial.fields.email);
  if (!email) {
    yield put(
      navigate('TRIAL_EMAIL_CHECK', null, {
        query,
      }),
    );
    return;
  }
  yield fork(watchSubmitLogin);
}

export function* navigateTrialRegister() {
  const query = yield select((state) => state.routing.query);
  const email = yield select((state) => state.trial.fields.email);
  if (!email) {
    yield put(
      navigate('TRIAL_EMAIL_CHECK', null, {
        query,
      }),
    );
    return;
  }
  yield fork(watchValidateInput);
  yield takeLatest('SUBMIT_REGISTER', handleSubmitRegister);
  yield takeLatest('SUBMIT_REGISTER#VALIDATE_CAPTCHA', handleValidateCaptcha);
  yield takeLatest('GENERATE_CG_CAPTCHA', generateCgCaptcha);

  yield put({ type: 'TRIAL_SET_LOADING', loading: true });

  const data = yield call(Api.fetch, '/countries');
  const countries = data.map((country) => ({ key: country.code, value: country.name }));
  const appData = yield select((state) => state.app);
  yield put({
    type: 'TRIAL_POPULATE_COUNTRIES',
    data: { countries, location: appData.location || UNKNOWN_COUNTRY_CODE },
  });

  if (appData.location === 'CN') {
    yield call(generateCgCaptcha);
  }

  yield put({ type: 'TRIAL_SET_LOADING', loading: false });
}

export function* navigateTrialRegisterSuccess() {
  yield takeLatest('TRIAL_RESEND_ACTIVATION_EMAIL', resendActivationEmail);
}

function* resendActivationEmail({ email }) {
  const returnTo = getQueryParamByName('return_to') || window.TRIAL_ADDR;
  try {
    yield call(Api.fetch, '/account/trial/activation/resend', {
      method: 'POST',
      on5xx: 'throw',
      body: JSON.stringify({ email, returnTo }),
    });
    yield put({ type: 'TRIAL_RESEND_ACTIVATION_EMAIL#SUCCESS' });
  } catch (error) {
    yield put({ type: 'TRIAL_ERROR', error: error.message });
  }
}

export function* navigateTrialActivate() {
  const routingQuery = yield select((state) => state.routing.query);
  const returnTo = routingQuery.return_to || window.TRIAL_ADDR;
  const url = new URL(returnTo);
  if (routingQuery.code) {
    try {
      const data = yield call(Api.fetch, '/account/activation/login', {
        method: 'POST',
        on5xx: 'throw',
        body: JSON.stringify({ activationCode: routingQuery.code }),
      });
      if (data.Email) {
        url.searchParams.append('isNewAccount', true);
        window.location = url;
      }
      yield cancel();
    } catch (error) {
      if (error.message && error.message.includes('Error cannot activate user with code')) {
        window.location = url;
        return;
      }
      yield put(
        navigate('TRIAL_EXPIRED_LINK', null, {
          query: routingQuery,
        }),
      );
    }
  }

  yield put(
    navigate('TRIAL_EXPIRED_LINK', null, {
      query: routingQuery,
    }),
  );
}

export function* navigateTrialPasswordRecover() {
  yield takeLatest('TRIAL_PASSWORD_RECOVER', onPasswordRecover);
}

function* onPasswordRecover({ email }) {
  const fieldErrors = yield call(validateData, { email }, { email: recoverValidations.email });
  if (fieldErrors) {
    yield put({ type: 'TRIAL_SET_FIELD_ERRORS', fieldErrors });
    yield cancel();
  }

  try {
    const returnTo = getQueryParamByName('return_to') || window.TRIAL_ADDR;
    yield call(Api.fetch, '/account/recover', {
      method: 'POST',
      on5xx: 'throw',
      body: JSON.stringify({ email, returnTo }),
    });
    yield put({ type: 'TRIAL_PASSWORD_RECOVER#SUCCESS' });
  } catch (error) {
    yield put({ type: 'TRIAL_SET_FIELD_ERRORS', fieldErrors: { email: error.message } });
  }
}

function* validateEmail() {
  const { email } = yield select((state) => state.trial.fields);

  if (email === '') {
    yield put({ type: 'TRIAL_SET_EMAIL_STATUS', status: null });
    return;
  }

  yield put({ type: 'TRIAL_SET_LOADING', loading: true });

  const query = yield select((state) => state.routing.query);

  try {
    const validationData = yield call(Api.fetch, '/account/register/validate', {
      method: 'POST',
      body: JSON.stringify({ email }),
    });

    if (validationData.status === 'conflict') {
      yield put({ type: 'TRIAL_SET_LOADING', loading: false });
      yield put({ type: 'TRIAL_SET_FIELD_ERRORS', fieldErrors: { email: null } });
      yield put(
        navigate('TRIAL_LOGIN', null, {
          query,
        }),
      );
    }
    if (validationData.status === 'invalid') {
      yield put({ type: 'TRIAL_SET_LOADING', loading: false });
      yield put({ type: 'TRIAL_SET_FIELD_ERRORS', fieldErrors: { email: 'Please enter a valid email.' } });
    }
    if (validationData.status === 'ok') {
      yield put({ type: 'TRIAL_SET_LOADING', loading: false });
      yield put({ type: 'TRIAL_SET_FIELD_ERRORS', fieldErrors: { email: null } });
      yield put(
        navigate('TRIAL_REGISTER', null, {
          query,
        }),
      );
    }
  } catch (error) {
    yield put({ type: 'TRIAL_ERROR', error: error.message });
  }
}
function* watchValidateEmail() {
  yield takeLatest('TRIAL_VALIDATE_EMAIL', validateEmail);
}

function* validateInput({ data }) {
  const { id, value } = data;
  if (!id || !validationRules[id]) {
    return;
  }

  const fieldErrors = yield call(validateData, { [id]: value }, { [id]: validationRules[id] });
  if (fieldErrors) {
    yield put({ type: 'TRIAL_SET_FIELD_ERRORS', fieldErrors });
    return;
  }
  yield put({ type: 'TRIAL_SET_FIELD_ERRORS', fieldErrors: { [id]: null } });
}
function* watchValidateInput() {
  yield takeLatest('TRIAL_VALIDATE_INPUT', validateInput);
}
