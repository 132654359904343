import { call, put, cancel } from 'redux-saga/effects';

import { reportApiError } from './Monitoring';
import store from '../../store';

const defaultFetchOptions = {
  host: window.SSO_GATEWAY,
  parseJSON: true,
  onUnauthorized: 'redirect',
  on4xx: 'throw',
  on5xx: 'API_ERROR',
  credentials: 'include',
  skipCSRFHeader: false,
};

export const Api = {
  *fetch(path, options = {}) {
    const { host, parseJSON, onUnauthorized, on4xx, on5xx, retryHandler, skipCSRFHeader, timeout, ...request } = {
      ...defaultFetchOptions,
      ...options,
    };
    const address = (host || window.MYCHAOS_BACKEND_ADDRESS) + path;

    if (!skipCSRFHeader) {
      yield call(injectHeaderCSRF, request);
    }

    let response = null;
    try {
      response = yield call(fetchTimeout, address, request, timeout);
    } catch (err) {
      yield call(handleApiError, on5xx, null, retryHandler);
    }

    if (response.status === 403) {
      return yield call(handleApiError, onUnauthorized, response, retryHandler);
    }

    if (/^4[0-9]{2}$/.exec(response.status)) {
      return yield call(handleApiError, on4xx, response, retryHandler);
    }
    if (/^5[0-9]{2}$/.exec(response.status)) {
      return yield call(handleApiError, on5xx, response, retryHandler);
    }

    yield put({ type: 'API_ERROR#RESET' });

    if (parseJSON) {
      return yield call(() => response.json());
    }

    return response;
  },
};

function injectHeaderCSRF(request) {
  const {
    app: { csrfToken = '' },
  } = store.getState();
  if (csrfToken) {
    if (typeof request.headers !== 'object') {
      request.headers = {};
    }
    request.headers['X-CSRF-Token'] = csrfToken;
  }
}

function fetchTimeout(url, options, timeout = 30000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), timeout)),
  ]);
}

/* eslint-disable consistent-return */
function* handleApiError(action, response, retryHandler) {
  if (action === 'redirect') {
    window.location = window.location.href;
    yield cancel();
  }

  let error;
  if (response) {
    reportApiError(response);
    error = yield call(responseError, response);
  } else if (navigator.onLine === false) {
    error = {
      message: 'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.',
    };
  } else {
    error = {
      message: 'We are having technical difficulties. The operation timed out. Please try later.',
    };
  }

  if (action === 'throw') {
    throw error;
  }

  if (action === 'ignore') {
    return response;
  }

  yield put({ type: action, data: { error, retryHandler } });

  yield cancel();
}

function* responseError(response) {
  const error = {
    HTTPStatus: response.status,
    message: '',
  };

  if (response.headers.get('content-type').indexOf('application/json') === 0) {
    const responseBody = yield call(() => response.json());
    return { ...error, ...(responseBody.error ? responseBody.error : responseBody) };
  }

  const responseText = yield call(() => response.text());
  return { ...error, message: ['An unexpected error occurred.', response.status, responseText].join(' ') };
}
