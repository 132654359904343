import React from 'react';
import { Logo } from '../Helper/Logo';

export default function ContentHeader({ title, link }) {
  return (
    <div className="top-wrapper">
      <div>
        <Logo />
      </div>
      <div className="button-actions">
        {title && <span>{title}&nbsp;</span>}
        {link}
      </div>
    </div>
  );
}
