import { put, fork, takeLatest, takeEvery, cancel, cancelled, call, select, all, delay } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';

import { halt } from '../../sagas';
import csrfFetch from '../Utils/fetch';
import { Api } from '../Utils/api';
import { getQueryParamByName, gotoLogin } from '../Utils/helpers';
import { isEmail, isLength, validateData } from '../Utils/Validation';
import {
  validationRules as registerValidationRules,
  warningRules as registerWarnings,
} from '../Register/registerSagas';
import { validateCorporateMail } from '../CorporateLogin/corporateSagas';
import { errorMessage, linkMessage } from '../CorporateLogin/constants';
import { getCorporateLoginURL } from '../CorporateLogin/utils';

/* Account edit sagas */
export function* navigateProfile() {
  yield fork(watchSubmitEditProfile);
  yield fork(watchEditProfileInputChange);

  // No need to fetch data again if already initialized
  const { initializing } = yield select((state) => state.profileEdit);
  if (initializing) {
    yield put({ type: 'EDIT_ACCOUNT#START' });

    const data = {};
    const fetchFuncs = [
      () =>
        csrfFetch(`${window.SSO_GATEWAY}/account/profile`)
          .then((resp) => resp.json())
          .then((resp) => {
            data.profile = resp;
          }),
      () =>
        fetch(`${window.SSO_GATEWAY}/countries`)
          .then((resp) => resp.json())
          .then((resp) => resp.map((country) => ({ key: country.code, value: country.name })))
          .then((resp) => {
            data.countries = resp;
          }),
    ];

    yield all(fetchFuncs.map((f) => call(f)));

    yield put({ type: 'EDIT_ACCOUNT#COMPLETE', data: { profile: data.profile, countries: data.countries } });

    if (!data.profile.error) {
      yield put({ type: 'EDIT_ACCOUNT#NAVIGATE' });
    }
  } else {
    yield put({ type: 'EDIT_ACCOUNT#NAVIGATE' });
  }
}

function* watchEditProfileInputChange() {
  yield takeLatest('EDIT_ACCOUNT_INPUT_CHANGE', editProfileInputChange);
}

function* editProfileInputChange() {
  window.document.body.onbeforeunload = () => '';
  yield;
}

function* submitEditProfile() {
  const profileData = yield select((state) => state.profileEdit);
  const fieldErrors = yield call(() => validateData(profileData, editProfileValidations));

  if (fieldErrors) {
    yield put({ type: 'SUBMIT_EDIT_PROFILE#VALIDATION_ERROR', data: { fieldErrors } });
    yield cancel();
  }

  try {
    yield put({ type: 'SUBMIT_EDIT_PROFILE#START' });
    const response = yield call(() =>
      csrfFetch(`${window.SSO_GATEWAY}/account/profile`, {
        method: 'PUT',
        body: JSON.stringify({ ...profileData }),
      }).then((res) => res.json()),
    );
    window.document.body.onbeforeunload = null;
    const data = { ...response, profileUpdated: !response.error };
    yield put({ type: 'SUBMIT_EDIT_PROFILE#COMPLETE', data });
  } catch (error) {
    yield put({ type: 'SUBMIT_EDIT_PROFILE#ERROR', error });
  }
}

/* Account recover password sagas */
export function* navigateRecoverPassword() {
  try {
    yield fork(watchSubmitRecover);
    yield call(halt);
  } finally {
    if (yield cancelled()) {
      yield put({ type: 'RECOVER_PASSWORD_RESET' });
    }
  }
}

function* submitRecover() {
  const data = yield select((state) => state.profileRecoverPassword);
  const fieldErrors = yield call(validateData, data, recoverValidations);

  if (fieldErrors) {
    yield put({ type: 'SUBMIT_RECOVER_PASSWORD#ERROR', data: { fieldErrors } });
    yield cancel();
  }

  try {
    const returnTo = getQueryParamByName('return_to') || window.HOME_PAGE;
    const responseData = yield call(Api.fetch, '/account/recover', {
      method: 'POST',
      body: JSON.stringify({ email: data.email, returnTo }),
      on5xx: 'SUBMIT_RECOVER_PASSWORD#ERROR',
    });

    yield put({ type: 'SUBMIT_RECOVER_PASSWORD#COMPLETE', data: responseData });
  } catch (error) {
    yield put({ type: 'SUBMIT_RECOVER_PASSWORD#ERROR', data: { fieldErrors: { email: error } } });
  }
}

/* Account reset password sagas */
export function* navigateResetPassword() {
  yield fork(watchSubmitResetPassword);
  yield takeEvery('RESET_PASSWORD_VALIDATE_INPUT', validateResetPasswordInput);
  yield put({ type: 'RESET_PASSWORD_RESET' });

  const code = getQueryParamByName('code');
  if (!code) {
    yield put(navigate('NOT_FOUND'));
    yield cancel();
  }

  yield put({ type: 'RESET_PASSWORD_VALIDATE', data: { code } });
  const data = yield call(() => csrfFetch(`${window.SSO_GATEWAY}/account/recover/${code}`).then((res) => res.json()));
  yield put({ type: 'RESET_PASSWORD_VALIDATE#COMPLETE', data });
}

function* validateChangePasswordInput(payload) {
  const data = yield select((state) => state.profileChangePassword);

  const fieldErrors = yield validateInput(payload, data, changePasswordValidations);
  yield put({ type: 'CHANGE_PASSWORD_VALIDATE_INPUT#COMPLETE', data: { id: payload.data.id, fieldErrors } });
}

function* validateResetPasswordInput(payload) {
  const data = yield select((state) => state.profileResetPassword);

  const fieldErrors = yield validateInput(payload, data, resetPasswordValidations);
  yield put({ type: 'RESET_PASSWORD_VALIDATE_INPUT#COMPLETE', data: { id: payload.data.id, fieldErrors } });
}

function* validateSetupPasswordInput(payload) {
  const data = yield select((state) => state.profileSetupPassword);

  const fieldErrors = yield validateInput(payload, data, setupPasswordValidations);
  yield put({ type: 'SETUP_PASSWORD_VALIDATE_INPUT#COMPLETE', data: { id: payload.data.id, fieldErrors } });
}

function* validateInput(payload, data, validationRules) {
  if (!payload.data || !payload.data.id || !validationRules[payload.data.id]) {
    yield cancel();
  }

  const fieldName = payload.data.id;

  return yield call(validateData, data, { [fieldName]: validationRules[fieldName] });
}

function* submitResetPassword() {
  const { profileResetPassword, routing } = yield select();
  const fieldErrors = yield call(() => validateData(profileResetPassword, resetPasswordValidations));

  if (fieldErrors) {
    yield put({ type: 'SUBMIT_RESET_PASSWORD#ERROR', error: fieldErrors.newPassword });
    yield cancel();
  }

  try {
    const response = yield call(() =>
      csrfFetch(`${window.SSO_GATEWAY}/account/reset_password`, {
        method: 'POST',
        body: JSON.stringify({ password: profileResetPassword.newPassword, code: profileResetPassword.code }),
      }).then((res) => res.json()),
    );

    yield put({ type: 'SUBMIT_RESET_PASSWORD#COMPLETE', data: response });
    if (response.status === 'OK') {
      gotoLogin(routing.query);
      yield cancel();
    }
  } catch (error) {
    yield put({ type: 'SUBMIT_RESET_PASSWORD#ERROR', error });
  }
}

/* Account setup password sagas */
export function* navigateSetupPassword() {
  yield fork(watchSubmitSetupPassword);
  yield takeEvery('SETUP_PASSWORD_VALIDATE_INPUT', validateSetupPasswordInput);
  yield put({ type: 'SETUP_PASSWORD_SETUP' });

  const code = getQueryParamByName('code');
  if (!code) {
    yield put(navigate('NOT_FOUND'));
    yield cancel();
  }

  yield put({ type: 'SETUP_PASSWORD_VALIDATE', data: { code } });
  const data = yield call(() => csrfFetch(`${window.SSO_GATEWAY}/account/recover/${code}`).then((res) => res.json()));
  yield put({ type: 'SETUP_PASSWORD_VALIDATE#COMPLETE', data });
}

function* submitSetupPassword() {
  const data = yield select((state) => state.profileSetupPassword);
  const fieldErrors = yield call(() => validateData(data, setupPasswordValidations));

  if (fieldErrors) {
    yield put({ type: 'SUBMIT_SETUP_PASSWORD#ERROR', error: fieldErrors.newPassword });
    yield cancel();
  }

  try {
    const response = yield call(() =>
      csrfFetch(`${window.SSO_GATEWAY}/account/reset_password`, {
        method: 'POST',
        body: JSON.stringify({ password: data.newPassword, code: data.code }),
      }).then((res) => res.json()),
    );
    yield put({ type: 'SUBMIT_SETUP_PASSWORD#COMPLETE', data: response });
    if (response.status === 'OK') {
      yield delay(2000);
      gotoLogin({
        return_to: window.CUSTOMER_PORTAL,
      });
      yield cancel();
    }
  } catch (error) {
    yield put({ type: 'SUBMIT_SETUP_PASSWORD#ERROR', error });
  }
}

/* Account change password sagas */
export function* navigateChangePassword() {
  yield fork(watchChangePasswordInputChange);
  yield fork(watchSubmitChangePassword);
  yield takeEvery('CHANGE_PASSWORD_VALIDATE_INPUT', validateChangePasswordInput);
  yield put({ type: 'CHANGE_PASSWORD_RESET' });
}

export function* watchChangePasswordInputChange() {
  yield takeLatest('CHANGE_PASSWORD_INPUT_CHANGE', changePasswordInputChange);
}

export function* changePasswordInputChange(payload) {
  if (!payload.data || !payload.data.id || !changePasswordWarningRules[payload.data.id]) {
    yield cancel();
  }

  const data = yield select((state) => state.profileChangePassword);
  const fieldName = payload.data.id;
  const fieldWarnings = yield call(validateData, data, { [fieldName]: changePasswordWarningRules[fieldName] });

  yield put({ type: 'CHANGE_PASSWORD_INPUT_CHANGE#WARN', fieldWarnings });
}

function* submitChangePassword() {
  const { profileChangePassword, routing } = yield select();
  const fieldErrors = yield call(() => validateData(profileChangePassword, changePasswordValidations));

  if (fieldErrors) {
    yield put({ type: 'SUBMIT_CHANGE_PASSWORD#ERROR', data: { fieldErrors } });
    yield cancel();
  }

  try {
    yield put({ type: 'SUBMIT_CHANGE_PASSWORD#START' });

    const response = yield call(Api.fetch, '/account/change_password', {
      method: 'POST',
      body: JSON.stringify({
        oldPassword: profileChangePassword.oldPassword,
        password: profileChangePassword.newPassword,
      }),
    });

    yield put({ type: 'SUBMIT_CHANGE_PASSWORD#COMPLETE', data: response });

    if (response.status === 'OK') {
      yield delay(2000);
      gotoLogin(routing.query);
      yield cancel();
    }
  } catch (error) {
    if (error.message.indexOf('current password is incorrect') > -1) {
      yield put({ type: 'SUBMIT_CHANGE_PASSWORD#ERROR', data: { fieldErrors: { oldPassword: error.message } } });
    } else {
      yield put({ type: 'SUBMIT_CHANGE_PASSWORD#ERROR', data: { error } });
    }
  }
}

/* Account activate sagas */
export function* navigateActivateProfile() {
  const code = getQueryParamByName('code');
  if (!code) {
    yield put({ type: 'SUBMIT_ACTIVATE#ERROR', error: { message: 'Invalid activation link.' } });
    yield cancel();
  }

  yield put({ type: 'LOGIN_RESET' });

  try {
    yield put({ type: 'SUBMIT_ACTIVATE#START' });

    const data = yield csrfFetch(`${window.SSO_GATEWAY}/account/activate`, {
      method: 'POST',
      body: JSON.stringify({ activationCode: code }),
    }).then((res) => res.json());

    yield put({ type: 'SUBMIT_ACTIVATE#COMPLETE', data });
  } catch (error) {
    yield put({ type: 'SUBMIT_ACTIVATE#ERROR', error });
  } finally {
    yield cancelled();
  }
}

function* resendActivation() {
  const { email } = yield select((state) => state.activationResend);
  const returnTo = getQueryParamByName('return_to');

  if (!email) {
    yield cancel();
  }

  try {
    const reqPayload = {
      email,
    };

    if (returnTo) {
      reqPayload.returnTo = returnTo;
    }

    const data = yield call(() =>
      csrfFetch(`${window.SSO_GATEWAY}/account/resend_activation`, {
        method: 'POST',
        body: JSON.stringify(reqPayload),
      }).then((res) => res.json()),
    );

    yield put({ type: 'RESEND_ACTIVATION_CODE#COMPLETE', data });
  } catch (error) {
    yield put({ type: 'RESEND_ACTIVATION_CODE#ERROR', error });
  }
}

/* Account deletion sagas */
export function* navigateDeleteAccount() {
  yield fork(watchSubmitDeleteAccount);

  const deletionCode = getQueryParamByName('code');
  if (!deletionCode) {
    yield put({ type: 'DELETE_ACCOUNT#ERROR', error: { message: 'Invalid delete account link.' } });
    yield cancel();
  }

  yield put({ type: 'DELETE_ACCOUNT', data: { deletionCode } });
}

function* submitDeleteAccount() {
  const deleteAccountData = yield select((state) => state.profileDelete);

  try {
    yield put({ type: 'SUBMIT_DELETE_ACCOUNT#START' });
    const data = yield call(() =>
      csrfFetch(`${window.SSO_GATEWAY}/account/delete`, {
        method: 'POST',
        body: JSON.stringify({ deletionCode: deleteAccountData.deletionCode }),
      }).then((res) => res.json()),
    );
    yield put({ type: 'SUBMIT_DELETE_ACCOUNT#COMPLETE', data });
  } catch (error) {
    yield put({ type: 'SUBMIT_DELETE_ACCOUNT#ERROR', error });
  }
}

/* Watcher sagas */
export function* watchResendActivation() {
  yield takeLatest('RESEND_ACTIVATION_CODE', resendActivation);
}

function* watchSubmitEditProfile() {
  yield takeLatest('SUBMIT_EDIT_ACCOUNT', submitEditProfile);
}

function* watchSubmitDeleteAccount() {
  yield takeLatest('SUBMIT_DELETE_ACCOUNT', submitDeleteAccount);
}

function* watchSubmitRecover() {
  yield takeLatest('SUBMIT_RECOVER_PASSWORD', submitRecover);
}

function* watchSubmitResetPassword() {
  yield takeLatest('SUBMIT_RESET_PASSWORD', submitResetPassword);
}

function* watchSubmitChangePassword() {
  yield takeLatest('SUBMIT_CHANGE_PASSWORD', submitChangePassword);
}

function* watchSubmitSetupPassword() {
  yield takeLatest('SUBMIT_SETUP_PASSWORD', submitSetupPassword);
}

/* Validation rules */
const editProfileValidations = {
  email: { ...registerValidationRules.email },
  firstName: { ...registerValidationRules.firstName },
  lastName: { ...registerValidationRules.lastName },
  countryCode: { ...registerValidationRules.countryCode },
};

const changePasswordWarningRules = {
  password: [...registerWarnings.password],
};

export const recoverValidations = {
  email: [
    { f: (v) => isLength(v), message: 'Please enter your email address.' },
    { f: (v) => isEmail(v), message: 'Please enter valid email address.' },
    {
      f: validateCorporateMail,
      message: {
        message: errorMessage,
        link: {
          text: linkMessage,
          href: getCorporateLoginURL(),
        },
      },
    },
  ],
};

const resetPasswordValidations = { newPassword: [...registerValidationRules.newPassword] };

const changePasswordValidations = {
  newPassword: registerValidationRules.newPassword,
  oldPassword: [
    { ...registerValidationRules.password[0] },
    { ...registerValidationRules.password[1], message: 'Invalid password.' },
  ],
};

const setupPasswordValidations = { newPassword: [...registerValidationRules.newPassword] };
