/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Layout from '../Layout';
import Select from '../Form/Select';
import { FormError } from '../Form/errors';
import Checkbox from '../Form/Checkbox';
import GoogleLoginButton from './GoogleLoginButton';
import LoginLink from '../Helper/LoginLink';
import PrivacyText from '../Helper/PrivacyText';
import TextField from '../Form/TextField';
import { Cover } from '../Helper/Cover';
import Link from '../Helper/Link';

const Register = withTranslation()(({ routing, data, validateInput, onInputChange, onSubmitRegister, t }) => {
  const { platform, firstName, lastName, email, return_to } = routing.query;

  const isTrial = return_to && return_to.includes(window.TRIAL_ADDR);
  const title = isTrial ? 'Create a Chaos account to start your free trial.' : 'Create account';

  const platformSettings = {
    google: {
      message: <p>{t('We will use your Google details to create your Chaos account')}</p>,
      changeButton: (
        <GoogleLoginButton id="btn-change-google-account">
          <span className="link is-regular">{t('Change')}</span>
        </GoogleLoginButton>
      ),
    },
    facebook: {
      message: <p>{t('We will use your Facebook details to create your Chaos account')}</p>,
      // Do not show change FB button again, as only one account can be
      // logged in in one browser session in FB.
      changeButton: null,
    },
    trimble: {
      message: <p>{t('We will use your Trimble details to create your Chaos account')}</p>,
      changeButton: null,
    },
  };

  return (
    <Layout
      className={`wrapper signup ${platform} ${isTrial ? 'trial' : ''}`}
      cover={
        isTrial ? (
          <Cover backgroundImage='url("/images/trial-banner.png")' copyright="© Chaos" />
        ) : (
          { src: '/images/sign-up2.jpg', author: 'Nmachine' }
        )
      }
    >
      <form
        id="form-register"
        action="/"
        method="post"
        className="wrapper-shrink"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitRegister(email);
        }}
      >
        <div className="text-wrapper">
          <h1 className="title">{t(title)}</h1>
          {!isTrial && (
            <>
              <h5 className="subtitle">{t('Complete your profile')}</h5>
              {platformSettings[platform].message}
              <h5 className="name">{[firstName, lastName].join(' ')}</h5>
              <p className="email">{email}</p>
              {platformSettings[platform].changeButton}
            </>
          )}
        </div>

        {isTrial && (
          <TextField
            id="email"
            value={email}
            label={t('Work or educational email')}
            icon={{ src: '../images/padlock.svg', className: 'custom-icon' }}
            disabled
          >
            <Link id="change-email" tabIndex="4" action="TRIAL_EMAIL_CHECK">
              {t('Change email')}
            </Link>
          </TextField>
        )}

        <TextField
          inputName="firstName"
          value={data.firstName}
          label={t('First name')}
          disabled={data.loading}
          validating={data.fieldsValidating.firstName}
          error={data.fieldErrors.firstName}
          onChange={(e) => e.preventDefault() || onInputChange(e.target.id, e.target.value)}
          onBlur={(e) => validateInput(e.target.id, e.target.value)}
        />

        <TextField
          inputName="lastName"
          value={data.lastName}
          label={t('Last name')}
          disabled={data.loading}
          validating={data.fieldsValidating.lastName}
          error={data.fieldErrors.lastName}
          onChange={(e) => e.preventDefault() || onInputChange(e.target.id, e.target.value)}
          onBlur={(e) => validateInput(e.target.id, e.target.value)}
        />

        <Select
          selectName="countryCode"
          label={t('Country')}
          value={data.countryCode}
          tabIndex="1"
          disabled={data.loading}
          options={data.countries}
          onChange={(e) => {
            e.preventDefault();
            onInputChange(e.target.id, e.target.value);
          }}
          onInputChange={onInputChange}
        />

        <Checkbox
          inputName="newsletterSubscription"
          value={data.newsletterSubscription}
          label={t('newsletterSubscription')}
          disabled={data.loading}
          onChange={(e) => {
            e.preventDefault();
            onInputChange('newsletterSubscription', !data.newsletterSubscription);
          }}
        />

        <div className="bottom-content">
          {!isTrial && <PrivacyText i18nKey={`privacy-text-${window.THEME.toLowerCase()}`} />}

          <div className="form-field-wrapper button-wrapper">
            <button id="btn-register" type="submit" className="button btn-active" tabIndex="3" disabled={data.loading}>
              {t('CREATE ACCOUNT')}
            </button>
          </div>

          <p>
            <LoginLink id="link-nav-back">
              <span className="icon-arrow-left" />
              <span className="text">{t('Back')}</span>
            </LoginLink>
          </p>
        </div>
      </form>
      {data.error && <FormError error={data.error} />}
    </Layout>
  );
});

Register.propTypes = {
  onSubmitRegister: PropTypes.func.isRequired,
  validateInput: PropTypes.func.validateInput,
  onInputChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  socialLogin: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    routing: state.routing,
    data: state.socialRegister,
    socialLogin: state.socialLogin,
    geoipCountryCode: state.app.location,
  }),
  (dispatch) => ({
    onInputChange: (id, value) => dispatch({ type: 'SOCIAL_REGISTER_INPUT_CHANGE', data: { id, value } }),
    onSubmitRegister: (email) => dispatch({ type: 'SUBMIT_SOCIAL_REGISTER', email }),
    validateInput: (id, value) => dispatch({ type: 'VALIDATE_INPUT', data: { id, value } }),
  }),
)(Register);
