import { call, cancel, fork, put, select, takeLatest, delay } from 'redux-saga/effects';

import { Api } from '../Utils/api';

export function* navigateTrimbleLogin() {
  yield fork(watchSubmitTrimbleLogin);
}

export function* watchSubmitTrimbleLogin() {
  yield takeLatest('SUBMIT_TRIMBLE_AUTO_LOGIN', submitTrimbleLogin);
}

function* submitTrimbleLogin() {
  yield put({ type: 'SOCIAL_LOGIN#RESET' });
  yield put({ type: 'SOCIAL_LOGIN#START' });
  try {
    const redirectTo = `https://${window.location.hostname}/register/trimble/complete`;
    const continueURL = `${window.SSO_GATEWAY}/legacy_token?continue=${encodeURIComponent(redirectTo)}`;
    const response = yield call(Api.fetch, '/account/social/login', {
      method: 'POST',
      body: JSON.stringify({
        platform: 'trimble',
        continue: continueURL,
        auto_register: true,
      }),
      onUnauthorized: 'throw',
      on5xx: 'throw',
    });
    window.location.href = response.redirect_to;
  } catch (error) {
    // It will return http.StatusUnprocessableEntity
    // if the session is expired.
    if (error.HTTPStatus === 422) {
      window.location.reload();
      return;
    }
    yield put({ type: 'SUBMIT_LOGIN_SOCIAL#ERROR', error });
  }
}

export function* navigateSketchupLoginComplete() {
  const routing = yield select((state) => state.routing);
  const accessToken = routing.query?.access_token;
  // store the registration data (i.e. the access token) in the state
  yield put({ type: 'SUBMIT_SOCIAL_REGISTER#COMPLETE', accessToken });
  // mark the activation as in-progress before we redirect to the Sketchup complete page
  yield put({
    type: 'SUBMIT_SKETCHUP_ACTIVATION#STORE_ACTIVATION_IN_PROGRESS',
  });

  yield fork(activateLicenseServer);
}

/**
 * activateLicenseServer tries to activate a locally running license server by sending a request to its /activate
 * endpoint with the access token obtained during registration/login.
 *
 * It will try to activate it 5 times and if it succeeds it will report it by storing a success in the redux store. If
 * all 5 times fail it will store the last error in the store and cancel the saga.
 *
 * @returns {Object} -
 */
function* activateLicenseServer() {
  const maxRetries = 4; // 1 initial + 4 retries
  const retriesDelay = 2 * 1000;

  const socialRegister = yield select((state) => state.socialRegister);

  if (!socialRegister.accessToken) {
    yield put({
      type: 'SUBMIT_SKETCHUP_ACTIVATION#STORE_ACTIVATION_ERROR',
      error: new Error('Failed to obtain access token'),
    });
    yield cancel();
  }

  let retriesLeft = maxRetries;
  while (true) {
    try {
      yield call(Api.fetch, `/activate?access_token=${socialRegister.accessToken}&noredirect=1`, {
        method: 'GET',
        host: 'http://localhost:30304',
        onUnauthorized: 'throw',
        on4xx: 'throw',
        on5xx: 'throw',
        mode: 'cors',
        parseJSON: false,
        credentials: 'omit',
        skipCSRFHeader: true,
        timeout: 10000,
      });

      yield put({ type: 'SUBMIT_SKETCHUP_ACTIVATION#SUCCESS' });
      break;
    } catch (error) {
      if (retriesLeft <= 0) {
        yield put({
          type: 'SUBMIT_SKETCHUP_ACTIVATION#STORE_ACTIVATION_ERROR',
          error: new Error(`License server activation failed: ${error.message || 'reason unknown'}`),
        });

        yield cancel();
      }

      retriesLeft -= 1;
      yield delay(retriesDelay);
    }
  }
}
