/* eslint-disable no-param-reassign */
import store from '../../store';

export default function (url, params = {}) {
  const {
    app: { csrfToken = '' },
  } = store.getState();

  if (!('headers' in params)) {
    params.headers = {};
  }

  if (csrfToken) {
    params.headers['X-CSRF-Token'] = csrfToken;
  }

  if (!('credentials' in params)) {
    params.credentials = 'include';
  }

  return fetch(url, params);
}
