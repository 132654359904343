import makeAsyncScriptLoader from 'react-async-script';
import ReCAPTCHA from './Recaptcha';

const lang =
  typeof window !== 'undefined' && window.recaptchaOptions && window.recaptchaOptions.lang
    ? `&hl=${window.recaptchaOptions.lang}`
    : '';
const reCaptcahURL = `https://www.google.com/recaptcha/api.js?&render=explicit${lang}`;
const globalName = 'grecaptcha';

export default makeAsyncScriptLoader(ReCAPTCHA, reCaptcahURL, {
  globalName,
  exposeFuncs: ['getValue', 'reset', 'execute'],
});
