import { put, takeLatest, cancel, fork, call, select } from 'redux-saga/effects';

import { isLength, isEmail, validateData } from '../Utils/Validation';
import { verifiedReturnToURL, getQueryParamByName } from '../Utils/helpers';
import { Api } from '../Utils/api';

import { myChaosPersonalInfo } from './constants';

const loginValidations = {
  email: [
    {
      f: (v) => isLength(v),
      message: 'Please enter your username or email address.',
    },
    {
      f: (v) => isEmail(v),
      message: 'Please enter a valid email.',
    },
  ],
};

export function* navigateCorporateLogin() {
  yield put({ type: 'CORPORATE_LOGIN_RESET' });
  yield fork(watchSubmitLogin);
}

function* onSubmitLogin() {
  try {
    const loginData = yield select((state) => state.corporateLogin);

    const fieldErrors = yield call(() => validateData(loginData, loginValidations));

    if (fieldErrors) {
      yield put({ type: 'SUBMIT_CORPORATE_LOGIN#ERROR', fieldErrors });
      yield cancel();
    }

    const returnURL = verifiedReturnToURL(getQueryParamByName('return_to'), myChaosPersonalInfo);

    const response = yield call(Api.fetch, '/account/federated/login', {
      method: 'POST',
      body: JSON.stringify({
        email: loginData.email,
        origin: returnURL,
      }),
      onUnauthorized: 'throw',
      on5xx: 'throw',
    });

    window.location.href = response.redirect_to;

    yield put({ type: 'SUBMIT_CORPORATE_LOGIN#COMPLETE' });
  } catch (error) {
    switch (error.error_code) {
      case 'no_identity_provider':
        yield put({
          type: 'SUBMIT_CORPORATE_LOGIN#ERROR',
          fieldErrors: {
            email: 'We cannot find a corporate account with that email.',
          },
        });
        break;
      default:
        yield put({
          type: 'SUBMIT_CORPORATE_LOGIN#ERROR',
          error: {
            message: error.error_message,
            guid: error.error_guid,
          },
        });
    }
  }
}

export function* watchSubmitLogin() {
  yield takeLatest('SUBMIT_CORPORATE_LOGIN', onSubmitLogin);
}

export function* validateCorporateMail(value) {
  const { oidc, password } = yield getCorporateEmailInfo(value);

  return Boolean(password) || !oidc;
}

export function* getCorporateEmailInfo(value) {
  return yield call(Api.fetch, '/account/login/modes', {
    method: 'POST',
    body: JSON.stringify({ username: value }),
  });
}
