import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ContentHeader from '../Layout/ContentHeader';
import TextField from '../Form/TextField';
import Select from '../Form/Select';
import { InputError } from '../Form/errors';
import Link from '../Helper/Link';
import { getQueryParamByName } from '../Utils/helpers';
import { Cover } from '../Helper/Cover';

/* eslint-disable complexity */
const Profile = withTranslation()(({ data, onInputChange, onSubmit, returnTo, t }) => {
  const confirmUnsavedChanges = t('Leave page?\nChanges that you made may not be saved.');
  return (
    <div className="wrapper profile edit">
      <Cover
        className="column image-cover"
        backgroundImage='url("/images/sign-in.jpg")'
        copyright="© Joaquim Puigdomenech"
      />
      <div className="column content">
        <ContentHeader
          link={
            <Link
              id="link-logout"
              action="LOGOUT"
              confirmMessage={data.hasUnsavedChanges ? confirmUnsavedChanges : null}
            >
              {t('Logout')}
            </Link>
          }
        />
        <div className="wrapper-shrink">
          <h1 className="title">{t('Edit profile')}</h1>
          {data.profileUpdated && (
            <div className="profile-updated">
              <div className="alert">{t('Your profile was updated!')}</div>
              {returnTo && (
                <span>
                  {t('Go back to')} <a href={returnTo}>{t(`${window.THEME} website`)}</a>
                </span>
              )}
            </div>
          )}

          {data.initializing && <h5>{t('Loading your account...')}</h5>}

          {!data.initializing && (
            <form
              id="form-profile"
              action=""
              method="post"
              onSubmit={(e) => e.preventDefault() || (!data.loading && onSubmit())}
            >
              <TextField type="email" inputName="email" value={data.email} label={t('Email')} disabled />
              <TextField
                inputName="firstName"
                value={data.firstName}
                label={t('First name')}
                error={data.fieldErrors.firstName}
                onChange={(e) => e.preventDefault() || onInputChange(e.target.id, e.target.value)}
              />
              <TextField
                inputName="lastName"
                value={data.lastName}
                label={t('Last name')}
                error={data.fieldErrors.lastName}
                onChange={(e) => e.preventDefault() || onInputChange(e.target.id, e.target.value)}
              />
              <Select
                selectName="countryCode"
                label={t('Country')}
                value={data.countryCode}
                error={data.fieldErrors.countryCode}
                options={data.countries}
                onChange={(e) => e.preventDefault() || onInputChange(e.target.id, e.target.value)}
                onInputChange={onInputChange}
              />
              {window.THEME !== 'enscape' && data.customerNumber && (
                <TextField
                  inputName="customerNumber"
                  value={data.customerNumber}
                  label={t('Customer Number')}
                  disabled
                />
              )}

              <div className="form-field-wrapper button-wrapper">
                <button id="btn-save-changes" type="submit" className="button btn-active" disabled={data.loading}>
                  {t('Save changes')}
                </button>
                <p style={{ marginTop: 20 }}>{data.error && <InputError err={data.error} />}</p>
              </div>
            </form>
          )}
          {data.passwordAllowed && (
            <>
              <hr />
              <div className="change-password">
                <Link
                  id="link-change-password"
                  action="PASSWORD_CHANGE"
                  confirmMessage={data.hasUnsavedChanges ? confirmUnsavedChanges : null}
                >
                  {t('Change password')}
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});
/* eslint-enable complexity */

Profile.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    data: state.profileEdit,
    returnTo: getQueryParamByName('return_to'),
  }),
  (dispatch) => ({
    onInputChange: (id, value) => dispatch({ type: 'EDIT_ACCOUNT_INPUT_CHANGE', data: { id, value } }),
    onSubmit: () => dispatch({ type: 'SUBMIT_EDIT_ACCOUNT' }),
  }),
)(Profile);
