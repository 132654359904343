import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Layout from '../Layout';
import LoginLink from '../Helper/LoginLink';
import TextField from '../Form/TextField';
import { FormError } from '../Form/errors';

const form = (data, onInputChange, onSubmit, t) => {
  return (
    <div className="wrapper-shrink">
      <h1 className="title">{t('Forgot your password?')}</h1>
      <h5 className="subtitle">
        {t("Enter your email and we'll")} <br />
        {t('send you instructions to reset your password')}
      </h5>
      {data.error && <FormError error={data.error} />}
      <form
        id="form-recover-password"
        action=""
        method="post"
        onSubmit={(e) => e.preventDefault() || (!data.loading && onSubmit())}
      >
        <TextField
          type="email"
          inputName="email"
          value={data.email}
          label={t('Email')}
          autoFocus
          error={data.fieldErrors.email}
          onChange={(e) => e.preventDefault() || onInputChange(e.target.value)}
        />
        <div className="form-field-wrapper button-wrapper">
          <button id="btn-recover" type="submit" className="button btn-active" disabled={data.loading}>
            {t('Recover')}
          </button>
        </div>
      </form>
    </div>
  );
};

const success = (email, t) => {
  const getLoginLinkLabel = (theme) => {
    if (theme === 'enscape') {
      return 'Back to sign in';
    }

    return 'Back to login';
  };
  return (
    <div className="wrapper-shrink success">
      <h1 className="title">{t('Thank you!')}</h1>
      <div className="signup-confirmation">
        <p id="msg-reset-password">
          {t("We've sent an email to")} <strong>{email}</strong>&nbsp;
          {t("with a link to reset your password. If you don't see the email, please check your spam folder.")}
        </p>
        <LoginLink className="button btn-active">{t(getLoginLinkLabel(window.THEME))}</LoginLink>
      </div>
    </div>
  );
};

const PasswordRecover = withTranslation()(({ data, onInputChange, onSubmit, t }) => (
  <Layout
    className="profile recover"
    cover={{ src: '/images/sign-in.jpg', author: 'Joaquim Puigdomenech' }}
    type="password-recover"
    contentHeaderTitle={t('Already have an account?')}
    contentHeaderLink={<LoginLink>{t('Sign in')}</LoginLink>}
  >
    {data.success ? success(data.email, t) : form(data, onInputChange, onSubmit, t)}
  </Layout>
));

export default connect(
  (state) => ({ data: state.profileRecoverPassword }),
  (dispatch) => ({
    onInputChange: (value) => dispatch({ type: 'RECOVER_PASSWORD_INPUT_CHANGE', data: { email: value } }),
    onSubmit: () => dispatch({ type: 'SUBMIT_RECOVER_PASSWORD' }),
  }),
)(PasswordRecover);
