import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ContentHeader from '../Layout/ContentHeader';
import LoginLink from '../Helper/LoginLink';
import { Cover } from '../Helper/Cover';
import { isEnscape } from '../Utils/helpers';

import ActivateSuccessEnscape from './ActivateSuccessEnscape';

const Activate = withTranslation()(({ requestStatus, error, t }) => {
  const capitalizedThemeName = `${window.THEME.charAt(0).toUpperCase()}${window.THEME.slice(1)}`;

  if (isEnscape()) {
    return <ActivateSuccessEnscape requestStatus={requestStatus} error={error} />;
  }

  return (
    <div className="wrapper profile activate">
      <Cover className="column image-cover" backgroundImage='url("/images/sign-up2.jpg")' copyright="© Nmachine" />
      <div className="column content">
        <ContentHeader />
        <div className="wrapper-shrink">
          {requestStatus === 'OK' && (
            <>
              <h1 className="title">{t('Your account is active')}</h1>
              <h5 className="subtitle">{t(`Welcome to ${capitalizedThemeName}!`)}</h5>

              <div className="button-wrapper">
                <LoginLink id="btn-login" className="button btn-active">
                  {t('Sign in')}
                </LoginLink>
              </div>
            </>
          )}
          {error && (
            <h5 id="msg-error">
              <span className="icon-minus" />
              {t(error.message)} Ref. {error.guid}
            </h5>
          )}
        </div>
      </div>
    </div>
  );
});

export default connect((state) => state.profileActivate)(Activate);
