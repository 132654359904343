/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { InputError } from './errors';

export default function Select({
  id,
  selectName,
  value,
  label,
  disabled,
  autoFocus,
  options,
  error,
  valid,
  tabIndex,
  onChange,
}) {
  const fieldId = id || selectName;

  return (
    <div className="form-field-wrapper">
      {label && <label htmlFor={id}>{label}</label>}
      <div className="style-select">
        <select id={fieldId} name={selectName} {...{ value, disabled, autoFocus, tabIndex, onChange }}>
          {selectItems(options)}
        </select>
      </div>
      {error && <InputError id={`${fieldId}-error`} err={error} />}
      {valid && <span className="field-icon valid-icon" />}
    </div>
  );
}

function selectItems(options) {
  return options.map((option) => (
    <option key={option.key} value={option.key}>
      {option.value}
    </option>
  ));
}

Select.propTypes = {
  value: PropTypes.string.isRequired,
  selectName: PropTypes.string.isRequired,
  autoComplete: PropTypes.oneOf(['on', 'off', 'new-password']),
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
  validating: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
};
