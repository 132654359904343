import React from 'react';
import { withTranslation } from 'react-i18next';

import ChaosCloud from '../Layout/Covers/ChaosCloud';
import LoginLink from '../Helper/LoginLink';

import Layout from '../Layout';

const Success = withTranslation()(({ t }) => {
  return (
    <div className="wrapper ula success">
      <Layout cover={<ChaosCloud />}>
        <img src="/images/ULA/Success.svg" alt="success" />
        <div className="title">
          <strong>{t('Sign out complete.')}</strong>
        </div>
        <div className="description">{t('You are now signed out of all Chaos products licensed to you')}</div>
        <strong>{t('You may now close this window.')}</strong>
        <div className="login-button-wrapper">
          <a className="button muted" href={window.HOME_PAGE}>
            {t('Go to chaos.com')}
          </a>
        </div>
        <div className="signin-info">{t('Sign in with a different account?')}</div>
        <LoginLink id="btn-login" className="button btn-active">
          {t('Sign in')}
        </LoginLink>
      </Layout>
    </div>
  );
});

export default Success;
