const initialState = {
  error: '',
};

export default function (state = initialState, payload) {
  switch (payload.type) {
    case 'LOGIN':
      return state;
    case 'LOGIN#ERROR':
      return { ...state, error: payload.data.error };
    default:
      return state;
  }
}
