import { select, call, fork, take, takeLatest, spawn } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { saga as routerSaga } from 'redux-saga-first-router';
import { createBrowserHistory } from 'history';

import { reportSagaError } from './app/Utils/Monitoring';
import { routesMap, watchRouterNavigate, excludeInitRoutes } from './routes';
import { init } from './app/App/appSagas';

const browserHistory = createBrowserHistory();

// rootSaga can be modified to watch more sagas like:
// ```
// export function* rootSaga() {
//   yield all([watchRouterNavigate(), ...watcherN()]);
// }
// ```
export function* rootSaga() {
  yield fork(watchRouterNavigate);
  yield fork(watchApiError);
  if (!excludeInitRoutes.includes(window.location.pathname)) {
    yield call(init);
  }
  yield spawn(routerSaga, routesMap, browserHistory);
}

// halt is used to indicate that the saga is done,
// but termination is still not needed, as for eg.
// it needs to perform clean-up when it is canceled
// by a navigation event.
export function* halt() {
  yield take('__NA__');
}

export const sagaMiddleware = createSagaMiddleware({
  onError: reportSagaError,
});

function* watchApiError() {
  yield takeLatest('API_ERROR#RETRY', retryApiError);
}

function* retryApiError() {
  const { retryHandler } = yield select((state) => state.app.apiError);
  if (retryHandler) {
    yield call(retryHandler);
  }
}
