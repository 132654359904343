import React from 'react';
import { withTranslation } from 'react-i18next';

import ChaosCloud from '../Layout/Covers/ChaosCloud';

import Layout from '../Layout';

const Success = withTranslation()(({ t }) => {
  return (
    <div className="wrapper ula success">
      <Layout cover={<ChaosCloud />}>
        <img src="/images/ULA/Success.svg" alt="success" />
        <div className="title">
          <strong>{t('Sign in complete.')}</strong>
        </div>
        <div className="description">{t('You can now start using all Chaos products licensed to you')}</div>
        <div>
          <strong>{t('You may now close this window.')}</strong>
        </div>
      </Layout>
    </div>
  );
});

export default Success;
