import React from 'react';
import { withTranslation } from 'react-i18next';

import Loading from '../Loading';

const Activate = withTranslation()(() => {
  return <Loading cover={{ backgroundImage: 'url("/images/trial-banner.png")', copyright: '© Chaos' }} />;
});

export default Activate;
