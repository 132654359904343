import 'url-polyfill'; // add support for URL in IE10+
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { reportJsError } from './app/Utils/Monitoring';
import { translations } from './translations';
import App from './app/App';
import { sagaMiddleware, rootSaga } from './sagas';
import store from './store';
import './styles/sass/index.scss';

Sentry.init({
  dsn: window.SENTRY_DSN,
  environment: window.ENV,
});

sagaMiddleware.run(rootSaga);

window.onerror = reportJsError;

window.onload = () => {
  const faviconElement = document.querySelector('#favicon');
  // eslint assumes "process" is undefined
  // eslint-disable-next-line no-undef
  faviconElement.href = `${process.env.PUBLIC_URL}/${window.THEME}-favicon.ico`;
};

i18n.use(initReactI18next).init({
  resources: translations,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  nsSeparator: false,
  keySeparator: false,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
