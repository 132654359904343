import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Cover } from '../Helper/Cover';

import { sketchupStudioRedirect } from '../Utils/helpers';

/**
 * Builds and returns the main part of the Complete page - the one where the overall status is displayed.
 *
 * @param {Object} activationResult the activation result.
 * @param {String} [accessToken] Activation token.
 * @param {boolean} redirecting the redirect state.
 * @returns {React.Fragment} -
 */
function content(activationResult = {}, accessToken, redirecting) {
  if (!activationResult.error) {
    // All good. Thank you!
    return (
      <>
        <h1 className="title">Thank you</h1>
        <h5 id="gtm-trimble-activation-successful" className="subtitle">
          You are ready to start using V-Ray for SketchUp Studio
        </h5>
        <button
          type="button"
          id="gtm-trimble-get-started"
          className="button btn-passive-wireframe"
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex="1"
          disabled={redirecting}
          onClick={() => sketchupStudioRedirect()}
        >
          Access learning content
        </button>
        <p>V-Ray is ready to use in your desktop client</p>
      </>
    );
  }

  // both permanent error and in-progress share the same title.
  const { error } = activationResult;
  const title = (
    <>
      <h1 className="title">V-Ray for Sketchup Studio</h1>
      <h5 className="subtitle">We are almost there</h5>
    </>
  );

  let activationStatus;
  if (window.safari && accessToken) {
    // There is currently a problem with Safari's CORS policy
    // regarding localhost -  it does not make a request at all.
    const href = `http://localhost:30304/activate?access_token=${encodeURIComponent(accessToken)}`;
    activationStatus = (
      <>
        <div className="activation-status-running">Activating your License Server is a click away …</div>
        <div className="dark-gray">
          To use V-Ray as part of SketchUp Studio you need to activate
          <br />
          your License Server
        </div>
        <a className="button btn-active btn-ls-prompt" href={href} target="_blank" rel="noopener noreferrer">
          Activate License Server
        </a>
      </>
    );
  } else if (error.temporary === true) {
    // activation in progress
    activationStatus = (
      <>
        <div className="activation-status-running">Activating your License Server. Please wait</div>
        <div className="loading-dots" />
      </>
    );
  } else {
    // permanent error
    activationStatus = (
      <>
        <div id="gtm-trimble-activation-failed" className="activation-status-failed">
          Your License Server activation failed
        </div>
        <p className="">
          To use V-Ray as part of Sketchup Studio please contact
          <br />
          <a href="https://help.sketchup.com/en/contact-support">Customer Support Center</a>
        </p>
      </>
    );
  }
  return (
    <>
      {title}
      {activationStatus}
    </>
  );
}

const SketchupLoginComplete = withTranslation()(({ data, app, accessToken, t }) => (
  <div className="wrapper signup signup-complete sketchup">
    <Cover className="column image-cover" backgroundImage='url("/images/trimble/background.jpg")' copyright="© Chaos" />
    <div className="column content complete content-centered">
      <div className="content-centered-inner">
        {content(data.activation, accessToken, app.redirecting, t)}
        <div className="hr hr-light" />
        <p className="dark-gray">
          {t('A Chaos account has been created and linked to your')}
          <br />
          {t('Trimble Account.')}
        </p>
        <h5 id="full-name" className="name">
          {data.fullName}
        </h5>
        <p id="email">{data.email}</p>
      </div>
    </div>
  </div>
));

export default connect((state) => ({
  data: state.socialLogin,
  app: state.app,
  accessToken: state.socialRegister.accessToken,
}))(SketchupLoginComplete);
