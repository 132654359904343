/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate } from 'redux-saga-first-router';
import { routesMap } from '../../routes';
import { getQueryParamByName, setQueryParam } from '../Utils/helpers';

function Link({ action, children, dispatch, returnTo, promptQuery, confirmMessage, ...props }) {
  const navigateParams = { query: {} };
  props.href = routesMap.get(action).path;
  if (returnTo) {
    props.href = setQueryParam(props.href, 'return_to', returnTo);
    navigateParams.query.return_to = returnTo;
  }
  if (promptQuery) {
    props.href = setQueryParam(props.href, 'prompt', promptQuery);
    navigateParams.query.prompt = promptQuery;
  }

  props.onClick = (e) => {
    e.preventDefault();

    if (confirmMessage) {
      const continueNavigate = window.confirm(confirmMessage);
      if (!continueNavigate) {
        return;
      }
    }

    dispatch(navigate(action, null, navigateParams));
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <a {...props}>{children}</a>;
}

Link.propTypes = {
  action: PropTypes.string.isRequired,
  className: PropTypes.string,
  tabIndex: PropTypes.string,
  confirmMessage: PropTypes.string,
};

export default connect(() => ({
  returnTo: getQueryParamByName('return_to'),
  promptQuery: getQueryParamByName('prompt'),
}))(Link);
