import { put, call, cancel } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';
import csrfFetch from '../Utils/fetch';

export function* navigateDiscourseLogin() {
  const queryParams = window.location.search;
  const query = {
    return_to: window.location.href,
  };

  try {
    const res = yield call(() => fetch(`${window.SSO_GATEWAY}/session`, { credentials: 'include' }));
    const sessionData = yield call(() => res.json().then((d) => d));
    if (!sessionData.UserGUID) {
      yield put(navigate('SERVICE_LOGIN', null, { query }));
      yield cancel();
    }

    const data = yield call(() =>
      csrfFetch(`${window.SSO_GATEWAY}/discourse/sso${queryParams}`, {
        method: 'GET',
      }).then((r) => r.json()),
    );

    if (data.loginUrl) {
      window.location = data.loginUrl;
      yield cancel();
    }

    if (data.error) {
      const error = data.error.message;
      yield put({ type: 'LOGIN#ERROR', data: { error } });
    }
  } catch (error) {
    yield put({ type: 'LOGIN#ERROR', data: { error } });
  }
}
