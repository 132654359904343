/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LoadingOverlay from '../Helper/LoadingOverlay';
import Layout from '../Layout';
import TrimbleLoginButton from '../SocialAccounts/TrimbleLoginButton';

import Trimble from '../Layout/Covers/Trimble';
import { TRIMBLE_STYLE } from '../Helper/constants';

const LoginWithTrimble = () => (
  <TrimbleLoginButton id="btn-trimble-login" buttonStyle={TRIMBLE_STYLE.DARK} autoRegister trimbleActivationFlow />
);

const TrimbleLogin = withTranslation()(({ data, app, t }) => (
  <>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Layout className="signup wrapper-trimble-login" cover={<Trimble />}>
      <LoadingOverlay active={data.loading || app.redirecting} />

      <div className="trimble-login-content content-centered-inner">
        <h3 className="title">V-Ray for SketchUp Studio</h3>
        <h5 className="subtitle">{t('Fast interactive rendering. New, smart functionality.')}</h5>
        <h6 className="login-hint">
          {t('Sign in with your Trimble account to start using V-Ray for SketchUp Studio')}
        </h6>
        <LoginWithTrimble />
      </div>
    </Layout>
  </>
));

TrimbleLogin.propTypes = {
  data: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
};

export default connect((state) => ({
  data: state.socialLogin,
  app: state.app,
}))(TrimbleLogin);
