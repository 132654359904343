const socialLoginState = {
  platform: null,
  error: null,
  loading: false,
  activation: {},
};

const socialRegisterState = {
  countries: [{ key: -1, value: 'Please select your country' }],
  countryCode: '',
  firstName: '',
  lastName: '',
  newsletterSubscription: false,
  loading: false,
  error: null,
  fieldErrors: {},
  fieldsValidating: {},
};

const linkExistingState = {
  password: '',
  platform: '',
  existing: false,
  loading: false,
  error: null,
  fieldErrors: {},
  fieldWarnings: {},
};

export function socialLoginReducer(state = socialLoginState, payload) {
  switch (payload.type) {
    case 'SOCIAL_LOGIN#RESET':
      return socialLoginState;
    case 'SOCIAL_LOGIN#START':
      return { ...state, loading: true };
    case 'SOCIAL_LOGIN#ERROR':
      return { ...state, error: payload.error, loading: false };
    case 'SUBMIT_TRIMBLE_AUTO_LOGIN':
      return {
        ...state,
        platform: payload.platform,
        autoRegister: payload.autoRegister,
      };
    case 'SUBMIT_SKETCHUP_ACTIVATION#STORE_ACTIVATION_IN_PROGRESS':
      return { ...state, activation: { error: { temporary: true } } };
    case 'SUBMIT_SKETCHUP_ACTIVATION#STORE_ACTIVATION_ERROR':
      return { ...state, activation: { error: { ...payload.error, temporary: false } } };
    case 'SUBMIT_SKETCHUP_ACTIVATION#SUCCESS':
      return { ...state, activation: {} };
    default:
      return state;
  }
}

export function socialRegisterReducer(state = socialRegisterState, payload) {
  switch (payload.type) {
    case 'SOCIAL_REGISTER':
      return { ...state, error: null };
    case 'SOCIAL_REGISTER_INPUT_CHANGE':
      return {
        ...state,
        fieldErrors: { ...state.fieldErrors, [payload.data.id]: false },
        [payload.data.id]: payload.data.value,
      };
    case 'POPULATE_COUNTRIES':
      return { ...state, countries: payload.data.countries, countryCode: payload.data.location };
    case 'POPULATE_FIRST_NAME':
      return { ...state, firstName: payload.data.firstName };
    case 'POPULATE_LAST_NAME':
      return { ...state, lastName: payload.data.lastName };
    case 'SUBMIT_SOCIAL_REGISTER':
      return { ...state, loading: true };
    case 'SUBMIT_SOCIAL_REGISTER#ERROR':
      return { ...state, error: payload.error, loading: false };
    case 'SUBMIT_SOCIAL_REGISTER#VALIDATION_ERROR':
      return { ...state, error: payload.error || null, fieldErrors: payload.data.fieldErrors, loading: false };
    case 'SUBMIT_SOCIAL_REGISTER#COMPLETE':
      return { ...state, accessToken: payload.accessToken, loading: false };
    case 'VALIDATE_INPUT':
      return { ...state, fieldsValidating: { ...state.fieldsValidating, [payload.data.id]: true } };
    case 'VALIDATE_INPUT#COMPLETE':
      return {
        ...state,
        fieldsValidating: { ...state.fieldsValidating, [payload.data.id]: false },
        fieldErrors: { ...state.fieldErrors, ...payload.data.fieldErrors },
        error: null,
      };
    default:
      return state;
  }
}

export function linkExistingReducer(state = linkExistingState, payload) {
  switch (payload.type) {
    case 'LINK_EXISTING_ACCOUNT_INPUT_CHANGE':
      return {
        ...state,
        [payload.data.id]: payload.data.value,
      };
    case 'SUBMIT_LINK_ACCOUNT':
      return { ...state, loading: true };
    case 'SUBMIT_LINK_ACCOUNT#RESET':
      return { ...linkExistingState };
    case 'SUBMIT_LINK_ACCOUNT#ERROR':
      return { ...state, loading: false, error: payload.error };
    case 'SUBMIT_LINK_ACCOUNT#COMPLETE':
      return { ...state, accessToken: payload.data.accessToken };
    default:
      return state;
  }
}
