/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Layout from './Layout';
import { IconError } from './Layout/Icons';

const ErrorPage = withTranslation()(({ recoveryAction, routing, t }) => {
  const Action = recoveryAction;
  const { error_code, error_id, error_description } = routing.query || {};

  const errorCodeTitles = {
    internal_server_error: t('Oops... something went wrong'),
    invalid_authentication: t('Authentication error'),
    user_disabled: t('User disabled'),
    session_expired: t('Session expired'),
  };

  const errorCodeMessages = {
    internal_server_error: (
      <span>
        {t('We might be having some technical difficulties at the moment.')}
        <br />
        {t('Try to sign in again and if you still experience problems, please')}{' '}
        <a href={window.HELP_PAGE}>{t('contact our support')}</a>.
      </span>
    ),
    invalid_authentication: (
      <span>
        {t('We failed to authenticate you.')}
        <br />
        {t(
          'Make sure you sign in with the same user on your company login page as the one you used on the Corporate Sign In page.',
        )}
      </span>
    ),
    external_idp_error: (
      <span>{t("We couldn't sign you in. If the problem persists please contact your system administrator.")}</span>
    ),
    social_idp_error: (
      <span>
        {t('We could not sign you in. If the problem persists, please')}{' '}
        <a href={window.HELP_PAGE}>{t('contact our support')}.</a>
      </span>
    ),
    social_invalid_authentication: <span>{t('We failed to authenticate you.')}</span>,
    user_disabled: (
      <span>
        {t('Your user has been disabled in our system.')} <br />
        {t('Please contact your system administrator.')}
        <br />
      </span>
    ),
    social_user_disabled: (
      <span>
        {t('Your user has been disabled in our system.')} <br />
        {t('Please')} <a href={window.HELP_PAGE}>{t('contact our support')}.</a>
      </span>
    ),
    session_expired: <span>{t('Your session has expired. Please sign in again to continue using our platform.')}</span>,
    legacy_token_error: (
      <span>
        {t('We could not activate your license server.')}
        <br />
        {t('Try to activate it manually')} <a href="http://localhost:30304">{t('here')}</a>.
      </span>
    ),
    legacy_invalid_authentication: (
      <span>
        {t('We could not activate your license server.')}
        <br />
        {t('Try to activate it manually')} <a href="http://localhost:30304">{t('here')}</a>.
      </span>
    ),
  };

  const errorTitle = () => {
    return errorCodeTitles[error_code] || errorCodeTitles.internal_server_error;
  };

  const errorMessage = () => {
    if (error_code) {
      return errorCodeMessages[error_code] || t(error_code);
    }
    if (error_description) {
      return t(error_description);
    }
    return errorCodeMessages.internal_server_error;
  };

  return (
    <Layout cover={{ src: '/images/sign-up.jpg', author: 'Double Aye' }}>
      <div className="wrapper-error wrapper-shrink">
        <div className="form-error">
          <IconError size={42} />
          <h3>
            <strong>{errorTitle()}</strong>
          </h3>
          <h5 id="msg-error" className="error">
            {errorMessage()}
          </h5>
          {error_id && <p className="error-guid">{error_id}</p>}
        </div>
        {Action && <Action />}
      </div>
    </Layout>
  );
});

export default connect((state) => ({
  routing: state.routing,
}))(ErrorPage);
