import { put, takeLatest, takeEvery, cancel, call, select, fork } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';
import { watchResendActivation } from '../Profile/profileSagas';
import { watchSubmitSocialLogin } from '../SocialAccounts/socialAccountsSagas';

import { Api } from '../Utils/api';
import {
  isEmail,
  isLength,
  containsDigits,
  containsSpecialSymbols,
  containsLowerAndUpperCaseLetters,
  containsNonLatinLetters,
  validateData,
} from '../Utils/Validation';
import { verifiedReturnToURL, getQueryParamByName } from '../Utils/helpers';
import { getCookie } from '../Utils/cookiesHelper';
import { validateCorporateMail } from '../CorporateLogin/corporateSagas';
import { errorMessage, linkMessage } from '../CorporateLogin/constants';
import { getCorporateLoginURL } from '../CorporateLogin/utils';

import { CUSTOM_ERRORS } from '../Form/constants';

export const UNKNOWN_COUNTRY_CODE = 'ZZ';

export function* navigateRegister() {
  yield takeLatest('REGISTER_INPUT_CHANGE', registerInputChange);
  yield takeEvery('VALIDATE_INPUT', validateInput);
  yield takeLatest('SUBMIT_REGISTER', handleSubmitRegister);
  yield takeLatest('SUBMIT_REGISTER#SET_STEP', handleSetStep);
  yield takeLatest('SUBMIT_REGISTER#VALIDATE_CAPTCHA', handleValidateCaptcha);
  yield takeLatest('GENERATE_CG_CAPTCHA', generateCgCaptcha);
  yield fork(watchResendActivation);
  yield fork(watchSubmitSocialLogin);

  yield call(initializeRegisterData);
}

function* initializeRegisterData() {
  yield put({ type: 'REGISTER_RESET' });
  yield put({ type: 'RESEND_ACTIVATION_CODE_RESET' });

  const appData = yield select((state) => state.app);

  const data = yield call(Api.fetch, '/countries', { retryHandler: initializeRegisterData });
  const countries = data.map((country) => ({ key: country.code, value: country.name }));

  yield put({ type: 'POPULATE_COUNTRIES', data: { countries, location: appData.location || UNKNOWN_COUNTRY_CODE } });

  const prefillEmail = getQueryParamByName('email');
  if (prefillEmail) {
    yield put({ type: 'PREFILL_EMAIL_REGISTER', email: prefillEmail });

    // redirect to sign in page if email is already registered
    const reponse = yield call(validateField, 'email', prefillEmail);
    if (reponse.status === 'conflict') {
      yield put(navigate('LOGIN'));
    }
  }
}

export function* navigateRegisterComplete() {
  const registerSuccess = yield select((state) => state.register.success);
  if (!registerSuccess) {
    yield put(navigate('LOGIN'));
  }

  yield fork(watchResendActivation);
}

function* registerInputChange(payload) {
  if (!payload.data || !payload.data.id || !warningRules[payload.data.id]) {
    yield cancel();
  }

  const data = yield select((state) => state.register);
  const fieldName = payload.data.id;
  const fieldWarnings = yield call(validateData, data, { [fieldName]: warningRules[fieldName] });

  yield put({ type: 'REGISTER_INPUT_CHANGE#WARN', fieldWarnings });
}

function* validateInput(payload) {
  if (!payload.data || !payload.data.id || !validationRules[payload.data.id]) {
    yield cancel();
  }

  const fieldName = payload.data.id;

  const data = yield select((state) => state.register);
  const fieldErrors = yield call(validateData, data, { [fieldName]: validationRules[fieldName] });

  yield put({ type: 'VALIDATE_INPUT#COMPLETE', data: { id: payload.data.id, fieldErrors } });
}

export function* handleSubmitRegister({ isTrial = false }) {
  const registerData = yield select((state) => (isTrial ? state.trial.fields : state.register));
  const captchaData = yield select((state) => state.register);
  const fieldErrorAction = isTrial ? 'TRIAL_SET_FIELD_ERRORS' : 'SUBMIT_REGISTER#VALIDATION_ERROR';
  let fieldErrors = null;
  if (isTrial) {
    const { password, ...otherRules } = validationRules;
    fieldErrors = yield call(validateData, registerData, otherRules);
  } else if (registerData.registrationStep === 1) {
    fieldErrors = yield call(() => validateStep1(registerData));
  } else if (registerData.registrationStep === 2) {
    fieldErrors = yield call(() => validateStep2(registerData));
  }

  if (fieldErrors && Object.values(fieldErrors).every(Boolean)) {
    yield put({ type: fieldErrorAction, data: { fieldErrors } });
    yield cancel();
  }

  if (registerData.registrationStep === 1) {
    return yield put({ type: 'SUBMIT_REGISTER#SET_STEP', data: { registrationStep: 2 } });
  }

  if (!captchaData.captchaValue && captchaData.captcha) {
    return yield call(() => captchaData.captcha.execute());
  }
  return yield call(handleValidateCaptcha, { data: { isTrial } });
}

// handleValidateCaptcha is a callback function triggered by the SUBMIT_REGISTER#VALIDATE_CAPTCHA action
// provided to the Recaptcha component as a prop.
export function* handleValidateCaptcha({ data }) {
  const registerData = yield select((state) => (data.isTrial ? state.trial.fields : state.register));
  const captchaData = yield select((state) => state.register);

  if (!captchaData.captchaValue && captchaData.captcha) {
    yield call(() => captchaData.captcha.execute());
    yield cancel();
  }

  // Parse utm params if any
  let utmParams = {};
  const utmParamsCookie = getCookie('utm_campaign_params');
  if (utmParamsCookie) {
    try {
      utmParams = JSON.parse(decodeURIComponent(utmParamsCookie));
    } catch (err) {
      console.log('Error decoding utm params');
    }
  }
  const httpReferer = decodeURIComponent(getCookie('http_referer_param'));

  const action = `${data.isTrial ? 'TRIAL_' : ''}SUBMIT_REGISTER`;

  const routing = yield select((state) => state.routing);
  const trialParams = { productCode: '' };
  if (data.isTrial) {
    const trialProductCode = 'CS00MPMOTCL001';
    trialParams.productCode = routing.query?.productCode || trialProductCode;

    const returnTo = getQueryParamByName('return_to');
    if (returnTo.includes('try.enscape3d.com')) {
      trialParams.isEnscapeTrial = true;
    } else {
      trialParams.isTrial = true;
    }
  }

  try {
    const reqData = yield call(Api.fetch, '/account/register', {
      method: 'POST',
      body: JSON.stringify({
        captchaGUID: captchaData.captchaId,
        captchaAnswer: captchaData.captchaValue,
        email: registerData.email,
        password: registerData.newPassword,
        firstName: registerData.firstName,
        lastName: registerData.lastName,
        countryCode: registerData.countryCode,
        newsletterSubscription: registerData.newsletterSubscription,
        returnTo: verifiedReturnToURL(getQueryParamByName('return_to')),
        utmMedium: utmParams.utm_medium || '',
        utmSource: utmParams.utm_source || '',
        utmCampaign: utmParams.utm_campaign || '',
        httpReferer: httpReferer || '',
        ...trialParams,
      }),
      on4xx: 'throw',
      on5xx: 'throw',
    });

    if (data.isTrial) {
      const query = yield select((state) => state.routing.query) || {};
      yield put(navigate('TRIAL_REGISTER_SUCCESS', null, { query }));
    } else {
      yield put({ type: `${action}#COMPLETE`, data: reqData });
      yield put(navigate('REGISTER_COMPLETE'));
    }
  } catch (error) {
    if (captchaData.captcha) {
      yield put({ type: `SUBMIT_REGISTER#RESET_CAPTCHA` });
      yield call(() => captchaData.captcha.reset());
    }
    if (error.message.indexOf('Incorrect answer to captcha') > -1) {
      yield put({ type: `${action}#VALIDATION_ERROR`, data: { fieldErrors: { captcha: error.message } } });
    } else {
      yield put({ type: `${action}#ERROR`, data: { error } });
    }
  }
}

function* handleSetStep() {
  const appData = yield select((state) => state.app);
  const registerData = yield select((state) => state.register);

  // if entering step 2 we'd like to validate the session-set country code value and
  // generate CgCaptcha challenge if location is China
  if (registerData.registrationStep === 2) {
    if (registerData.countryCode) {
      yield call(validateInput, { data: { id: 'countryCode' } });
    }
    if (appData.location === 'CN') {
      yield call(generateCgCaptcha);
    }
  }
}

export function* generateCgCaptcha() {
  yield put({ type: `GENERATE_CG_CAPTCHA#START` });
  const data = yield call(() => fetch(`${window.SSO_GATEWAY}/captcha/generate`).then((resp) => resp.json()));
  yield put({ type: `GENERATE_CG_CAPTCHA#COMPLETE`, data });
}

function validateStep1(data) {
  const { email, newPassword } = validationRules;

  return validateData(data, { email, newPassword });
}

function validateStep2(data) {
  const { firstName, lastName, countryCode } = validationRules;

  return validateData(data, { firstName, lastName, countryCode });
}

/* Validation rules */
export const validationRules = {
  email: [
    { f: isLength, message: 'Please enter your email address.' },
    { f: isValidEmail, message: 'Please enter valid email address.' },
    {
      f: validateCorporateMail,
      message: {
        message: errorMessage,
        link: {
          text: linkMessage,
          href: getCorporateLoginURL(),
        },
      },
    },
    { f: registerValidationRule.bind(null, 'email'), message: { customError: CUSTOM_ERRORS.EMAIL_ALREADY_TAKEN } },
  ],
  password: [
    { f: isLength, message: 'Please enter your password.' },
    { f: (v) => isLength(v, { min: 8 }), message: 'Your password must be at least 8 characters long.' },
    { f: (v) => isLength(v, { max: 64 }), message: 'Your password must be at most 64 characters long.' },
  ],
  newPassword: [
    { f: containsLowerAndUpperCaseLetters, last: false, message: 'Both lower and upper case letters' },
    { f: containsDigits, last: false, message: 'At least one number' },
    { f: containsSpecialSymbols, last: false, message: 'At least one symbol' },
    { f: (v) => isLength(v, { min: 12 }), last: false, message: 'At least 12 characters' },
  ],
  firstName: [
    { f: isLength, message: 'Please enter your first name.' },
    { f: (v) => isLength(v, { max: 72 }), message: 'Your first name cannot be more than 72 characters long.' },
  ],
  lastName: [
    { f: isLength, message: 'Please enter your last name.' },
    { f: (v) => isLength(v, { max: 53 }), message: 'Your last name cannot be more than 53 characters long.' },
  ],
  countryCode: [{ f: isLength, message: 'Please enter your country.' }],
};

export const warningRules = {
  password: [{ f: (v) => !containsNonLatinLetters(v), message: 'Non-Latin letters' }],
};

function* validateField(field, value) {
  return yield call(Api.fetch, '/account/register/validate', {
    method: 'POST',
    body: JSON.stringify({ [field]: value }),
    on5xx: 'SUBMIT_REGISTER#ERROR',
  });
}

function* registerValidationRule(field, value) {
  const validateResponse = yield call(validateField, field, value);

  return validateResponse && validateResponse.status === 'ok';
}

function* isValidEmail(value) {
  const validateResponse = yield call(validateField, 'email', value);

  return isEmail(value) && validateResponse.status !== 'invalid';
}
