/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

export default function Checkbox({ id, inputName, value, disabled, label, labelDescription, onChange }) {
  const checkboxID = id || inputName;

  return (
    <div className="form-field-wrapper checkbox">
      <label className="checkbox-style" htmlFor={checkboxID} onClick={onChange}>
        <input
          id={checkboxID}
          type="checkbox"
          name={inputName}
          disabled={disabled}
          onChange={(e) => e.preventDefault()}
          checked={value}
          className="checkbox-style"
        />
        <span className="label-checkbox">
          <span className="label-text">
            {label} {labelDescription}
          </span>
        </span>
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  value: PropTypes.bool.isRequired,
  inputName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
