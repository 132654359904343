const initialState = {
  loading: false,
  error: null,
  fields: {},
  emailStatus: null,
  fieldErrors: {},
  fieldWarnings: {},
  isInitialCaptchaSet: false,
  resendActivationEmailSuccess: false,
  passwordRecoverSuccess: false,
};

export default function (state = initialState, payload) {
  switch (payload.type) {
    case 'TRIAL_INPUT_CHANGE':
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload.data,
        },
      };
    case 'TRIAL_SET_FIELD_ERRORS':
      return {
        ...state,
        fieldErrors: {
          ...state.fieldErrors,
          ...payload.fieldErrors,
        },
      };
    case 'TRIAL_POPULATE_COUNTRIES':
      return {
        ...state,
        countries: payload.data.countries,
        fields: {
          ...state.fields,
          countryCode: payload.data.location,
        },
      };
    case 'TRIAL_SET_LOADING':
      return {
        ...state,
        loading: payload.loading,
      };
    case 'TRIAL_SET_EMAIL_STATUS':
      return { ...state, emailStatus: payload.status };
    case 'SUBMIT_REGISTER':
      return { ...state, loading: true };
    case 'TRIAL_SET_IS_INITIAL_CAPTCHA_SET':
      return { ...state, isInitialCaptchaSet: payload.isInitialCaptchaSet };
    case 'TRIAL_SUBMIT_REGISTER#ERROR':
      return { ...state, error: payload.data.error, fieldErrors: {}, loading: false };
    case 'TRIAL_SUBMIT_REGISTER#VALIDATION_ERROR':
      return { ...state, error: payload.error || null, fieldErrors: payload.data.fieldErrors, loading: false };
    case 'TRIAL_RESEND_ACTIVATION_EMAIL#SUCCESS':
      return { ...state, resendActivationEmailSuccess: true };
    case 'TRIAL_PASSWORD_RECOVER#SUCCESS':
      return { ...state, passwordRecoverSuccess: true, fieldErrors: {} };
    case 'TRIAL_PASSWORD_RECOVER#RESET':
      return { ...state, passwordRecoverSuccess: false };
    case 'TRIAL_ERROR':
      return { ...state, error: payload.error, loading: false };
    default:
      return state;
  }
}
