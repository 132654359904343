import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';

import Layout from '../Layout';
import { Cover } from '../Helper/Cover';
import ProductsStrip from './ProductsStrip';

const Success = withTranslation()(({ firstName, email, success, error, resendEmail, t }) => {
  return (
    <Layout
      className="trial success"
      cover={<Cover backgroundImage='url("/images/trial-banner.png")' copyright="© Chaos" />}
    >
      <ProductsStrip />
      <div className="wrapper-shrink">
        <h1 className="title">{t('Your Chaos account has been created successfully.')}</h1>
        <p className="subtitle">{t('Activation is required.')}</p>
        <div className="success-content">
          <img src="/images/ULA/Success.svg" alt="success" />
          <div className="title">
            <strong>
              {t('Check your email')}, {firstName}!
            </strong>
          </div>
          <div className="description">
            {t("We've sent an email to")} <strong>{email}</strong>{' '}
            {t('with a link to activate your account and access your trial.')}
          </div>
          <span className="divider" />
          {error && <p className="error">{t(error)}</p>}
          <div className="send-again">
            {success ? (
              <>
                {t("We've resent the activation code successfully. If you still don't see the email, please")}
                &nbsp;
                <a href={window.HELP_PAGE}>{t('contact')}</a>
                &nbsp;
                {t('our support team.')}
              </>
            ) : (
              <>
                <Trans
                  i18nKey="resend"
                  components={{
                    send: <a onClick={() => resendEmail(email)}>sending it again</a>,
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
});

export default connect(
  (state) => ({
    firstName: state.trial.fields.firstName,
    email: state.trial.fields.email,
    success: state.trial.resendActivationEmailSuccess,
    error: state.trial.error,
  }),
  (dispatch) => ({
    resendEmail: (email) => dispatch({ type: 'TRIAL_RESEND_ACTIVATION_EMAIL', email }),
  }),
)(withTranslation()(Success));
