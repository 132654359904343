import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Link from '../Helper/Link';
import TextField from '../Form/TextField';
import Layout from '../Layout';
import LoadingOverlay from '../Helper/LoadingOverlay';
import { FormError } from '../Form/errors';
import PrivacyText from '../Helper/PrivacyText';

const ENTER_KEY_CHAR_CODE = 13;

const CorporateLogin = withTranslation()(({ app, data, routing, onInputChange, onSubmitLogin, t }) => {
  const isLoading = app.loading || app.redirecting || data.loading;
  const backAction = routing.prev ? routing.prev.id : 'SERVICE_LOGIN';

  const onEmailKeyPress = (e) => {
    if (e.charCode === ENTER_KEY_CHAR_CODE) {
      onSubmitLogin();
    }
  };

  return (
    <Layout className="corporate-login" cover={{ src: '/images/sign-up.jpg', author: 'Double Aye' }}>
      <LoadingOverlay active={isLoading} />
      <div className="wrapper-shrink">
        <h1 className="title">{t('Corporate sign in')}</h1>

        {data.error && <FormError error={data.error} />}

        <TextField
          inputName="email"
          value={data.email}
          label={t('Corporate email')}
          disabled={isLoading}
          error={data.fieldErrors.email}
          autoFocus
          onChange={(e) => onInputChange(e.target.id, e.target.value)}
          onKeyPress={onEmailKeyPress}
        />
        <div className="button-wrapper">
          <button
            type="button"
            value={t('Sign in')}
            className="button btn-active"
            disabled={isLoading}
            onClick={onSubmitLogin}
          >
            {t('Next')}
          </button>
        </div>

        <div className="back">
          <Link className="link link-nav-back" action={backAction}>
            <span className="icon-arrow-left" />
            <span className="text">{t('Back')}</span>
          </Link>
        </div>

        <PrivacyText className="privacy-text" i18nKey={`privacy-text-${window.THEME.toLowerCase()}`} />
      </div>
    </Layout>
  );
});

export default connect(
  (state) => ({
    app: state.app,
    data: state.corporateLogin,
    routing: state.routing,
  }),
  (dispatch) => ({
    onInputChange: (id, value) => dispatch({ type: 'CORPORATE_LOGIN_INPUT_CHANGE', data: { id, value } }),
    onSubmitLogin: () => dispatch({ type: 'SUBMIT_CORPORATE_LOGIN' }),
  }),
)(CorporateLogin);
