import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GoogleButton from '../Helper/GoogleButton';

function GoogleLoginButton({ id, label, onLogin, children }) {
  return (
    <span
      onMouseDown={(e) => {
        e.preventDefault();
        if (e.button === 0) {
          onLogin();
        }
      }}
      style={{ display: 'inline-block' }}
      id={id}
      className="google-login-button"
    >
      {children || <GoogleButton type="light" label={label} />}
    </span>
  );
}

GoogleLoginButton.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default connect(null, (dispatch) => ({
  onLogin: () => {
    return dispatch({
      type: 'SUBMIT_SOCIAL_LOGIN',
      platform: 'google',
    });
  },
}))(GoogleLoginButton);
