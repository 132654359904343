import React from 'react';
import { withTranslation } from 'react-i18next';

import { Cover } from '../Helper/Cover';
import ContentHeader from '../Layout/ContentHeader';

const RegisterComplete = withTranslation()(({ registeredEmail, resend, resendActivationCode, t }) => {
  return (
    <div className="wrapper signup reg-complete">
      <Cover className="column image-cover" type="register-complete" />
      <div className="column content">
        <ContentHeader />
        <h1 className="title">{t('Your account has been created')}</h1>
        <h5 className="subtitle">{t('Activation required')} </h5>
        <div className="signup-confirmation">
          {!resend.success && (
            <p id="msg-register-success" className="text-bigger">
              <div>
                {t("We've sent an email to")} <strong>{registeredEmail}</strong>
                &nbsp;
                {t('with a link to activate your account.')}
              </div>
              <a className="button btn-secondary" href="/">
                {t('Got it')}
              </a>
              <span className="divider" />
              <div className="bottom-text">
                {t("If you don't see the email, please check your spam folder or")}{' '}
                <span className="link" onClick={() => !resend.loading && resendActivationCode(registeredEmail)}>
                  {t('Send email again')}
                </span>
              </div>
            </p>
          )}
          {resend.success && (
            <p id="msg-register-resend-success" className="text-bigger">
              {t("We've resent the activation code successfully. If you still don't see the email, please")}
              &nbsp;
              <a href={window.HELP_PAGE}>{t('contact')}</a>
              &nbsp;
              {t('our support team.')}
            </p>
          )}
          {resend.error && <p className="error">{t(resend.error.message)}</p>}
        </div>
      </div>
    </div>
  );
});

export default RegisterComplete;
