/* eslint-disable no-prototype-builtins */

import { errorsContainField } from '../Utils/helpers';

const registerInitialState = {
  countries: [{ key: -1, value: 'Please select your country' }],
  email: '',
  username: '',
  newPassword: '',
  firstName: '',
  lastName: '',
  countryCode: '',
  newsletterSubscription: false,
  registrationStep: 1,
  loading: false,
  error: null,
  success: false,
  registeredEmail: '',
  fieldErrors: {},
  fieldWarnings: {},
  fieldsValidating: {},
  fieldsValidated: {},
  captchaLoading: false,
  captchaId: null,
  captchaImageUrl: '',
  captchaValue: '',
  isEmailLocked: false,
};

/* eslint-disable complexity */
export default function registerReducer(state = registerInitialState, payload) {
  switch (payload.type) {
    case 'REGISTER_INPUT_CHANGE':
      if (!registerInitialState.hasOwnProperty(payload.data.id)) {
        return state;
      }

      return {
        ...state,
        fieldErrors: { ...state.fieldErrors, [payload.data.id]: false },
        fieldsValidated: { ...state.fieldsValidated, [payload.data.id]: false },
        [payload.data.id]: payload.data.value,
      };
    case 'REGISTER_INPUT_CHANGE#WARN':
      return { ...state, fieldWarnings: payload.fieldWarnings || {} };
    case 'REGISTER_RESET':
      return registerInitialState;
    case 'POPULATE_COUNTRIES':
      return { ...state, countries: payload.data.countries, countryCode: payload.data.location };
    case 'PREFILL_EMAIL_REGISTER':
      return { ...state, email: payload.email, isEmailLocked: true };
    case 'SUBMIT_REGISTER':
      return { ...state, captcha: payload.captcha, loading: true };
    case 'SUBMIT_REGISTER#SET_STEP':
      return {
        ...state,
        registrationStep: Math.min(2, Math.max(1, payload.data.registrationStep)),
        error: null,
        loading: false,
      };
    case 'SUBMIT_REGISTER#COMPLETE':
      return { ...registerInitialState, registeredEmail: state.email, loading: false, success: true };
    case 'SUBMIT_REGISTER#ERROR':
      return { ...state, error: payload.data.error, fieldsValidating: {}, fieldErrors: {}, loading: false };
    case 'SUBMIT_REGISTER#VALIDATION_ERROR':
      return { ...state, error: payload.error || null, fieldErrors: payload.data.fieldErrors, loading: false };
    case 'VALIDATE_INPUT':
      if (!registerInitialState.hasOwnProperty(payload.data.id)) {
        return state;
      }

      return { ...state, fieldsValidating: { ...state.fieldsValidating, [payload.data.id]: true } };
    case 'VALIDATE_INPUT#COMPLETE':
      if (!registerInitialState.hasOwnProperty(payload.data.id)) {
        return state;
      }

      return {
        ...state,
        fieldsValidated: {
          ...state.fieldsValidated,
          [payload.data.id]: errorsContainField(payload.data.id, payload.data.fieldErrors),
        },
        fieldsValidating: { ...state.fieldsValidating, [payload.data.id]: false },
        fieldErrors: { ...state.fieldErrors, ...payload.data.fieldErrors },
        error: null,
      };
    case 'SUBMIT_REGISTER#VALIDATE_CAPTCHA':
      return { ...state, captchaValue: payload.data.value, loading: true };
    case 'SUBMIT_REGISTER#RESET_CAPTCHA':
      return { ...state, captchaValue: '', loading: false };
    case 'GENERATE_CG_CAPTCHA':
      return { ...state, captchaLoading: true };
    case 'GENERATE_CG_CAPTCHA#COMPLETE':
      return {
        ...state,
        captchaId: payload.data.id,
        captchaImageUrl: `//${window.CG_CAPTCHA_DOMAIN}${payload.data.image}`,
        captchaLoading: false,
      };
    default:
      return state;
  }
}
/* eslint-enable complexity */
