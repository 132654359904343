import React from 'react';
import { withTranslation } from 'react-i18next';

import ErrorPage from './Error';
import { reportReactError } from './Utils/Monitoring';

const ActionButton = withTranslation()(({ t }) => (
  <button
    type="button"
    className="button btn-active"
    onClick={() => {
      window.location.reload();
    }}
  >
    {t('Reload page')}
  </button>
));
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    reportReactError(error);
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return <ErrorPage recoveryAction={ActionButton} />;
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
