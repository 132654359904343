import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ContentHeader from '../Layout/ContentHeader';
import { loginRedirect } from '../Utils/helpers';
import { Cover } from '../Helper/Cover';

const RegisterGoogleComplete = withTranslation()(({ routing, app, t }) => {
  // eslint-disable-next-line camelcase
  const { email, fullName, return_to } = routing.query;

  return (
    <div className="wrapper signup google">
      <Cover className="column image-cover" backgroundImage='url("/images/sign-up2.jpg")' copyright="© Nmachine" />
      <div className="column content complete">
        <ContentHeader />
        <h1 className="title">{t('Account created')}</h1>
        <h5 className="subtitle">
          {t('Your Chaos account is now active.')}
          <br />
          {t('Thank you!')}
        </h5>
        <span className="icon-tick" />
        <h5 className="name">{fullName}</h5>
        <p>{email}</p>
        <button
          type="button"
          className="button btn-active login"
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex="1"
          disabled={app.redirecting}
          onClick={() => loginRedirect(null, return_to)}
        >
          {t('OK')}
        </button>
      </div>
    </div>
  );
});

export default connect((state) => ({
  routing: state.routing,
  app: state.app,
}))(RegisterGoogleComplete);
