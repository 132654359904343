// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';

import { getFragmentParamByName, loginRedirect, getQueryParamByName } from '../Utils/helpers';
import { getCookie, setCookie, deleteCookie } from '../Utils/cookiesHelper';

const STATE_CODE_COOKIE = 'ssofe_lsc'; // sso-frontend legacy state code

export const handleLegacyLogin = (returnTo) => {
  const { origin: sso } = window.location;

  // a cookie with a randomly generated code is set
  // the code is used to check on it before redirecting the user with their access token
  const tokenCode = uuidv4();
  setCookie(STATE_CODE_COOKIE, tokenCode, 'lax');

  const state = JSON.stringify({
    return_to: returnTo,
    code: tokenCode,
  });
  const loginCallback = `${sso}/legacy_login/callback`;

  const noPrompt = getQueryParamByName('no-prompt');
  let oauthImplicitFlowURL = `${window.OAUTH_URL}/oauth/authorize?`;
  oauthImplicitFlowURL += 'response_type=token';
  oauthImplicitFlowURL += `&client_id=${encodeURIComponent(window.CLIENT_ID)}`;
  if (!noPrompt) {
    oauthImplicitFlowURL += '&prompt=login';
  }
  oauthImplicitFlowURL += `&redirect_uri=${encodeURIComponent(loginCallback)}`;
  oauthImplicitFlowURL += `&state=${encodeURIComponent(state)}`;

  window.location.href = oauthImplicitFlowURL;
};

export const handleLegacyLoginCallback = (onError) => {
  const error = getFragmentParamByName('error');
  if (error) {
    const errorDescription = getFragmentParamByName('error_description');
    console.log(`OAuth2 provider returned an error "${error}" ("${errorDescription}").`);
    onError(errorDescription, error);
    return;
  }

  const state = JSON.parse(getFragmentParamByName('state'));
  const cookieCode = getCookie(STATE_CODE_COOKIE);
  if (!cookieCode) {
    console.log('State cookie has expired. Will reinitiate login.');
    // We cannot verify the authentication, so we are redirecting to give the
    // user a chance to login all over again. This is also why we are not
    // returning the access token.
    loginRedirect(null, new URL(state.return_to));
    return;
  }

  deleteCookie(STATE_CODE_COOKIE);
  if (state.code !== cookieCode) {
    console.log(`OAuth2 state code "${state.code}" and cookie code "${cookieCode}" mismatch.`);
    onError('There was a problem finalizing your authentication.');
  } else {
    const accessToken = getFragmentParamByName('access_token');
    loginRedirect(accessToken, new URL(state.return_to));
  }
};
