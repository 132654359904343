import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const componentLightStyle = {
  display: 'inline-block',
  backgroundColor: '#3b5998',
  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px 0px',
  cursor: 'pointer',
  transition: 'box-shadow 0.218s ease 0s',
  width: '215px',
};

const lightStyleHoverDelta = {
  boxShadow: 'rgba(0, 0, 0, 0.65) 0px 1px 2px 0px',
};

const logoContainerStyle = {
  margin: '.84rem 0 0 .6875rem',
  width: '18px',
  height: '18px',
};

const textContainerStyle = {
  display: 'inline-block',
  marginLeft: '.375rem',
  marginRight: 0,
  color: 'rgb(247, 247, 247)',
  letterSpacing: '.013125rem',
  paddingLeft: '.625rem',
  paddingRight: '.625rem',
  fontSize: '.875rem',
  lineHeight: '3rem',
  verticalAlign: 'top',
};

const FacebookButton = ({ facebookLogoURL, label, t }) => {
  const [componentStyle, setComponentStyle] = React.useState(componentLightStyle);

  const handleMouseEnter = () => setComponentStyle({ ...componentStyle, ...lightStyleHoverDelta });
  const handleMouseLeave = () => setComponentStyle(componentLightStyle);

  return (
    <div style={componentStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <img alt="" style={logoContainerStyle} src={facebookLogoURL} />
      <span style={textContainerStyle}>{t(label)}</span>
    </div>
  );
};

FacebookButton.defaultProps = {
  facebookLogoURL: '/images/f.svg',
};

FacebookButton.propTypes = {
  facebookLogoURL: PropTypes.string,
};

export default withTranslation()(FacebookButton);
