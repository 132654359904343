/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ReCAPTCHA from '../Recaptcha/RecaptchaWrapper';
import { IconError } from './Icons';
import ContentHeader from './ContentHeader';
import { Cover } from '../Helper/Cover';
import Notice from './Notice';

// Layout is the global layout wrapper component for the application.
const Layout = ({
  children,
  app,
  cover,
  type,
  contentHeaderTitle,
  contentHeaderLink,
  loading,
  className,
  reCaptchaValidateHandler,
}) => {
  const reCaptchaRef = React.useRef(null);

  const getChildren = () => {
    if (reCaptchaValidateHandler) {
      return React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          reCaptcha: reCaptchaRef.current,
        });
      });
    }

    return children;
  };

  return (
    <div className={['wrapper', className].join(' ')}>
      {cover && React.isValidElement(cover) ? (
        React.cloneElement(cover, {
          className: 'column image-cover',
        })
      ) : (
        <Cover
          backgroundImage={`url("${cover.src}")`}
          copyright={cover.author ? `© ${cover.author}` : null}
          type={type}
        >
          {cover.link}
        </Cover>
      )}

      {reCaptchaValidateHandler && (
        <ReCAPTCHA ref={reCaptchaRef} size="invisible" className="g-recaptcha" onChange={reCaptchaValidateHandler} />
      )}

      <PageContent loading={loading} apiError={app.apiError} apiRetrying={app.apiRetrying}>
        <ContentHeader title={contentHeaderTitle} link={contentHeaderLink} />
        {getChildren()}
      </PageContent>
    </div>
  );
};

Layout.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  className: null,
  reCaptchaValidateHandler: null,
  contentHeaderTitle: null,
  contentHeaderLink: null,
};

Layout.propTypes = {
  loading: PropTypes.bool,
  app: PropTypes.shape({
    apiError: PropTypes.object,
  }),
  cover: PropTypes.oneOfType([
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      author: PropTypes.string,
      link: PropTypes.element,
    }),
    PropTypes.element,
  ]),
  contentHeaderTitle: PropTypes.string,
  contentHeaderLink: PropTypes.element,
  reCaptchaValidateHandler: PropTypes.func,
};

const apiErrorComponent = withTranslation()(({ apiError, loading, retry, t }) => (
  <div className="wrapper-error wrapper-shrink">
    <IconError size={42} />
    <h3>{t('Oops... what a mess.')}</h3>
    <p className="error">{apiError.message}</p>
    {apiError.guid && (
      <p>
        <strong>{apiError.guid}</strong>
      </p>
    )}
    {apiError.retryHandler && (
      <p>
        <span>
          {t('Click')}
          <span className={['link', loading ? 'disabled' : ''].join(' ')} onClick={retry}>
            {' '}
            {t('here')}{' '}
          </span>{' '}
          {t('to try again.')}
        </span>
      </p>
    )}
  </div>
));

apiErrorComponent.propTypes = {
  apiError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  loading: PropTypes.bool,
  retry: PropTypes.func.isRequired,
};

// ApiError component is responsible for rendering global API errors.
// apiError prop is passed to the component, while retry method is taken
// from mapDispatchToProps function of the connected component.
const ApiError = connect(null, (dispatch) => ({
  retry: () => dispatch({ type: 'API_ERROR#RETRY' }),
}))(apiErrorComponent);

export default connect((state) => ({
  app: state.app,
}))(Layout);

// PageContent holds the logic for displayin the global props.children. It
// could render ApiError component or a Loading component if needed.
const PageContent = withTranslation()(({ loading, apiError, apiRetrying, children, t }) => {
  if (loading) {
    return (
      <div className="column content">
        <div className="wrapper-loading">
          <h1>{t('Loading...')}</h1>
        </div>
      </div>
    );
  }

  if (apiError) {
    return (
      <div className="column content">
        <ApiError apiError={apiError} loading={apiRetrying} />
      </div>
    );
  }

  return (
    <div className="column content">
      <Notice />
      {children}
    </div>
  );
});

PageContent.propTypes = {
  loading: PropTypes.bool,
  apiError: PropTypes.object,
  apiRetrying: PropTypes.bool,
};
