import { put, takeLatest, takeEvery, call, select, fork, cancel, delay } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';

import { UNKNOWN_COUNTRY_CODE, validationRules } from '../Register/registerSagas';
import csrfFetch from '../Utils/fetch';
import { Api } from '../Utils/api';
import { cleanQuery, getQueryParamByName, loginRedirect, DEFAULT_RETURN_TO_URL } from '../Utils/helpers';
import { validateData } from '../Utils/Validation';

export function* navigateSocialRegister() {
  yield takeEvery('VALIDATE_INPUT', validateInput);
  yield fork(watchSubmitSocialRegister);
  yield fork(watchSubmitSocialLogin);
  yield put({ type: 'SOCIAL_REGISTER' });

  const appData = yield select((state) => state.app);
  const countries = yield call(() =>
    fetch(`${window.SSO_GATEWAY}/countries`)
      .then((resp) => resp.json())
      .then((data) => data.map((country) => ({ key: country.code, value: country.name }))),
  );

  const routing = yield select((state) => state.routing);

  yield put({ type: 'POPULATE_FIRST_NAME', data: { firstName: routing.query.firstName || '' } });
  yield put({ type: 'POPULATE_LAST_NAME', data: { lastName: routing.query.lastName || '' } });
  yield put({ type: 'POPULATE_COUNTRIES', data: { countries, location: appData.location || UNKNOWN_COUNTRY_CODE } });
}

function* submitSocialRegister({ email }) {
  const registerData = yield select((state) => state.socialRegister);
  const routing = yield select((state) => state.routing);
  const returnTo = routing.query.return_to;

  try {
    const fieldErrors = yield call(validateStep, registerData);
    if (fieldErrors) {
      yield put({ type: 'SUBMIT_SOCIAL_REGISTER#VALIDATION_ERROR', data: { fieldErrors } });
      yield cancel();
    }

    const resp = yield call(Api.fetch, '/account/social/register', {
      method: 'POST',
      body: JSON.stringify({
        countryCode: registerData.countryCode,
        newsletterSubscription: registerData.newsletterSubscription,
        firstName: registerData.firstName,
        lastName: registerData.lastName,
      }),
      on5xx: 'throw',
      on4xx: 'throw',
      onUnauthorized: 'throw',
    });

    if (resp.error) {
      yield put({ type: 'SUBMIT_SOCIAL_REGISTER#ERROR', error: resp });
      yield cancel();
    }
    yield put({ type: 'SUBMIT_SOCIAL_REGISTER#COMPLETE', data: resp });

    const fullName = [registerData.firstName, registerData.lastName].join(' ');

    yield put(
      navigate('REGISTER_SOCIAL_COMPLETE', null, {
        query: cleanQuery({
          return_to: returnTo,
          email,
          fullName,
        }),
      }),
    );
  } catch (error) {
    yield put({ type: 'SUBMIT_SOCIAL_REGISTER#ERROR', error });
  }
}

export function* navigateSocialRegisterComplete() {
  const routing = yield select((state) => state.routing);

  if (!routing.query.email) {
    yield put(
      navigate('SERVICE_LOGIN', null, {
        query: cleanQuery({
          return_to: getQueryParamByName('return_to'),
        }),
      }),
    );
  }

  yield delay(3000);
  yield call(loginRedirect, null, getQueryParamByName('return_to'));
}

export function* navigateLinkExistingAccount() {
  yield fork(watchSubmitLinkAccount);
}

function* handleSubmitLinkAccount() {
  const data = yield select((state) => state.linkExistingAccount);
  const routing = yield select((state) => state.routing);
  const { return_to: returnTo, email } = routing.query;

  try {
    const resp = yield csrfFetch(`${window.SSO_GATEWAY}/account/social/link`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password: data.password,
        returnTo: returnTo ? decodeURIComponent(returnTo) : '',
      }),
    }).then((r) => r.json());

    if (resp.error) {
      yield put({ type: 'SUBMIT_LINK_ACCOUNT#ERROR', error: resp.error });
      yield cancel();
    }

    yield call(loginRedirect, null, returnTo);
  } catch (error) {
    yield put({ type: 'SUBMIT_LINK_ACCOUNT#ERROR', error });
  }
}

function* watchSubmitSocialRegister() {
  yield takeLatest('SUBMIT_SOCIAL_REGISTER', submitSocialRegister);
}

function* watchSubmitLinkAccount() {
  yield takeLatest('SUBMIT_LINK_ACCOUNT', handleSubmitLinkAccount);
}

export function* watchSubmitSocialLogin() {
  yield takeLatest('SUBMIT_SOCIAL_LOGIN', submitSocialLogin);
}

function* submitSocialLogin({ platform, autoRegister }) {
  const routing = yield select((state) => state.routing);

  yield put({ type: 'SOCIAL_LOGIN#RESET' });
  yield put({ type: 'SOCIAL_LOGIN#START' });

  try {
    const response = yield call(Api.fetch, '/account/social/login', {
      method: 'POST',
      body: JSON.stringify({
        platform,
        continue: routing.query?.return_to || DEFAULT_RETURN_TO_URL,
        auto_register: autoRegister,
      }),
      onUnauthorized: 'throw',
      on5xx: 'throw',
    });

    window.location.href = response.redirect_to;
  } catch (error) {
    // It will return http.StatusUnprocessableEntity
    // if the session is expired.
    if (error.HTTPStatus === 422) {
      window.location.reload();
      return;
    }
    yield put({
      type: 'SOCIAL_LOGIN#ERROR',
      error,
    });
  }
}

function* validateInput(payload) {
  if (!payload.data || !payload.data.id || !validationRules[payload.data.id]) {
    yield cancel();
  }

  const fieldName = payload.data.id;

  const data = yield select((state) => state.socialRegister);
  const fieldErrors = yield call(validateData, data, { [fieldName]: validationRules[fieldName] });
  yield put({ type: 'VALIDATE_INPUT#COMPLETE', data: { id: payload.data.id, fieldErrors } });
}

function validateStep(data) {
  const { firstName, lastName } = validationRules;

  return validateData(data, { firstName, lastName });
}
