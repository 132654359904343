import React from 'react';

const Notice = () => (
  <div
    style={{
      backgroundColor: '#b5161c',
      padding: '11px',
      fontSize: '14.5px',
      textAlign: 'center',
      color: '#fff',
      zIndex: 10,
      position: 'relative',
    }}
  >
    <strong>Important: Update Your Chaos License Server by January 28, 2025</strong>
    <br />
    <br />
    To ensure uninterrupted access to your licenses, an essential update is required by January 28, 2025. Failure to
    update your Chaos license server will result in the loss of access to your licenses. For detailed instructions,
    please visit the{' '}
    <a
      href="https://support.chaos.com/hc/en-us/articles/30185867975185-IMPORTANT-Update-your-Chaos-License-Server-Version"
      target="_blank"
      rel="noreferrer"
      style={{ color: 'inherit', fontWeight: '700' }}
    >
      FAQ Article
    </a>
  </div>
);

export default Notice;
