import { errorsContainField } from '../Utils/helpers';

const activateState = {
  requestStatus: '',
  error: null,
};

const deleteAccountState = {
  deletionCode: '',
  requestStatus: '',
  error: null,
};

const resendActivationState = {
  email: '',
  loading: false,
  success: false,
  error: null,
};

const editProfileState = {
  countries: [{ key: -1, value: 'Please select your country' }],
  email: '',
  firstName: '',
  lastName: '',
  countryCode: '',
  customerNumber: '',
  initializing: true,
  loading: false,
  profileUpdated: false,
  hasUnsavedChanges: false,
  error: null,
  fieldErrors: {},
};

const changePasswordState = {
  oldPassword: '',
  newPassword: '',
  requestStatus: '',
  loading: false,
  error: null,
  fieldErrors: {},
  fieldWarnings: {},
  fieldsValidated: {},
};

const recoverPasswordState = {
  email: '',
  fieldErrors: {},
  error: null,
  success: false,
};

const resetPasswordState = {
  newPassword: '',
  code: '',
  codeValid: false,
  validating: false,
  validatingError: null,
  fieldsValidated: {},
  fieldErrors: {},
  loading: false,
  success: false,
  error: null,
};

const setupPasswordState = {
  newPassword: '',
  code: '',
  codeValid: false,
  validating: false,
  validatingError: null,
  fieldsValidated: {},
  fieldErrors: {},
  loading: false,
  success: false,
  error: null,
};

export function activateReducer(state = activateState, payload) {
  switch (payload.type) {
    case 'SUBMIT_ACTIVATE#START':
      return { ...state, requestStatus: 'loading' };
    case 'SUBMIT_ACTIVATE#COMPLETE':
      return { ...state, error: payload.data.error, requestStatus: payload.data.status };
    case 'SUBMIT_ACTIVATE#ERROR':
      return { ...state, error: payload.error };
    default:
      return state;
  }
}

export function deleteReducer(state = deleteAccountState, payload) {
  switch (payload.type) {
    case 'DELETE_ACCOUNT':
      return { ...state, deletionCode: payload.data.deletionCode, requestStatus: 'INITIAL' };
    case 'DELETE_ACCOUNT#ERROR':
      return { ...state, error: payload.error };
    case 'SUBMIT_DELETE_ACCOUNT':
      return { ...state, loading: true };
    case 'SUBMIT_DELETE_ACCOUNT#START':
      return { ...state, loading: true };
    case 'SUBMIT_DELETE_ACCOUNT#COMPLETE':
      return { ...state, loading: false, error: payload.data.error, requestStatus: payload.data.status };
    case 'SUBMIT_DELETE_ACCOUNT#ERROR':
      return { ...state, loading: false, error: payload.error };
    default:
      return state;
  }
}

export function activationResendReducer(state = resendActivationState, payload) {
  switch (payload.type) {
    case 'RESEND_ACTIVATION_CODE':
      return { ...state, email: payload.data.email, loading: true };
    case 'RESEND_ACTIVATION_CODE_RESET':
      return { ...resendActivationState };
    case 'RESEND_ACTIVATION_CODE#COMPLETE':
      return { ...state, success: payload.data.status === 'OK', error: payload.data.error, loading: false };
    default:
      return state;
  }
}

/* eslint-disable complexity */
export function editProfileReducer(state = editProfileState, payload) {
  switch (payload.type) {
    case 'POPULATE_COUNTRIES':
      return { ...state, countries: payload.data.countries };
    case 'EDIT_ACCOUNT#START':
      return { ...editProfileState, initializing: true };
    case 'EDIT_ACCOUNT#NAVIGATE':
      return { ...state, error: null, profileUpdated: false };
    case 'EDIT_ACCOUNT#COMPLETE':
      return {
        ...state,
        ...payload.data.profile,
        countries: payload.data.countries,
        error: payload.data.profile.error,
        initializing: false,
      };
    case 'EDIT_ACCOUNT_INPUT_CHANGE':
      // eslint-disable-next-line no-prototype-builtins
      if (!editProfileState.hasOwnProperty(payload.data.id)) {
        return state;
      }

      // eslint-disable-next-line no-case-declarations
      const fieldErrors = { ...state.fieldErrors };
      delete fieldErrors[payload.data.id];

      return {
        ...state,
        hasUnsavedChanges: true,
        fieldErrors: { ...fieldErrors },
        [payload.data.id]: payload.data.value,
      };
    case 'EDIT_ACCOUNT_RESET':
      return { ...editProfileState };
    case 'SUBMIT_EDIT_ACCOUNT':
      return { ...state, loading: true, profileUpdated: false };
    case 'SUBMIT_EDIT_PROFILE#VALIDATION_ERROR':
      return {
        ...state,
        error: payload.error || false,
        fieldErrors: payload.data.fieldErrors,
        loading: false,
        profileUpdated: false,
      };
    case 'SUBMIT_EDIT_PROFILE#COMPLETE':
      return { ...state, ...payload.data, error: payload.data.error, loading: false, hasUnsavedChanges: false };
    case 'SUBMIT_EDIT_PROFILE#ERROR':
      return { ...state, error: payload.data.error, profileUpdated: false };
    default:
      return state;
  }
}
/* eslint-enable complexity */

/* eslint-disable complexity */
export function changePasswordReducer(state = changePasswordState, payload) {
  switch (payload.type) {
    case 'CHANGE_PASSWORD_INPUT_CHANGE':
      // eslint-disable-next-line no-prototype-builtins
      if (!changePasswordState.hasOwnProperty(payload.data.id)) {
        return state;
      }
      return {
        ...state,
        [payload.data.id]: payload.data.value,
        fieldErrors: { ...state.fieldErrors, [payload.data.id]: '' },
      };
    case 'CHANGE_PASSWORD_INPUT_CHANGE#WARN':
      return { ...state, fieldWarnings: payload.fieldWarnings || {} };
    case 'CHANGE_PASSWORD_RESET':
      return { ...changePasswordState };
    case 'SUBMIT_CHANGE_PASSWORD#START':
      return { ...state, loading: true };
    case 'SUBMIT_CHANGE_PASSWORD#COMPLETE':
      if (payload.data.status === 'OK') {
        return { ...changePasswordState, requestStatus: 'OK', loading: false };
      }
      return { ...state, ...payload.data, loading: false };
    case 'SUBMIT_CHANGE_PASSWORD#ERROR':
      return { ...state, ...payload.data, loading: false };
    case 'CHANGE_PASSWORD_VALIDATE_INPUT#COMPLETE':
      return {
        ...state,
        fieldsValidated: {
          ...state.fieldsValidated,
          [payload.data.id]: errorsContainField(payload.data.id, payload.data.fieldErrors),
        },
        fieldErrors: { ...payload.data.fieldErrors },
        error: null,
      };
    default:
      return state;
  }
}

export function recoverPasswordReducer(state = recoverPasswordState, payload) {
  switch (payload.type) {
    case 'RECOVER_PASSWORD_INPUT_CHANGE':
      return { ...state, email: payload.data.email, error: null };
    case 'SUBMIT_RECOVER_PASSWORD':
      return { ...state, loading: true };
    case 'SUBMIT_RECOVER_PASSWORD#COMPLETE':
      return { ...state, error: payload.data.error, loading: false, success: payload.data.status === 'OK' };
    case 'SUBMIT_RECOVER_PASSWORD#ERROR':
      return {
        ...state,
        error: payload.data.error || null,
        fieldErrors: payload.data.fieldErrors || {},
        loading: false,
      };
    case 'RECOVER_PASSWORD_VALIDATE':
      return { ...state, validating: true };
    case 'RECOVER_PASSWORD_RESET':
      return { ...recoverPasswordState };
    default:
      return state;
  }
}
/* eslint-enable complexity */

export function resetPasswordReducer(state = resetPasswordState, payload) {
  switch (payload.type) {
    case 'RESET_PASSWORD_INPUT_CHANGE':
      return { ...state, newPassword: payload.data.newPassword, error: null };
    case 'SUBMIT_RESET_PASSWORD':
      return { ...state, loading: true };
    case 'SUBMIT_RESET_PASSWORD#COMPLETE':
      return { ...state, error: payload.data.error, loading: false, success: payload.data.status === 'OK' };
    case 'SUBMIT_RESET_PASSWORD#ERROR':
      return { ...state, error: payload.error, loading: false };
    case 'RESET_PASSWORD_VALIDATE':
      return { ...state, code: payload.data.code, validating: true };
    case 'RESET_PASSWORD_VALIDATE#COMPLETE':
      return {
        ...state,
        codeValid: payload.data.status === 'OK',
        validatingError: payload.data.error,
        validating: false,
      };
    case 'RESET_PASSWORD_VALIDATE_INPUT#COMPLETE':
      return {
        ...state,
        fieldsValidated: {
          ...state.fieldsValidated,
          [payload.data.id]: errorsContainField(payload.data.id, payload.data.fieldErrors),
        },
        fieldErrors: { ...payload.data.fieldErrors },
        error: null,
      };
    case 'RESET_PASSWORD_RESET':
      return { ...resetPasswordState };
    default:
      return state;
  }
}

export function setupPasswordReducer(state = setupPasswordState, payload) {
  switch (payload.type) {
    case 'SETUP_PASSWORD_INPUT_CHANGE':
      return { ...state, newPassword: payload.data.newPassword, error: null };
    case 'SUBMIT_SETUP_PASSWORD':
      return { ...state, loading: true };
    case 'SUBMIT_SETUP_PASSWORD#ERROR':
      return { ...state, error: payload.error, loading: false };
    case 'SETUP_PASSWORD_VALIDATE':
      return { ...state, code: payload.data.code, validating: true };
    case 'SETUP_PASSWORD_VALIDATE#COMPLETE':
      return {
        ...state,
        codeValid: payload.data.status === 'OK',
        validatingError: payload.data.error,
        validating: false,
      };
    case 'SETUP_PASSWORD_VALIDATE_INPUT#COMPLETE':
      return {
        ...state,
        fieldsValidated: {
          ...state.fieldsValidated,
          [payload.data.id]: errorsContainField(payload.data.id, payload.data.fieldErrors),
        },
        fieldErrors: { ...payload.data.fieldErrors },
        error: null,
      };
    case 'SETUP_PASSWORD_RESET':
      return { ...setupPasswordState };
    default:
      return state;
  }
}
