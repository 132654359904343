import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LoginLink from '../Helper/LoginLink';
import { Cover } from '../Helper/Cover';

const DeleteAccount = withTranslation()(({ requestStatus, loading, onSubmit, error, t }) => (
  <div className="wrapper profile delete">
    <Cover className="column image-cover" backgroundImage='url("/images/sign-up2.jpg")' copyright="© Double Aye" />

    <div className="column content">
      <div className="wrapper-shrink">
        {requestStatus === 'INITIAL' && (
          <form
            id="form-delete-account"
            action=""
            method="post"
            onSubmit={(e) => e.preventDefault() || (!loading && onSubmit())}
          >
            <h3 className="title">{t('Delete account')}</h3>
            <h5>{t('Are you sure you want to delete all data associated with your account?')}</h5>
            <div className="form-field-wrapper button-wrapper">
              <button id="btn-delete-account" type="submit" className="button btn-active btn-danger" disabled={loading}>
                {t('Delete all data')}
              </button>
            </div>
          </form>
        )}

        {requestStatus === 'OK' && (
          <div className="success">
            <h1>{t('Thank you!')}</h1>
            <h3 id="msg-delete-success">{t('All data was successfully deleted.')}</h3>
            <h3>{t('We apologize for any inconvenience.')}</h3>
          </div>
        )}

        {error && (
          <div className="error">
            <h3>
              <strong>{t('Oops...')}</strong>
            </h3>
            <h5>{t('Something went wrong.')}</h5>
            <p id="msg-error">{t(error.message)}</p>
          </div>
        )}

        <div className="hr" />
        <div className="support">
          <h6>
            <strong>{t('Having troubles?')}</strong>
          </h6>
          <p>
            {t('If you experience problems, contact our')}{' '}
            <a href="mailto:support@chaos.com">{t('Customer Service.')}</a>
          </p>
          <p>
            {t('Already have an account?')} {<LoginLink>{t('Sign in')}</LoginLink>}
          </p>
        </div>
      </div>
    </div>
  </div>
));

export default connect(
  (state) => state.profileDelete,
  (dispatch) => ({
    onSubmit: () => dispatch({ type: 'SUBMIT_DELETE_ACCOUNT' }),
  }),
)(DeleteAccount);
