/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate } from 'redux-saga-first-router';
import { routesMap } from '../../routes';
import { getQueryParamByName, setQueryParam } from '../Utils/helpers';

const loginRouteIDs = ['LOGIN', 'SERVICE_LOGIN'];

function LoginLink({ children, routing, navigateToLink, beforeNavigate, ...props }) {
  const { id: prevRouteID } = routing.prev || {};
  const routeID = loginRouteIDs.includes(prevRouteID) ? prevRouteID : loginRouteIDs[0];
  const navigateParams = { query: {} };
  props.href = routesMap.get(routeID).path;

  const returnTo = getQueryParamByName('return_to');
  if (returnTo) {
    props.href = setQueryParam(props.href, 'return_to', returnTo);
    navigateParams.query.return_to = returnTo;
  }
  const promptQuery = getQueryParamByName('prompt');
  if (promptQuery) {
    props.href = setQueryParam(props.href, 'prompt', promptQuery);
    navigateParams.query.prompt = promptQuery;
  }

  props.onMouseDown = () => {
    beforeNavigate();
    navigateToLink(routeID, navigateParams);
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <a {...props}>{children}</a>;
}

LoginLink.defaultProps = {
  beforeNavigate: () => {},
};

LoginLink.propTypes = {
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  tabIndex: PropTypes.string,
  beforeNavigate: PropTypes.func,
};

export default connect(
  (state) => ({ routing: state.routing }),
  (dispatch) => ({
    navigateToLink: (routeID, navigateParams) => dispatch(navigate(routeID, null, navigateParams)),
  }),
)(LoginLink);
