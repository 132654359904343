import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { navigate } from 'redux-saga-first-router';

const CorporateLoginButton = withTranslation()(({ label, routing, navigateCorporateLogin, t }) => (
  <>
    <div
      className="corporate-login-button-wrapper"
      onMouseDown={(e) => {
        e.preventDefault();
        if (e.button === 0) {
          navigateCorporateLogin(routing);
        }
      }}
    >
      <div className="corporate-login-button">
        <img src="/images/city.svg" alt="Corporate login" />
        <span>{t(label)}</span>
      </div>
    </div>
  </>
));

export default connect(
  (state) => ({
    routing: state.routing,
  }),
  (dispatch) => ({
    navigateCorporateLogin: (routing) =>
      dispatch(
        navigate('CORPORATE_LOGIN', null, {
          query: routing.query,
        }),
      ),
  }),
)(CorporateLoginButton);
