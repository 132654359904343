import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Link from '../Helper/Link';
import PasswordField from '../Form/PasswordField';
import Layout from '../Layout';

const getPasswordUpdateMessage = (theme) => {
  const platform = theme.charAt(0).toUpperCase() + theme.slice(1);

  return `Since we are merging your Chaos ${platform !== 'Chaos' ? `and ${platform}` : ''} accounts,
          please choose a new password to activate or manage your products & services.`;
};

const PasswordChange = withTranslation()(({ routing, data, onInputChange, onSubmit, validateInput, t }) => {
  const query = routing.query || {};
  const requiredPasswordUpdate = Boolean(query.required_password_update);

  return (
    <Layout
      className="profile password-change"
      cover={{ src: '/images/sign-in.jpg', author: 'Joaquim Puigdomenech' }}
      contentHeaderLink={requiredPasswordUpdate ? null : <Link action="ACCOUNT_EDIT">{t('Back to profile')}</Link>}
    >
      <div className="wrapper-shrink">
        <h1 className="title">{t('Change your password')}</h1>

        {requiredPasswordUpdate && (
          <div className="info">
            {t(getPasswordUpdateMessage(window.THEME))}
            <br />
          </div>
        )}

        <form
          id="form-change-password"
          action=""
          method="post"
          onSubmit={(e) => e.preventDefault() || (!data.loading && onSubmit())}
        >
          <PasswordField
            inputName="oldPassword"
            autoComplete="new-password"
            value={data.oldPassword}
            label={t('Old password')}
            error={data.fieldErrors.oldPassword}
            showHideButton={false}
            onChange={(e) => onInputChange(e.target.id, e.target.value)}
          />
          <PasswordField
            inputName="newPassword"
            value={data.newPassword}
            showValidationRules
            label={t('New password')}
            valid={data.fieldsValidated?.newPassword}
            error={data.fieldErrors.newPassword}
            warning={data.fieldWarnings.newPassword}
            onChange={(e) => {
              onInputChange(e.target.id, e.target.value);
              validateInput(e.target.id, e.target.value);
            }}
          />

          <div className="form-field-wrapper button-wrapper">
            <button id="btn-change-password" type="submit" className="button btn-active" disabled={data.loading}>
              {t('Change Password')}
            </button>
            {data.error && (
              <p className="error-message text-left">
                <span className="error">{t(data.error.message)}</span>
              </p>
            )}
            {data.requestStatus === 'OK' && (
              <p className="success-message text-left">
                <span className="success">{t('Password successfully updated. Redirecting you to login page...')}</span>
              </p>
            )}
          </div>
        </form>
      </div>
    </Layout>
  );
});

export default connect(
  (state) => ({ routing: state.routing, data: state.profileChangePassword }),
  (dispatch) => ({
    onInputChange: (id, value) => dispatch({ type: 'CHANGE_PASSWORD_INPUT_CHANGE', data: { id, value } }),
    onSubmit: () => dispatch({ type: 'SUBMIT_CHANGE_PASSWORD' }),
    validateInput: (id, value) => dispatch({ type: 'CHANGE_PASSWORD_VALIDATE_INPUT', data: { id, value } }),
  }),
)(PasswordChange);
