const initialState = {
  email: '',
  loading: false,
  error: null,
  fieldErrors: {},
};

export default function (state = initialState, payload) {
  switch (payload.type) {
    case 'CORPORATE_LOGIN_INPUT_CHANGE':
      // eslint-disable-next-line no-prototype-builtins
      if (!initialState.hasOwnProperty(payload.data.id)) {
        return state;
      }
      return { ...state, [payload.data.id]: payload.data.value };
    case 'SUBMIT_CORPORATE_LOGIN':
      return { ...state, loading: true };
    case 'SUBMIT_CORPORATE_LOGIN#COMPLETE':
      return { ...state, error: null, loading: true };
    case 'SUBMIT_CORPORATE_LOGIN#ERROR':
      return {
        ...state,
        fieldErrors: payload.fieldErrors || initialState.fieldErrors,
        error: payload.error,
        loading: false,
      };
    case 'CORPORATE_LOGIN_RESET':
      return initialState;
    default:
      return state;
  }
}
