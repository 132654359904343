import React from 'react';
import { Trans } from 'react-i18next';

const PrivacyText = ({ className }) => {
  return (
    <p className={className || ''}>
      <Trans
        i18nKey="privacy-text-enscape"
        components={{
          eula: (
            <a
              href=""
              onMouseDown={(e) => {
                e.preventDefault();
                if (e.button === 0) {
                  window.location.href = window.EULA;
                }
              }}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Enscape End-User License Agreement
            </a>
          ),
        }}
      />
    </p>
  );
};

export default PrivacyText;
