import React from 'react';
import { withTranslation } from 'react-i18next';

import ContentHeader from './Layout/ContentHeader';
import { Cover } from './Helper/Cover';

const Loading = withTranslation()(({ cover, t }) => (
  <div className="wrapper">
    <Cover
      backgroundImage={cover?.backgroundImage || 'url("/images/sign-in.jpg")'}
      copyright={cover?.copyright || '© Joaquim Puigdomenech'}
    />
    <div className="column content">
      <ContentHeader />
      <div className="wrapper-shrink">
        <h1 className="loading">{t('Loading...')}</h1>
      </div>
    </div>
  </div>
));

export default Loading;
