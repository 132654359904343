/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Layout from '../Layout';
import LoadingOverlay from '../Helper/LoadingOverlay';
import TextField from '../Form/TextField';
import CorporateLoginButton from '../CorporateLogin/CorporateLoginButton';
import { TRIMBLE_STYLE } from '../Helper/constants';
import { getAlternateLoginLabels } from '../Utils/helpers';
import { SocialLoginButtons } from '../Login/Login';
import PrivacyText from '../Helper/PrivacyText';
import { Cover } from '../Helper/Cover';
import ProductsStrip from './ProductsStrip';

const EmailCheck = withTranslation()(
  ({ onContinue, onEmailChange, socialLogin, data, fieldErrors, loading, app, t }) => {
    const buttonsDisabled = app.loading || app.redirecting;
    const alternateLoginLabels = getAlternateLoginLabels(window.THEME);

    return (
      <Layout
        className="trial login"
        cover={<Cover backgroundImage='url("/images/trial-banner.png")' copyright="© Chaos" />}
      >
        <ProductsStrip />
        <div className="wrapper-shrink">
          <h1 className="title">{t('Enter your email.')}</h1>
          <p className="subtitle">{t('You will use it to access your trial.')}</p>
          <LoadingOverlay active={loading || socialLogin.loading || app.redirecting} />
          <form
            id="form-login"
            className="form-vertical-space"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              onContinue();
            }}
          >
            <TextField
              inputName="email"
              value={data.email || ''}
              label={t('Work or educational email')}
              disabled={buttonsDisabled}
              error={fieldErrors.email}
              tabIndex="1"
              autoFocus
              onChange={(e) => {
                e.preventDefault();
                onEmailChange(e.target.value);
              }}
            />

            <PrivacyText className="privacy-text" i18nKey="privacy-text-trial" />
            <div className="login-button-wrapper">
              <button
                id="btn-login"
                form="form-login"
                type="submit"
                name="commit"
                value={t('Continue')}
                className="button btn-active"
                disabled={buttonsDisabled}
                tabIndex="3"
              >
                {t('Continue')}
              </button>
            </div>
          </form>
          <SocialLoginButtons
            labels={alternateLoginLabels}
            showTrimble={window.TRIMBLE_LOGIN}
            trimbleStyle={TRIMBLE_STYLE.LIGHT}
          />
          {window.CORPORATE_LOGIN && <CorporateLoginButton label={alternateLoginLabels.corporate} />}
        </div>
      </Layout>
    );
  },
);

export default connect(
  (state) => ({
    data: state.trial.fields,
    fieldErrors: state.trial.fieldErrors,
    loading: state.trial.loading,
    socialLogin: state.socialLogin,
    app: state.app,
    routing: state.routing,
  }),
  (dispatch) => ({
    onEmailChange: (email) => dispatch({ type: 'TRIAL_INPUT_CHANGE', data: { email } }),
    onContinue: () => dispatch({ type: 'TRIAL_VALIDATE_EMAIL' }),
    resendActivationCode: (email) => dispatch({ type: 'RESEND_ACTIVATION_CODE', data: { email } }),
  }),
)(EmailCheck);
