/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Layout from '../Layout';
import Link from '../Helper/Link';
import RegisterLink from '../Helper/RegisterLink';
import LoadingOverlay from '../Helper/LoadingOverlay';
import { FormError } from '../Form/errors';
import TextField from '../Form/TextField';
import PasswordField from '../Form/PasswordField';
import GoogleLoginButton from '../SocialAccounts/GoogleLoginButton';
import FacebookLoginButton from '../SocialAccounts/FacebookLoginButton';
import TrimbleLoginButton from '../SocialAccounts/TrimbleLoginButton';
import CorporateLoginButton from '../CorporateLogin/CorporateLoginButton';
import { TRIMBLE_STYLE } from '../Helper/constants';
import { getAlternateLoginLabels } from '../Utils/helpers';
import ChaosCloud from '../Layout/Covers/ChaosCloud';

const ResendActivationError = withTranslation()(({ loginError, email, resend, t }) => {
  if (!loginError || loginError.HTTPStatus !== 403 || loginError.message.indexOf('not active') === -1) {
    return null;
  }

  return (
    <p>
      <span
        id="btn-resend-activation-code"
        className={`link ${resend.data.loading ? 'disabled' : ''}`}
        onClick={() => !resend.data.loading && resend.action(email)}
      >
        {t('Resend activation link')}
      </span>
    </p>
  );
});

const ResendActivationMessage = withTranslation()(({ email, resend, t }) => {
  return (
    <>
      {resend.data.success && (
        <h6 className="activation-resend-success">
          <span className="icon-tick" /> {t('A new activation link has been sent to')} <strong>{email}</strong>.{' '}
          {t('Please use it to activate your account.')}
        </h6>
      )}
    </>
  );
});

export const SocialLoginButtons = withTranslation()(({ showTrimble, trimbleStyle, labels, t }) => {
  return (
    <>
      {(window.FACEBOOK_LOGIN || window.GOOGLE_LOGIN || showTrimble) && (
        <span className="divider">{t(labels.divider)}</span>
      )}
      <div className="social-login-buttons">
        {window.GOOGLE_LOGIN && (
          <div className="row">
            <GoogleLoginButton id="btn-google-login" label={labels.google} />
          </div>
        )}
        {window.FACEBOOK_LOGIN && (
          <div className="row">
            <FacebookLoginButton id="btn-facebook-login" label={labels.facebook} />
          </div>
        )}
        {showTrimble && (
          <div className="row">
            <TrimbleLoginButton
              id="gtm-login-continue-with-trimble"
              buttonStyle={trimbleStyle}
              label={labels.trimble}
              trimbleActivationFlow={false}
              autoRegister
            />
          </div>
        )}
      </div>
    </>
  );
});

const getPageTitle = (returnTo) => {
  if (returnTo.includes('try.')) {
    return 'Sign in to get your trial';
  }

  if (returnTo.includes(window.STORE_ADDR) || returnTo.includes('doCheckout')) {
    return 'Sign in to complete your purchase';
  }

  if (returnTo.includes('demo.')) {
    return 'Sign in to activate the Corona trial on your account';
  }

  return 'Sign in';
};

const Login = withTranslation()(
  ({ onSubmitLogin, onInputChange, socialLogin, data, app, routing, resend, resendActivationCode, t }) => {
    const buttonsDisabled = app.loading || app.redirecting;

    // Show custom page title for users that are redirected from the website's trial page
    const { return_to = '' } = routing.query || {};
    const pageTitle = t(getPageTitle(return_to));
    const error = data.error || socialLogin.error;

    const alternateLoginLabels = getAlternateLoginLabels(window.THEME);

    return (
      <Layout className="login" cover={<ChaosCloud />}>
        <div className="wrapper-shrink">
          <h1 className="title">{pageTitle}</h1>
          <p className="button-post-text">
            {t('New user?')}{' '}
            <RegisterLink id="link-register" tabIndex="5">
              {t('Create Account')}
            </RegisterLink>
          </p>
          <LoadingOverlay active={data.loading || socialLogin.loading || app.redirecting} />
          <form
            id="form-login"
            className="form-vertical-space"
            action=""
            method="post"
            onSubmit={(e) => e.preventDefault() || onSubmitLogin()}
          >
            {error && (
              <FormError error={error}>
                <ResendActivationError
                  loginError={data.error || socialLogin.error}
                  email={data.email}
                  resend={{ data: resend, action: resendActivationCode }}
                />
              </FormError>
            )}

            <ResendActivationMessage email={data.email} resend={{ data: resend, action: resendActivationCode }} />

            <TextField
              inputName="email"
              value={data.email}
              label={t('Username or email')}
              disabled={buttonsDisabled}
              error={data.fieldErrors.email}
              tabIndex="1"
              autoFocus
              onChange={(e) => e.preventDefault() || onInputChange(e.target.id, e.target.value)}
            />

            <PasswordField
              inputName="password"
              value={data.password}
              label={t('Password')}
              error={data.fieldErrors.password}
              showHideButton
              tabIndex="2"
              disabled={buttonsDisabled}
              warning={data.fieldWarnings.password}
              onChange={(e) => onInputChange(e.target.id, e.target.value)}
            >
              <Link id="link-recover-account" tabIndex="4" action="PASSWORD_RECOVER">
                {t('Forgot your password?')}
              </Link>
            </PasswordField>
            <div className="login-button-wrapper">
              <button
                id="btn-login"
                form="form-login"
                type="submit"
                name="commit"
                value={t('Sign in')}
                className="button btn-active"
                disabled={buttonsDisabled}
                tabIndex="3"
              >
                {t('Sign in')}
              </button>
            </div>
          </form>
          <SocialLoginButtons
            labels={alternateLoginLabels}
            showTrimble={window.TRIMBLE_LOGIN}
            trimbleStyle={TRIMBLE_STYLE.LIGHT}
          />
          {window.CORPORATE_LOGIN && <CorporateLoginButton label={alternateLoginLabels.corporate} />}
        </div>
      </Layout>
    );
  },
);

Login.propTypes = {
  onSubmitLogin: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    data: state.login,
    socialLogin: state.socialLogin,
    resend: state.activationResend,
    app: state.app,
    routing: state.routing,
  }),
  (dispatch) => ({
    onInputChange: (id, value) => dispatch({ type: 'LOGIN_INPUT_CHANGE', data: { id, value } }),
    onSubmitLogin: () => dispatch({ type: 'SUBMIT_LOGIN' }),
    resendActivationCode: (email) => dispatch({ type: 'RESEND_ACTIVATION_CODE', data: { email } }),
  }),
)(Login);
