/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';

import { IconError } from '../Layout/Icons';

export const InputError = withTranslation()(({ err, id, t }) => {
  if (Array.isArray(err)) {
    return (
      <ul className="error" id={id}>
        {err.map((m, i) => {
          if (typeof m === 'object') {
            const { customError } = m;
            if (customError) {
              return <li key={i}>{customError}</li>;
            }

            const {
              message,
              link: { text, href },
            } = m;
            return (
              <li key={i}>
                {t(message)} <a href={href}>{text}</a>
              </li>
            );
          }

          return <li key={i}>{t(m)}</li>;
        })}
      </ul>
    );
  }

  if (typeof err === 'string') {
    return (
      <p className="error" id={id}>
        {t(err)}
      </p>
    );
  }

  if (typeof err === 'object' && 'message' in err) {
    return (
      <p className="error" id={id}>
        {t(err.message)}{' '}
        {err.guid && (
          <span style={{ fontSize: '80%' }}>
            <br />
            Ref. {err.guid}
          </span>
        )}
      </p>
    );
  }

  return null;
});

export const PasswordValidation = withTranslation()(({ t, id, err, inputValue, showValidationRules }) => {
  if (err && !Array.isArray(err)) {
    return <InputError id={id} err={err} />;
  }

  if (!showValidationRules) {
    return false;
  }

  const rules = [
    'Both lower and upper case letters',
    'At least one number',
    'At least one symbol',
    'At least 12 characters',
  ];

  const calculateRuleFulfillmentClass = (rule) => {
    if (!inputValue) {
      return 'error-message-neutral';
    }
    if (!err) {
      return 'error-message-positive';
    }
    return err.includes(rule) ? 'error-message-negative' : 'error-message-positive';
  };

  const renderedRules = rules.map((rule, index) => {
    const errMsgClass = calculateRuleFulfillmentClass(rule);
    return (
      <div key={rule} id={`${errMsgClass}-${index}`} className={errMsgClass}>
        <p className="icon" />
        <p>{t(rule)}</p>
      </div>
    );
  });

  return (
    <div id="verbose-password-input-error" className="password-input-error">
      <p className="description">{t('Your password should contain')}:</p>
      <div className="error-messages">{renderedRules}</div>
    </div>
  );
});

export const FormError = withTranslation()(({ error, children, t }) => {
  return (
    <div className="form-error">
      <IconError size={42} />
      <h3 className="error">
        <strong>{t('Oops...')}</strong>
      </h3>
      <h5 id="msg-error" className="error">
        <Trans i18nKey="multiline">{t(error.message)}</Trans>
      </h5>
      {error.guid && <p className="error-guid">{error.guid}</p>}
      {children}
    </div>
  );
});

InputError.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  err: PropTypes.any.isRequired,
};
