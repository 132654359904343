import store from '../../store';

export function sketchupStudioRedirect() {
  const sketchupRedirectURL = new URL(`${window.CG_WEBSITE_ADDR}/vray/sketchup-studio`);

  store.dispatch({ type: 'APPLICATION_REDIRECT' });

  window.location = sketchupRedirectURL.toString();
}

export function loginRedirect(accessToken, returnToURI, trackingFragment = 'regular-login') {
  const redirectURL = verifiedReturnToURL(returnToURI);

  // Append the access token only if the returnTo URL was valid
  if (accessToken && redirectURL.toString() === returnToURI.toString()) {
    redirectURL.searchParams.append('access_token', accessToken);
  }

  store.dispatch({ type: 'APPLICATION_REDIRECT' });

  if (redirectURL.toString().includes('localhost')) {
    // eslint-disable-next-line no-param-reassign
    trackingFragment = '';
  }
  window.location = `${redirectURL.toString()}#${trackingFragment}`;
}

export function logoutRedirect() {
  window.location = verifiedReturnToURL(getQueryParamByName('return_to')).toString();
}

export function isWhitelistedURL(url) {
  const ok = window.ALLOWED_RETURN_TO.find((d) =>
    url.hostname.match(new RegExp('([a-z0-9./])?.?%s$'.replace('%s', d))),
  );
  return Boolean(ok);
}

export function verifiedReturnToURL(url, defaultURL = DEFAULT_RETURN_TO_URL) {
  if (!url) {
    return defaultURL;
  }

  const returnToURL = new URL(url);
  if (!isWhitelistedURL(returnToURL)) {
    return defaultURL;
  }

  return returnToURL;
}

export const cleanQuery = (query) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in query) {
    if (query[key] === null || query[key] === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete query[key];
    }
  }
  return query;
};

export function getQueryParamByName(pName) {
  const params = new URLSearchParams(window.location.search.substring(1));
  return params.get(pName);
}

export function getFragmentParamByName(pName) {
  const params = new URLSearchParams(window.location.hash.substring(1));
  return params.get(pName);
}

export function setQueryParam(uri, key, value) {
  const URI = new URL(uri, window.location);
  URI.searchParams.set(key, value);
  return URI.toString();
}

export function setPageTitleForRoute(route) {
  window.document.title = getPageTitleForRoute(route);
}

export function changeEmailAllowed(platform) {
  return window.ALLOWED_EMAIL_CHANGE_PLATFORMS.includes(platform);
}

export const DEFAULT_RETURN_TO_URL = window.HOME_PAGE;

export function isChaos() {
  const { hostname } = window.location;
  return hostname.includes(window.domains.chaos);
}

export function isCorona() {
  const { hostname } = window.location;
  return hostname.includes(window.domains.corona);
}

export function isEnscape() {
  const { hostname } = window.location;
  return hostname.includes(window.domains.enscape);
}

export function isCylindo() {
  const { hostname } = window.location;
  return hostname.includes(window.domains.cylindo);
}

export function isAxyz() {
  const { hostname } = window.location;
  return hostname.includes(window.domains.axyz);
}

export const getTermsURLs = (language) => {
  if (isCorona()) {
    return '//corona-renderer.com/about/privacy-notice';
  }
  if (isEnscape()) {
    return '//enscape3d.com/privacy/';
  }
  if (isCylindo()) {
    return '//cylindo.com/privacy/';
  }

  return `//www.chaos.com/${language}/terms`;
};

export const getPrivacyURL = (language) => {
  if (isCorona()) {
    return '//corona-renderer.com/about/privacy-notice';
  }
  if (isEnscape()) {
    return '//enscape3d.com/privacy/';
  }
  if (isCylindo()) {
    return '//cylindo.com/privacy/';
  }

  return `//www.chaos.com/${language}/privacy`;
};

export const getCorporatePrivacyURL = () => {
  return '//www.chaos.com/corporate/privacy-notice';
};

export const getEulaURL = (language) => {
  // TODO: We don't have EULA links for anything other than Chaos.
  // (https://jira.chaosgroup.com/browse/DVB-2555)
  if (!isChaos() && !isAxyz()) {
    return '';
  }
  // chaos.com does not support Japanese and shows 404 page when jp is passed
  // to the URL.
  const lang = language === 'jp' ? 'en' : language;
  return `//www.chaos.com/${lang}/eula`;
};

export const gotoLogin = (query) => {
  const returnTo = getQueryParamByName('return_to') || window.HOME_PAGE;
  if (returnTo.includes(window.TRIAL_ADDR)) {
    window.location = `/trial/email-check?return_to=${returnTo}`;
    return;
  }

  const q = query || {};
  Object.keys(q).forEach((key) => q[key] === undefined && delete q[key]);
  const params = new URLSearchParams(q).toString();
  if (params) {
    // Go to the non-legacy login screen of Accounts.
    // This avoids going through the legacy login without a reason and then
    // needlessly propagating an access_token to the destination page.
    window.location = `/service_login?${params}&return_to=${returnTo}`;
  } else {
    // Go to the main Accounts screen.
    window.location = `/?return_to=${returnTo}`;
  }
};

const routesTitlesMap = {
  LOGIN: 'Sign in',
  LOGOUT: 'Sign out',
  SERVICE_LOGIN: 'Sign in',
  REGISTER: 'Sign up',
  REGISTER_COMPLETE: 'Sign up complete',
  ACCOUNT_EDIT: 'Edit account',
  ACCOUNT_ACTIVATE: 'Activate account',
  ACCOUNT_DELETE: 'Destroy account',
  PASSWORD_CHANGE: 'Change password',
  PASSWORD_RECOVER: 'Recover password',
  PASSWORD_RESET: 'Reset password',
  PASSWORD_SETUP: 'Setup password',
};

function getPageTitleForRoute(route) {
  if (isEnscape()) {
    return routesTitlesMap[route.id] ? `${routesTitlesMap[route.id]} | Enscape` : 'Enscape';
  }
  if (isCylindo()) {
    return routesTitlesMap[route.id] ? `${routesTitlesMap[route.id]} | Cylindo` : 'Cylindo';
  }
  if (isAxyz()) {
    return routesTitlesMap[route.id] ? `${routesTitlesMap[route.id]} | Axyz` : 'Axyz';
  }
  return routesTitlesMap[route.id] ? `${routesTitlesMap[route.id]} | Chaos` : 'Chaos';
}

export const getAlternateLoginLabels = (theme) => {
  if (theme === 'enscape') {
    return {
      divider: 'or continue with',
      google: 'Google',
      facebook: 'Facebook',
      trimble: 'Trimble',
      corporate: 'Corporate email',
    };
  }

  return {
    divider: 'or',
    google: 'Continue with Google',
    facebook: 'Continue with Facebook',
    trimble: 'Continue with Trimble',
    corporate: 'Continue with corporate email',
  };
};

// Returns true if the field is not in the errors object.
export const errorsContainField = (field, errors) => {
  return !errors || !errors[field];
};
