import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ContentHeader from '../Layout/ContentHeader';
import Link from '../Helper/Link';
import LoginLink from '../Helper/LoginLink';
import PasswordField from '../Form/PasswordField';
import { Cover } from '../Helper/Cover';
import { isEnscape } from '../Utils/helpers';

const form = (data, onInputChange, onSubmit, validateInput, t) => (
  <div className="wrapper-shrink">
    <h1 className="title">{t('Set your new password')}</h1>
    <form
      id="form-reset-password"
      action=""
      method="post"
      onSubmit={(e) => e.preventDefault() || (!data.loading && onSubmit())}
    >
      {isEnscape() ? (
        <div className="subtitle">{t('Please choose a new password to access your Enscape account.')}</div>
      ) : (
        <div className="info">
          {t(
            'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.',
          )}
        </div>
      )}
      <PasswordField
        inputName="newPassword"
        value={data.newPassword}
        showValidationRules
        label={<span>{t('Password')}</span>}
        error={data.fieldErrors?.newPassword}
        valid={data.fieldsValidated?.newPassword}
        onChange={(e) => {
          onInputChange(e.target.value);
          validateInput(e.target.id, e.target.value);
        }}
      />

      <div className="form-field-wrapper button-wrapper">
        <button id="btn-reset-password" type="submit" className="button btn-active" disabled={data.loading}>
          {t(isEnscape() ? 'Recover' : 'Update')}
        </button>
        {data.error && (
          <p className="error-message text-left">
            <span className="error">{t(data.error.message)}</span>
          </p>
        )}
      </div>
    </form>
  </div>
);

const error = (err, t) => (
  <div className="wrapper-shrink error">
    <div className="error-wrapper">
      <span className="icon-minus" />
      <h5>
        {t(err.message)} Ref. {err.guid}
      </h5>
    </div>
    <div className="button-wrapper">
      <Link action="PASSWORD_RECOVER" className="button btn-active">
        {t('Request a new one')}
      </Link>
    </div>
  </div>
);

const PasswordReset = withTranslation()(({ data, onInputChange, onSubmit, validateInput, t }) => (
  <div className="wrapper profile password-reset">
    <Cover
      className="column image-cover"
      backgroundImage='url("/images/sign-in.jpg")'
      copyright="© Joaquim Puigdomenech"
      type="password-reset"
    />
    <div className="column content">
      <ContentHeader title={t('Already have an account?')} link={<LoginLink>{t('Sign in')}</LoginLink>} />

      {data.validating && <span>{t('LOADING...')}</span>}
      {data.validatingError && error(data.validatingError, t)}
      {data.codeValid && data.code && !data.validatingError && form(data, onInputChange, onSubmit, validateInput, t)}
    </div>
  </div>
));

export default connect(
  (state) => ({ data: state.profileResetPassword }),
  (dispatch) => ({
    onInputChange: (value) =>
      dispatch({
        type: 'RESET_PASSWORD_INPUT_CHANGE',
        data: { newPassword: value },
      }),
    onSubmit: () => dispatch({ type: 'SUBMIT_RESET_PASSWORD' }),
    validateInput: (id, value) => dispatch({ type: 'RESET_PASSWORD_VALIDATE_INPUT', data: { id, value } }),
  }),
)(PasswordReset);
