import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Cover } from '../Helper/Cover';

import ContentHeader from '../Layout/ContentHeader';
import { isEnscape } from '../Utils/helpers';

import RegisterCompleteEnscape from './RegisterCompleteEnscape';

const RegisterComplete = withTranslation()(({ data: { registeredEmail }, resend, resendActivationCode, t }) => {
  if (isEnscape()) {
    return (
      <RegisterCompleteEnscape
        registeredEmail={registeredEmail}
        resend={resend}
        resendActivationCode={resendActivationCode}
      />
    );
  }

  return (
    <div className="wrapper signup reg-complete">
      <Cover className="column image-cover" backgroundImage='url("/images/sign-up2.jpg")' copyright="© Nmachine" />
      <div className="column content">
        <ContentHeader />
        <h1 className="title">{t('Thank you!')}</h1>
        <h5 className="subtitle">{t('Your account has been created.')} </h5>
        <div className="signup-confirmation">
          {!resend.success && (
            <p id="msg-register-success" className="text-bigger">
              {t("We've sent an email to")} <strong>{registeredEmail}</strong>
              &nbsp;
              {t(
                "with a link to activate your account. If you don't see the email, please check your spam folder or try",
              )}
              &nbsp;
              <span
                id="btn-resend-activation-code"
                className={`link ${resend.loading ? 'disabled' : ''}`}
                onClick={() => !resend.loading && resendActivationCode(registeredEmail)}
              >
                {t('sending')}
              </span>{' '}
              {t('the email again.')}
            </p>
          )}
          {resend.success && (
            <p id="msg-register-resend-success" className="text-bigger">
              {t("We've resent the activation code successfully. If you still don't see the email, please")}
              &nbsp;
              <a href={window.HELP_PAGE}>{t('contact')}</a>
              &nbsp;
              {t('our support team.')}
            </p>
          )}
          {resend.error && <p className="error">{t(resend.error.message)}</p>}
        </div>
      </div>
    </div>
  );
});

export default connect(
  (state) => ({ data: state.register, resend: state.activationResend }),
  (dispatch) => ({
    resendActivationCode: (email) => dispatch({ type: 'RESEND_ACTIVATION_CODE', data: { email } }),
  }),
)(RegisterComplete);
