const initialState = {
  email: '',
  prefilledEmail: '',
  password: '',
  loading: false,
  error: null,
  fieldErrors: {},
  fieldWarnings: {},
};

/* eslint-disable complexity */
export default function (state = initialState, payload) {
  switch (payload.type) {
    case 'LOGIN_INPUT_CHANGE':
      // eslint-disable-next-line no-prototype-builtins
      if (!initialState.hasOwnProperty(payload.data.id)) {
        return state;
      }
      return { ...state, [payload.data.id]: payload.data.value };
    case 'LOGIN_INPUT_CHANGE#WARN':
      return { ...state, fieldWarnings: payload.fieldWarnings || {} };
    case 'PREFILL_EMAIL':
      return { ...state, prefilledEmail: payload.email };
    case 'LOGIN_RESET':
      return { ...initialState, email: state.prefilledEmail };
    case 'SUBMIT_LOGIN':
      return { ...state, loading: true };
    case 'SUBMIT_LOGIN#COMPLETE':
      return { ...state, error: null };
    case 'SUBMIT_LOGIN#ERROR':
      return { ...state, password: '', fieldErrors: payload.fieldErrors || {}, error: payload.error, loading: false };
    case 'RESEND_ACTIVATION_CODE#COMPLETE':
      return { ...state, error: null };
    default:
      return state;
  }
}
/* eslint-enable complexity */
