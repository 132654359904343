import React from 'react';
import { Cover } from '../../Helper/Cover';

export default function Trimble({ className }) {
  return (
    <Cover
      id="trimble-cover"
      className={className}
      backgroundImage='url("/images/trimble/background.jpg")'
      copyright="© Chaos"
    />
  );
}
