import React from 'react';

export const Cover = ({ children, backgroundImage, copyright, type, className = '', id = '' }) => {
  switch (window.THEME) {
    case 'axyz': {
      return (
        <div
          className="column image-cover"
          style={{ backgroundImage: `url("/images/axyz-cover${type ? `-${type}` : ''}.jpg")` }}
        >
          <div className="logo anima">
            <img src="/images/anima-logo.svg" alt={window.THEME} />
          </div>
        </div>
      );
    }
    case 'enscape': {
      return (
        <div
          className="column image-cover"
          style={{ backgroundImage: `url("/images/enscape-cover${type ? `-${type}` : ''}.jpg")` }}
        />
      );
    }
    case 'cylindo': {
      return (
        <div
          className="column image-cover"
          style={{ backgroundImage: `url("/images/cylindo-cover${type ? `-${type}` : ''}.jpg")` }}
        />
      );
    }
    case 'corona': {
      return (
        <div
          className="column image-cover"
          style={{ backgroundImage: 'url("/images/corona-cover.jpg")' }}
          data-copyright="© Nikita Voropaev | behance.net/voropaevni57be"
        />
      );
    }
    default: {
      return (
        <div
          id={id}
          className={`column image-cover ${className}`}
          style={{ backgroundImage }}
          data-copyright={copyright}
        >
          {children}
        </div>
      );
    }
  }
};
