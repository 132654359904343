/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CgCaptcha from '../../Recaptcha/CgCaptcha';

const Captcha = ({ onInputChange, app, cgCaptchaDomain, error, generateCgCaptcha, data }) => {
  const { captchaValue, captchaImageUrl, captchaLoading } = data;
  return (
    <>
      {app.location === 'CN' && (
        <>
          <CgCaptcha
            value={captchaValue}
            imageUrl={captchaImageUrl}
            domain={cgCaptchaDomain || window.CG_CAPTCHA_DOMAIN}
            loading={captchaLoading}
            onInputChange={(e) => {
              e.preventDefault();
              onInputChange('captchaValue', e.target.value);
            }}
            onCaptchaRefresh={(e) => {
              e.preventDefault();
              generateCgCaptcha();
            }}
          />
          {error && <div className="captcha-error">{error}</div>}
        </>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    data: state.register,
    app: state.app,
    error: state.trial.fieldErrors.captcha,
  }),
  (dispatch) => ({
    generateCgCaptcha: () => dispatch({ type: 'GENERATE_CG_CAPTCHA' }),
    onInputChange: (id, value) => dispatch({ type: 'REGISTER_INPUT_CHANGE', data: { id, value } }),
  }),
)(withTranslation()(Captcha));
