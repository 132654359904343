import { put, call, select, cancel } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';
import i18n from 'i18next';

import { Api } from '../Utils/api';
import { getCookie, LANGUAGE_COOKIE } from '../Utils/cookiesHelper';
import { reportSagaError } from '../Utils/Monitoring';
import { Countries, LanguageCodes } from '../../constants';
import { getQueryParamByName } from '../Utils/helpers';
import { handleLegacyLogin, handleLegacyLoginCallback } from './legacyLoginUtils';

function* getLanguage() {
  const { app } = yield select((state) => state);

  let language = 'en';

  // check location
  const userLocation = app.Location;
  if (Countries[userLocation]) {
    language = Countries[userLocation];
  }

  // check cg-website cookie
  const cookieLanguage = getCookie(LANGUAGE_COOKIE);
  if (LanguageCodes.includes(cookieLanguage)) {
    language = cookieLanguage;
  }

  if (!window.LOCALES_ENABLED.includes(language)) {
    language = 'en';
  }

  i18n.changeLanguage(language);
}

export function* auth(callback, params = { public: true, callbackParams: null }) {
  try {
    const { app } = yield select();
    if (!params.public && !app.uid) {
      yield put(navigate('LOGIN'));
    } else {
      yield call(callback, params.callbackParams);
    }
  } catch (error) {
    // The error reporting in api.js will handle API errors
    if (error.HTTPStatus) {
      return;
    }

    reportSagaError(error);
  }
}

export function* init() {
  // if the URL doesn't have a path and has a return_to query parameter
  // that means it's legacy login
  const returnTo = getQueryParamByName('return_to');
  const requiredPasswordUpdate = getQueryParamByName('required_password_update');
  if (window.location.pathname === '/' && returnTo && !requiredPasswordUpdate) {
    handleLegacyLogin(returnTo);
    yield cancel();
  }

  // the URL with which the backend returns the access token as a fragment
  if (window.location.pathname === '/legacy_login/callback') {
    handleLegacyLoginCallback((error_description, error_code = '') => {
      window.location = `/error?error_description=${encodeURIComponent(
        error_description,
      )}&error_code=${encodeURIComponent(error_code)}`;
    });
    yield cancel();
  }

  try {
    yield put({ type: 'GET_SESSION#START' });
    const res = yield call(Api.fetch, '/session', {
      parseJSON: false,
      retryHandler: init,
    });
    const sessionData = yield call(() => res.json());
    const data = { ...sessionData, csrfToken: res.headers.get('X-CSRF-Token') };

    if (window.LINKED_SESSION_ENABLED && data.Linked === false) {
      window.location.href = `${window.SSO_GATEWAY}/session/link?continue=${encodeURIComponent(window.location.href)}`;
      yield cancel();
    }

    yield put({ type: 'GET_SESSION#COMPLETE', data });
    yield call(getLanguage);
  } catch (error) {
    yield put({ type: 'GET_SESSION#ERROR', error });

    // The error reporting in api.js will handle API errors
    if (error.HTTPStatus) {
      return;
    }

    reportSagaError(error);
  }
}
