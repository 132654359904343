const initialState = {
  id: '',
  uid: null,
  email: '',
  firstName: '',
  lastName: '',
  csrfToken: '',
  location: '',
  loading: true,
  redirecting: false,
  error: null,
  apiError: null,
  apiRetrying: false,
};

/* eslint-disable complexity */
export default function (state = initialState, payload) {
  switch (payload.type) {
    case 'GET_SESSION#START':
      return { ...state, loading: true };
    case 'GET_SESSION#COMPLETE':
      return {
        ...state,
        id: payload.data.ID,
        uid: payload.data.UserGUID,
        email: payload.data.Email,
        firstName: payload.data.FirstName,
        lastName: payload.data.LastName,
        csrfToken: payload.data.csrfToken,
        loading: false,
        location: payload.data.Location ? payload.data.Location.toUpperCase() : '',
      };
    case 'GET_SESSION#ERROR':
      return { ...state, error: payload.error };
    case 'SESSION#DESTROY':
      return { ...state, uid: null };
    case 'APPLICATION_REDIRECT':
      return { ...state, redirecting: true };
    case 'API_ERROR':
      return {
        ...state,
        apiError: {
          ...payload.data.error,
          retryHandler: payload.data.retryHandler,
        },
        loading: false,
        apiRetrying: false,
        indicateApiRetrying: payload.data.indicateApiRetrying !== false,
      };
    case 'API_ERROR#RETRY':
      return { ...state, apiRetrying: state.indicateApiRetrying };
    case 'API_ERROR#RESET':
      return { ...state, apiRetrying: false, apiError: null };
    default:
      return state;
  }
}
/* eslint-enable complexity */
