import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TrimbleButton from '../Helper/TrimbleButton';

function TrimbleLoginButton({ id, label, buttonStyle, trimbleActivationFlow, autoRegister, onLogin }) {
  return (
    <span
      onMouseDown={(e) => {
        e.preventDefault();
        if (e.button === 0) {
          onLogin(trimbleActivationFlow, autoRegister);
        }
      }}
      id={id}
    >
      <TrimbleButton buttonStyle={buttonStyle}>{label}</TrimbleButton>
    </span>
  );
}

TrimbleLoginButton.propTypes = {
  onLogin: PropTypes.func.isRequired,
  buttonStyle: PropTypes.oneOf(['dark', 'light']).isRequired,
  trimbleActivationFlow: PropTypes.bool.isRequired,
  autoRegister: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({ data: state.socialRegister }),
  (dispatch) => ({
    onLogin: (trimbleActivationFlow, autoRegister) => {
      dispatch({
        type: trimbleActivationFlow ? 'SUBMIT_TRIMBLE_AUTO_LOGIN' : 'SUBMIT_SOCIAL_LOGIN',
        platform: 'trimble',
        autoRegister,
      });
    },
  }),
)(TrimbleLoginButton);
