/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ContentHeader from '../Layout/ContentHeader';
import TextField from '../Form/TextField';
import PasswordField from '../Form/PasswordField';
import { FormError } from '../Form/errors';
import Link from '../Helper/Link';
import { Cover } from '../Helper/Cover';

const LinkExistingAccount = withTranslation()(
  ({ routing, data, fieldWarnings, fieldErrors, onInputChange, onSubmitLink, t }) => {
    const { email } = routing.query;

    return (
      <div className="wrapper link-account">
        <Cover className="column image-cover" backgroundImage='url("/images/sign-up2.jpg")' copyright="© Nmachine" />
        <div className="column content">
          <ContentHeader />
          <div className="wrapper-shrink">
            <form
              id="form-link"
              action="/"
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitLink();
              }}
            >
              <div className="text-wrapper">
                <h1 className="title">{t('Link existing account')}</h1>
              </div>

              <TextField
                inputName="email"
                value={email}
                label={<span>{t('Email')}</span>}
                autoFocus
                tabIndex="1"
                disabled
                onChange={(e) => e.preventDefault() || onInputChange(e.target.id, e.target.value)}
              />

              <PasswordField
                inputName="password"
                value={data.password}
                label={t('Chaos Password')}
                error={fieldErrors.password}
                showHideButton
                tabIndex="2"
                disabled={data.loading}
                warning={fieldWarnings.password}
                onChange={(e) => onInputChange(e.target.id, e.target.value)}
              >
                <Link id="link-recover-account" tabIndex="4" action="PASSWORD_RECOVER">
                  {t('Forgot your password?')}
                </Link>
              </PasswordField>

              <div className="form-field-wrapper button-wrapper">
                <button
                  id="btn-register"
                  type="submit"
                  className="button btn-active btn-wide"
                  tabIndex="3"
                  disabled={data.loading}
                >
                  {t('LINK ACCOUNTS')}
                </button>
              </div>

              <p>
                <span onClick={() => window.history.back()} className="link">
                  <span className="icon-arrow-left" />
                  <span className="text">{t('Back')}</span>
                </span>
              </p>
              <br />
              {data.error && <FormError error={data.error} />}
            </form>
          </div>
        </div>
      </div>
    );
  },
);

LinkExistingAccount.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    fieldErrors: PropTypes.object.isRequired,
    fieldWarnings: PropTypes.object.isRequired,
  }).isRequired,
  onSubmitLink: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

LinkExistingAccount.defaultProps = {
  fieldErrors: {},
  fieldWarnings: {},
};

export default connect(
  (state) => ({
    routing: state.routing,
    data: state.linkExistingAccount,
  }),
  (dispatch) => ({
    onInputChange: (id, value) =>
      dispatch({
        type: 'LINK_EXISTING_ACCOUNT_INPUT_CHANGE',
        data: { id, value },
      }),
    onSubmitLink: () => {
      dispatch({ type: 'SUBMIT_LINK_ACCOUNT' });
    },
  }),
)(LinkExistingAccount);
