import React from 'react';
import { Trans } from 'react-i18next';
import i18n from 'i18next';

import { getEulaURL, getPrivacyURL, getTermsURLs, isEnscape } from '../Utils/helpers';

import PrivacyTextEnscape from './PrivacyTextEnscape';

const PrivacyText = ({ className, i18nKey }) => {
  if (isEnscape()) {
    return <PrivacyTextEnscape className={className} />;
  }

  const privacyPolicyURL = getPrivacyURL(i18n.language);
  const termsOfUseURL = getTermsURLs(i18n.language);
  const eulaURL = getEulaURL(i18n.language);

  return (
    <p className={className || ''}>
      <Trans
        i18nKey={i18nKey}
        components={{
          privacy: (
            <a
              href={privacyPolicyURL}
              target="_blank"
              rel="noopener noreferrer"
              onMouseDown={(e) => {
                e.preventDefault();
                if (e.button === 0) {
                  window.open(privacyPolicyURL, '_blank');
                }
              }}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Privacy Policy
            </a>
          ),
          terms: (
            <a
              href={termsOfUseURL}
              target="_blank"
              rel="noopener noreferrer"
              onMouseDown={(e) => {
                e.preventDefault();
                if (e.button === 0) {
                  window.open(termsOfUseURL, '_blank');
                }
              }}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Terms of Use
            </a>
          ),
          eula: (
            <a
              href={eulaURL}
              target="_blank"
              rel="noopener noreferrer"
              onMouseDown={(e) => {
                e.preventDefault();
                if (e.button === 0) {
                  window.open(eulaURL, '_blank');
                }
              }}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              End-user license agreement
            </a>
          ),
        }}
      />
    </p>
  );
};

export default PrivacyText;
