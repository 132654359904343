import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const CgCaptcha = withTranslation()(({ value, imageUrl, loading, onCaptchaRefresh, onInputChange, t }) => {
  if (!imageUrl) {
    return null;
  }

  return (
    <div className={`cg-captcha form-field-wrapper ${loading ? 'loading' : ''}`}>
      <div className="image-wrapper">
        <img id="img-captcha" src={`${imageUrl}`} alt="" />

        {onCaptchaRefresh && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button type="button" className="btn-refresh" title={t('Reload captcha')} onClick={onCaptchaRefresh} />
        )}
      </div>
      <label htmlFor="register_form_cg_captcha_">{t('Please enter the numbers on the image')}</label>
      <input type="text" value={value} maxLength="6" onChange={(e) => onInputChange(e)} />
    </div>
  );
});

CgCaptcha.propTypes = {
  value: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCaptchaRefresh: PropTypes.func,
  loading: PropTypes.bool,
};

export default CgCaptcha;
