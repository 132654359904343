import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LoginLink from '../../Helper/LoginLink';

const AlreadyTakenEmailError = withTranslation()(({ prefillLoginEmail, email, t }) => {
  return (
    <div>
      {t('This email address is already connected to an account.')}
      <br />
      <LoginLink
        beforeNavigate={() => {
          prefillLoginEmail(email);
        }}
      >
        {t('Sign in')}
      </LoginLink>{' '}
      {t('instead?')}
    </div>
  );
});

export default connect(
  (state) => ({
    email: state.register.email,
  }),
  (dispatch) => ({
    prefillLoginEmail: (email) => dispatch({ type: 'PREFILL_EMAIL', email }),
  }),
)(AlreadyTakenEmailError);
