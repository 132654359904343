import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { TRIMBLE_STYLE } from './constants';

const darkStyle = {
  logo: '/images/trimble_white.svg',
  className: 'trimble-dark',
};

const lightStyle = {
  logo: '/images/trimble_blue.svg',
  className: 'trimble-light',
};

const TrimbleButton = ({ buttonStyle, t }) => {
  const btnStyle = buttonStyle === TRIMBLE_STYLE.LIGHT ? lightStyle : darkStyle;

  return (
    <div className={`trimble-button-wrapper ${btnStyle.className}`}>
      <img alt="" src={btnStyle.logo} />
      <span>{t('Continue with Trimble')}</span>
    </div>
  );
};

TrimbleButton.defaultProps = {
  buttonStyle: 'dark',
};

TrimbleButton.propTypes = {
  buttonStyle: PropTypes.oneOf([TRIMBLE_STYLE.DARK, TRIMBLE_STYLE.LIGHT]),
};

export default withTranslation()(TrimbleButton);
