import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ContentHeader from '../Layout/ContentHeader';
import { Cover } from '../Helper/Cover';

const DiscourseLogin = withTranslation()(({ data, t }) => {
  const message = data.error ? data.error : t('Loading your details...');
  return (
    <div className="wrapper discourse login">
      <Cover backgroundImage='url("/images/sign-up2.jpg")' copyright="© Joaquim Puigdomenech" />
      <div className="column content">
        <ContentHeader />
        <div className="wrapper-shrink">
          <div className="text-align-center">
            <h1 className="title">{t('Discourse Sign In')}</h1>
            <p>{t(message)}</p>
          </div>
        </div>
      </div>
    </div>
  );
});

DiscourseLogin.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default connect((state) => ({ data: state.discourseLogin }))(DiscourseLogin);
